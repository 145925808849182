import { useEffect } from 'react';
import { TGSpinner } from '../TGElements';

const EnterSMSCountdown = ({setCountEnd, count, setCount}) => {

    useEffect(() => {
        let intervalId;
        if(count > 0){
            intervalId = setInterval(() => {
                setCount(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }else{
            setCountEnd(true)
            return () => clearInterval(intervalId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);
    return (
        <TGSpinner
            text={count.toString()}
            size={67.5}
            textColor={"var(--tg-primary-text)"}
            primaryColor={"var(--tg-primary)"}
            secondaryColor={"var(--tg-white)"}
            styles={{margin: "10px auto"}}
            />    
    );
}

export default EnterSMSCountdown;
