import { useSelector } from 'react-redux'
import SearchResults from '../SearchResults'
import NearbyStations from '../NearbyStatons/NearbyStations'
import RecentSearches from '../RecentSearches/RecentSearches'
const SearchModalContent = ({currentBreakpoint}) => {
  const {searchResults} = useSelector(state => state.search)
  return searchResults.length ?
      <SearchResults />
      : (
        <>
          <NearbyStations />
          {currentBreakpoint !== 0.3 && <RecentSearches />}
        </>
      )
}

export default SearchModalContent