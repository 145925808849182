import { TGText } from "../../TGElements";
import TextLoading from "./TextLoading";
import { t } from "i18next";
import { formatDate, formatSeconds } from "../../../helpers";
const Summary = ({ createdAt, station, summary }) => {
  return (
    <div className="--invoice">
      <div className="line">
        <TGText>{t("date")}</TGText>
        <div className="date">
          <TextLoading fontWeight={"medium"}>
            {createdAt && `${formatDate(createdAt)} `}
          </TextLoading>
          <TextLoading
            fontWeight={"medium"}
            color={"var(--tg-secondary-text)"}
          >
            {createdAt && formatDate(createdAt, "time")}
          </TextLoading>
        </div>
      </div>
      <div className="line">
        <TGText>{t("station")}</TGText>
        <TextLoading fontWeight={"medium"}>
          {station?.name}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("station-no")}</TGText>
        <TextLoading fontWeight={"medium"}>
          {station.station_id && `TG${station?.station_id}`}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("duration")}</TGText>
        <TextLoading fontWeight={"medium"}>
          {summary?.charging_duration && formatSeconds(summary?.charging_duration)}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("charging-consumption")}</TGText>
        <TextLoading
          fontWeight={"medium"}
          textTransform={"unset"}
        >
          {summary?.consumed_amount && `${summary?.consumed_amount} kWh`}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("electricity-unit-price")}</TGText>
        <TextLoading
         
          fontWeight={"medium"}
          textTransform={"unset"}
        >
          {summary?.charging_fee && `${summary?.charging_fee}₺/kWh`}
        </TextLoading>
      </div>
    </div>
  );
};

export default Summary;
