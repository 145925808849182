import React from 'react'
import { TGSkeleton } from '../TGElements'

const InvoiceSkeleton = () => {
    const InvoiceSkeletonItem = () => {
        return (
            <div className="InvoiceItem">
            <div>
            <TGSkeleton width={50} height={50} borderRadius={12} />
                <div>
                <div>
                    <TGSkeleton width={73} height={17} borderRadius={5}/>
                    <TGSkeleton width={31} height={17} borderRadius={5}/>
                </div>
                    <TGSkeleton width={200} height={17} borderRadius={5}/>
                    <TGSkeleton width={50} height={12} borderRadius={5}/>
                </div>
            </div>
            </div>
        )
    }
    return (
        <div style={{padding: 16}}>
            {[...Array(10).keys()].map(a => <InvoiceSkeletonItem key={a} />)}
        </div>
    )
}

export default InvoiceSkeleton