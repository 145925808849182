//React
import { useEffect, useState } from "react";
//Components
import { IonModal } from "@ionic/react";
import TGPage from "./TGPage";
//Helpers
import { Capacitor } from "@capacitor/core";

const TGModal = ({
  isOpen,
  setIsOpen,
  children,
  scrollY,
  modalCloseButton,
  title,
  fullScreen,
  toolbar,
  breakpoints,
  initialBreakpoint,
  ionPadding,
  modalHandler,
  iosSafeArea,
  rightButton,
  cssClass,
  reference,
  onBreakpointDidChange,
  onWillDismiss,
  onDidDismiss,
  isProfileEdit,
  ...props
}) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0);
  const handleBreakpointChange = (ev) => {
    onBreakpointDidChange && onBreakpointDidChange(ev);
    setCurrentBreakpoint(ev.detail.breakpoint);
  };

  useEffect(() => {
    if (isOpen) setCurrentBreakpoint(initialBreakpoint ? initialBreakpoint : 1);
    else setCurrentBreakpoint(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isIos = iosSafeArea ?? (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios");

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false)
        onDidDismiss && onDidDismiss()
      }}
      onWillDismiss={() => {
        onWillDismiss && onWillDismiss()
      }}
      canDismiss={true}
      breakpoints={breakpoints}
      initialBreakpoint={initialBreakpoint}
      handle={modalHandler}
      fullScreen={fullScreen && fullScreen}
      className={`TGModal ${cssClass ? cssClass : ""}`}
      ref={reference ? reference : null}
      onIonBreakpointDidChange={handleBreakpointChange}
      {...props}
    >
      <TGPage
        type="modal"
        title={title}
        onModalClose={() => setIsOpen(false)}
        scrollY={scrollY}
        modalCloseButton={modalCloseButton}
        toolbar={toolbar}
        rightButton={rightButton}
        ionPadding={ionPadding}
        isSafeArea={Boolean(isIos && currentBreakpoint === 1)}
        isProfileEdit={isProfileEdit}
      >
        {children}
      </TGPage>
    </IonModal>
  );
};

export default TGModal;
