import React from 'react'
import Vehicles from './Vehicles'
import Payments from './Payments'

const MenuHighlights = () => {
  return (
    <div className='MenuHighlights'>
        <Vehicles />
        <Payments />
    </div>
  )
}

export default MenuHighlights