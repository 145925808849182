import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import 'swiper/css';
import {Slide1, Slide2, Slide3, Slide4} from './OnboardingSlides';

const OnboardingSwiper = ({activeIndex, setActiveIndex}) => {
    return (
        <Swiper
            centeredSlides={true}
            speed={200}
            allowTouchMove={true}
            modules={[Pagination]}
            pagination={{type: "bullets"}}
            onSlideChange={e => setActiveIndex(e.activeIndex)}
        >
            <SwiperSlide>
                <Slide1 activeIndex={activeIndex}/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide2 activeIndex={activeIndex}/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide3 activeIndex={activeIndex}/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide4 activeIndex={activeIndex}/>
            </SwiperSlide>
        </Swiper>
    );
}

export default OnboardingSwiper;
