import { TGButton, TGIcon } from '../../../../TGElements'
import { useHistory } from 'react-router';
import {isUserAbleToStartSession, isUserLoggedIn, startScan} from '../../../../../helpers';

const ScanButton = () => {
    const history = useHistory()
    
    const onClick = async () => {
        if (!isUserLoggedIn()) return history.push("/register-phone")
        const isAble = await isUserAbleToStartSession()
        if(!isAble) return;
        const isStart = await startScan()
        if(isStart?.start){
            history.push("/active-session", {isCableConnected:isStart.isCableConnected})
        }else if(!isStart?.scanner){
            history.push("/start-session");
        }
    }

    return (
        <div className='ScanButtonWrapper'>
            <TGButton
            width={52}
            height={52}
            borderRadius={"52px"}
            backgroundColor={"var(--tg-primary)"}
            expand="none"
            cssClass="TGMapButton ScanButton"
            onButtonClick={onClick}
            backgroundActiveColor={"var(--tg-button-effect)"}
            >
                <TGIcon name="scan" width={21} height={20.2}/>
            </TGButton>
        </div>
    );
}

export default ScanButton;
