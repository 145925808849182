//Ionic & React
import {
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import TGBackButton from "./TGBackButton";
import TGIcon from "./TGIcon";

const TGPageHeader = ({
  title,
  backButton=false,
  rightButton=false,
  onBack
}) => {

  return (
    <>
      <IonHeader id="main-content" className="ion-no-border">
        <IonToolbar className="TGPageHeader">
          {
            backButton ? (
              <IonButtons slot="start">
                <TGBackButton onBack={onBack}/>
              </IonButtons>
            ) : null
        }
          {title && <IonTitle className="ion-text-capitalize">{title}</IonTitle>}
          {rightButton && (
            <IonButtons slot="end">
              <IonButton fill="clear" onClick={rightButton.onClick}>
                {rightButton.text}
                {rightButton.icon && <TGIcon name={rightButton.icon.name} width={rightButton.icon.width || 24} height={rightButton.icon.height || 24} style={{paddingLeft: "8px"}}/>}
              </IonButton>
            </IonButtons>  
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default TGPageHeader;
