import TGIcon from "./TGIcon";
import { IonContent, IonPopover } from "@ionic/react";

const TGTooltip = ({id, children}) => {
  return (
    <>
      <TGIcon id={id} name={"tooltip"} size={16} />
      <IonPopover trigger={id} triggerAction="click">
        <IonContent scrollY={false} class="ion-padding">
            {children}
        </IonContent>
      </IonPopover>
    </>
  );
};

export default TGTooltip;
