import {
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import TGBackButton from "./TGBackButton";
import TGNotificationsButton from "./TGNotificationsButton";
import TGIcon from "./TGIcon";
import EditProfileButton from "../EditProfileButton";
const TGModalHeader = ({title, modalCloseButton, onModalClose, rightButton, isProfileEdit}) => {
  return (
      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar">
          <IonButtons slot="start">
            {!modalCloseButton ? <TGBackButton onBack={onModalClose} /> : null}
          </IonButtons>
          <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              {
                isProfileEdit ? <EditProfileButton /> : null
              }
              {rightButton === "notifications" ? (
                <TGNotificationsButton />
              ) : modalCloseButton === true ? (
                <TGIcon name={"close-modal"} size={30} onClick={onModalClose}/>
              ) : null}
            </IonButtons>
        </IonToolbar>
      </IonHeader>
  );
};

export default TGModalHeader;
