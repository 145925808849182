import React from 'react'
import { TGIcon, TGText } from '../../../TGElements'
import { useSelector } from 'react-redux'

const ChargingSpeed = () => {
    const {activeSession} = useSelector(state => state.session)
  return (
    <div className='ChargingSpeed'>
        <TGIcon name="charge-speed" size={24}/>
        <TGText color={"var(--tg-white)"} textTransform={"unset"}>
            <strong>{activeSession?.kwh}</strong> kw
        </TGText>
    </div>
  )
}

export default ChargingSpeed