import React from "react";
import { TGText } from "../../TGElements";
import TextLoading from "./TextLoading";
import { t } from "i18next";
const Pricing = ({
  chargingCost,
  totalCost,
  statusName,
}) => {
  return (
    <div className="--invoice">
      <div className="line">
        <TGText>{t("charging-cost")}</TGText>
        <TextLoading fontWeight={"medium"}>
        {chargingCost && `${chargingCost}₺`}
        </TextLoading>
      </div>
      <div className="line">
        <span className="total-divider" />
      </div>
      <div className="line">
        <TGText>{t("total-cost")}</TGText>
        <TextLoading
          fontWeight={"semibold"}
          fontSize={20}
        >
          {totalCost && `${totalCost}₺`}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("status")}</TGText>
        <TextLoading>{statusName}</TextLoading>
      </div>
    </div>
  );
};

export default Pricing;
