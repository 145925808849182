import {validate as cardValidation} from "./paymentFormValidation";
import {validate as userValidation} from "./registerFormValidation";

const useValidation = () => {
  const validate = (object) => {
    switch (object.type) {
      case "card-number":
        return cardValidation.creditCard(object);
      case "cvc":
        return cardValidation.cvc(object);
      case "expiry":
        return cardValidation.expire(object);
      case "name":
        return cardValidation.name(object);
      case "fullname":
        return userValidation.name(object)
      case "email":
        return userValidation.email(object)
      case "surname":
        return userValidation.surname(object)
      case "phone-number":
        return userValidation.phoneNumber(object)
      default:
        break;
    }
  };

  return validate;
};

export { useValidation };
