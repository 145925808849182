import {SessionContent} from './Partials'
import {SessionInformationModal} from '../SessionInformationModal'

const Session = ({from, setIntervalStart}) => {
  return (
    <>
        <SessionContent from={from} setIntervalStart={setIntervalStart}/>
        <SessionInformationModal />
    </>
  )
}

export default Session