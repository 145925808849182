//Components
import { TGText } from "../TGElements";
import ActionButton from "./ActionButton";
//Helpers
import { useTranslation } from "react-i18next";

const Content = ({ receipt }) => {
  const { t } = useTranslation();

  const formatDuration = (time) => {
    const timeArray = time.split(":");
    const timeUnitArray = ["hourUnit", "minuteUnit", "secondUnit"]
    let result = ""
    timeArray.forEach((time, index) =>
      Number(time) !== 0 ? (result += `${time}${t(timeUnitArray[index])} `) : ""
    );
    return result
  };


  return (
    <div className="ReceiptContent">
      <div>
        <TGText fontWeight={"semibold"} fontSize={18}>
          {t("payment-success")}
        </TGText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 16,
          }}
        >
          <TGText
            color={"var(--tg-secondary-text)"}
            fontSize={16}
            fontWeight={"medium"}
          >
            {t("total-price")}
          </TGText>
          <TGText fontWeight={"semibold"} fontSize={16}>
            {receipt?.cost} ₺
          </TGText>
        </div>
        <div className="ReceiptLine">
          <hr />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TGText
            color={"var(--tg-secondary-text)"}
            fontSize={16}
            fontWeight={"medium"}
          >
            {t("charging-station")}
          </TGText>
          <TGText fontWeight={"semibold"} fontSize={16}>
            {receipt?.stationName}
          </TGText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: 150,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("station-no")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16}>
              TG{receipt?.connector}
            </TGText>
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("date")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16}>
              {receipt?.endedAt}
            </TGText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: 150,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("charge-time")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16} textTransform="unset">
              {receipt?.duration ? formatDuration(receipt?.duration) : null}
            </TGText>
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("vehicle")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16}>
              {`${receipt?.vehicle}`}
            </TGText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: 150,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("unit-price")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16}>
              {receipt?.unitPrice} ₺
            </TGText>
          </div>
          {receipt?.consumption ? <div
            style={{
              flex: 1,
            }}
          >
            <TGText
              color={"var(--tg-secondary-text)"}
              fontSize={16}
              fontWeight={"medium"}
            >
              {t("total-consumption")}
            </TGText>
            <TGText fontWeight={"semibold"} fontSize={16} textTransform="unset">
              {receipt?.consumption} kWh
            </TGText>
          </div> : null}
        </div>
        {/* TODO: ENABLE WHEN CARD DATA IS DYNAMIC  */}
        {/* <Card /> */}
        <ActionButton />
      </div>
    </div>
  );
};

export default Content;
