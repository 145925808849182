import { useHistory } from "react-router";
import { formatDate } from "../../helpers";
import { TGIcon, TGText } from "../TGElements";

const TicketItem = ({ ticket, isDetail=false }) => {
  const history = useHistory()
  return (
    <div className="TicketItem" onClick={() => !isDetail && history.push(`/support/tickets/${ticket.id}`)}>
        <div className="TicketInner">
            <div className="TicketShortInfo">
                <TGText fontWeight={500}>{ticket.subject}</TGText>
                <TGText color="var(--tg-secondary-text)" fontSize={14}>
                {formatDate(ticket.created_at)}
                </TGText>
            </div>
            <div className="TicketSecondLine">
                <TGText>#{ticket.id}</TGText>
                <div className="TicketStatus">
                <span style={{ background: ticket.status.color }} />
                <TGText fontSize={14}>{ticket.status.name}</TGText>
                </div>
            </div>
        </div>
        {!isDetail && <TGIcon name="right-arrow" width={16} height={24}/>}
    </div>
  );
};

export default TicketItem;
