//Redux
import {store} from "../redux/store"
import {setUserPosition} from "../redux/slices/mapSlice"
//Capacitor
import {Geolocation} from '@capacitor/geolocation';
//Helper
import {getStationDetail} from "./apiFunctions";
import {Alert} from "./alert";
import {t} from "i18next"
//Assets
import {MapMarkerIcons} from "../assets/mapMarkerIcons";
import {MarkerClusterer} from "@googlemaps/markerclusterer";

export const getLocation = async () => {
    const options = {enableHighAccuracy: true, timeout: 5000, maximumAge: 30000};
    try {
        const {coords: {latitude, longitude}} = await Geolocation.getCurrentPosition(options);
        return handleCoordinates({latitude, longitude})
    } catch (err) {
        Alert.error(t("cant-get-location"))
    }
}

export const watchPosition = async () => {
    //watch position on success
    const onSuccessCallback = (response) => {
        if (response && response.coords) {
            handleCoordinates(response.coords)
        }
    }
    const coordinates = await Geolocation.watchPosition({enableHighAccuracy: true}, onSuccessCallback)
    return () => Geolocation.clearWatch({id: coordinates})
}

const handleCoordinates = ({latitude, longitude}) => {
    const {userPosition} = store.getState().map

    if (userPosition.lat !== latitude || userPosition.lng !== longitude) {
        store.dispatch(setUserPosition({lat: latitude, lng: longitude}))
    }

    return {lat: latitude, lng: longitude}
}

const onMarkerClick = (station, map) => {
    const OPEN_STATION_DETAIL_MODAL = true
    getStationDetail(station, OPEN_STATION_DETAIL_MODAL)
    // setNewCenter(station, map)
}

/*
const setNewCenter = (station, map) => {
    // TODO: zoom level should be the same as the current and new center is not working correct
    const newCenter = {lat: parseFloat(station.lat) - 1, lng: parseFloat(station.lon)}
    map.setCenter(newCenter);
    map.setZoom(7)
}
 */

export const createStationMarkers = (maps, map, stations) => {
    const markers = stations.map((station) => {
        const marker = new maps.Marker({
            position: {lat: parseFloat(station.lat), lng: parseFloat(station.lon)},
            map,
            icon: `data:image/svg+xml;base64,${window.btoa(MapMarkerIcons['station'])}`,
            station
        })

        marker.addListener("click", () => onMarkerClick(station, map));

        return marker;
    })

    const renderer = {
        render: ({position, count}) => (
            new maps.Marker({
                icon: `data:image/svg+xml;base64,${window.btoa(MapMarkerIcons[`cluster_station`](count))}`,
                position,
                zIndex: 1
            })
        )
    }

    return new MarkerClusterer({markers, map, renderer})
}

export const createCenterMarker = (maps, map, position, heading) => {
    const marker = new maps.Marker({
        position,
        map,
        icon: `data:image/svg+xml;base64,${window.btoa(MapMarkerIcons.centerMarker(Number(heading)))}`,
        zIndex: 2
    });
    return marker;
}


