import {t} from "i18next";
import React, {useEffect, useState} from "react";
import Subscriptions from "./Subscriptions";
import {api} from "../../helpers";
import MembershipVehiclesTab from "./MembershipVehiclesTab";
import {TGIcon} from "../TGElements";

const ParkDetailContainer = ({selectedPark}) => {
    const [activeTab, setActiveTab] = useState("vehicles");
    const [vehicles, setVehicles] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null)
    const [membershipDetail, setMembershipDetail] = useState(null);
    const getMembershipDetail = () => {
        api.getMembershipDetail(selectedPark).then((r) => {
            let data = r?.data.data;
            if (data) {
                data.userVehicles && setVehicles(formatVehicles(data.userVehicles));
                data.userSubscriptions && setSubscriptions(data.userSubscriptions);

                setMembershipDetail({
                    availableQuota: data.availableQuota,
                    freeQuota: data.freeQuota,
                    subscriptionCount: data.subscriptionCount,
                    vehicleCount: data.vehicleCount
                })
            }
        })
    }
    useEffect(() => {
        if (selectedPark) {
            getMembershipDetail()
        }
    }, [selectedPark])

    const formatVehicles = (userVehicles) => {
        let formatted = [];
        userVehicles.forEach((value) => {
            value.vehicle.model_text = value.vehicle.model;
            value.vehicle.uid = value.id;
            delete value.vehicle.model;
            formatted.push(value.vehicle)
        })
        return formatted
    }

    return (selectedPark &&
        <div className={"ParkDetailContainer"}>
            <div className={"parkDetailSummaryContainer"}>
                <p className={"title"}>{t("parking-rights-details")}</p>


                <p className={"summary"}>{t("free")}:
                    <span> {membershipDetail ? membershipDetail.freeQuota : 0}</span>
                    {/*
                    TODO::UNCOMMENT WHEN SUBSCRIPTION IS READY
                    <span className={"seperator"}></span> {t("subscription")}:
                    <span> {membershipDetail ? membershipDetail.subscriptionCount : 0}</span>*/}
                </p>
                <div style={{display:"flex"}}>
                    <TGIcon style={{"marginTop":"4px", marginRight:"3px"}} name="info-blue" width={16} height={16} />
                    <p className={"info"}> *{t("you-can-add-total-x-vehicles", {count: membershipDetail ? membershipDetail.availableQuota : 0})}</p>
                </div>

            </div>


            <div className={"ParkDetailTabsContainer"}>
                <p onClick={() => setActiveTab("vehicles")}
                   className={`${activeTab === 'vehicles' && 'active'}`}>{t("vehicles")}</p>
                {/*
                    TODO::UNCOMMENT WHEN SUBSCRIPTION IS READY

                  <p onClick={() => setActiveTab("subscriptions")}
                   className={`${activeTab === 'subscriptions' && 'active'}`}>{t("my-subscriptions")}</p>
                   */}
            </div>

            {activeTab === 'vehicles' &&
                <MembershipVehiclesTab getMembershipDetail={getMembershipDetail} vehicles={vehicles}
                                       parkId={selectedPark}/>
            }

            {activeTab === 'subscriptions' &&
                <Subscriptions subscriptions={subscriptions}/>
            }
        </div>

    );
};

export default ParkDetailContainer;
