import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import {isUserAbleToStartSession, startScan} from "./helpers";

const AppUrlListener = () => {
  let history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', async (event) => {

      const code = event.url.split('/').pop();

      if (!code) return;

      const isAble = await isUserAbleToStartSession()
      if(!isAble) return;

      history.push('/start-session',{ connection_id: code });
    });

  }, []);

  return null;
};

export default AppUrlListener;