import React, { useEffect } from 'react'
import { TGIcon, TGItem, TGList, TGPage } from '../components'
import {t} from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { setMenuModal } from '../redux/slices/modalSlice'
import { legal } from '../components/Menu/MenuContent/MenuItems'
const Agreements = () => {
  const history = useHistory()
  const {menuModal} = useSelector(state => state.modal)
  const dispatch = useDispatch()

  useEffect(() => {
    if(menuModal.goRouteWhileDismissing !== ''){
      dispatch(setMenuModal({
          status: false,
          goRouteWhileDismissing: ''
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const LeftIcon = ({...props}) => {
    return (
      <div className='TGItemIconWrapper'>
        <TGIcon {...props}/>
      </div>
    )
  }
  return (
    <TGPage
      title={t("agreements")}
      scrollY={false}
      backButton={true}
      onBack={() => history.goBack()}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end"}
    >
      <TGList>
        {legal.map((item) => (
          <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => history.push(`/agreements/${item.slug}`)}
            leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width} height={item.leftIcon.height}/>}
          />
        ))}
      </TGList>
    </TGPage>
  )
}

export default Agreements