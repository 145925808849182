import { useEffect, useRef, useState } from "react";
import { TGPage, TGText } from "../index";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMenuModal } from "../../redux/slices/modalSlice";
import { getContent } from "../../helpers";
import TGSkeleton from "./TGSkeleton";
import TGModal from "./TGModal";

const TGDefaultPage = ({ slug, isOpen=false, onBack=null, type="page" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageContentRef = useRef();
  const [content, setContent] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(isOpen) return;
    dispatch(
      setMenuModal({
        status: false,
        goRouteWhileDismissing: "",
      })
    );
    slug && handleContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if(!isOpen) return;
    slug && handleContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleContent = async () => {
    setLoading(true);
    const c = await getContent(slug);
    setContent(c);
    setLoading(false);
  };
  const LoadingSkeleton = () => {  
    const [skeletonCount, setSkeletonCount] = useState()
    const handleSkeletonCount = () => {
      setSkeletonCount(
        Math.floor(pageContentRef.current.getBoundingClientRect().height / 24)
      );
    }
    useEffect(() => {
      pageContentRef.current && handleSkeletonCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageContentRef])
    if(skeletonCount){
      return [...Array(skeletonCount).keys()].map(e => {
        const randomWidth49To99 = `${Math.floor(Math.random() * 50) + 50}%`
        return (
          <TGSkeleton width={randomWidth49To99} height={24} borderRadius={24} key={e}/>
        )
      })
    }
  };

  return type === 'page' ? (
    <TGPage
      title={t(slug)}
      scrollY={!isLoading}
      backButton={true}
      onBack={onBack}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end DefaultPage"}
      reference={pageContentRef}
    >
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </TGPage>
  ) : (
    <TGModal
      isOpen={isOpen}
      setIsOpen={() => isOpen && onBack(false)}
      breakpoints={[0, 0.7, 1]}
      initialBreakpoint={0.7}
      toolbar={false}
      cssClass="DefaultPage"
      iosSafeArea={false}
    >
      <div className="ion-padding-top">
        <TGText fontWeight={"semibold"} margin={"16px 0 16px 0"}>
          {t(slug)}
        </TGText>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
    </TGModal>
  );
};

export default TGDefaultPage;
