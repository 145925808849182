//Ionic
import { IonPage, IonContent } from "@ionic/react";
//Components
import { TGPageHeader, TGModalHeader, TGRefresher } from "./index";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../redux/slices/globalSlice";

const TGPage = ({
  children,
  title,
  scrollY = true,
  ionPadding, // send false, otherwise it will be applied
  fullscreen,
  backButton,
  onBack,
  onModalClose,
  rightButton,
  modalCloseButton = false,
  type = "page",
  toolbar = true,
  refresher,
  handleRefresh,
  isSafeArea,
  cssClass,
  isProfileEdit,
  reference
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {currentPath} = useSelector(state => state.global.route)
  
  useEffect(() => {
    if(currentPath === location.pathname.slice(1)) return;
    dispatch(setRoute({
      currentPath: location.pathname.slice(1),
      previousPath: currentPath
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const PageToolbar = () => {
    return type === "page" ? (
      <TGPageHeader
        title={title}
        backButton={backButton}
        rightButton={rightButton}
        onBack={onBack}
      />
    ) : (
      <TGModalHeader
        title={title}
        onModalClose={onModalClose}
        rightButton={rightButton}
        modalCloseButton={modalCloseButton}
        isProfileEdit={isProfileEdit}
      />
    );
  };

  return type === "page" ? (
    <IonPage>
      {toolbar && <PageToolbar />}
      <IonContent
        scrollY={scrollY}
        className={`
        ${cssClass ? cssClass : ""}
          TGPageContent
          ${ionPadding !== false ? "ion-padding-start ion-padding-end" : "ion-no-padding"}
          ${isSafeArea ? "iosSafeAreaTopPadding" : ""}
          `}
        fullscreen={fullscreen}
        ref={reference}
      >
        {(type === "page" || refresher) && (
          <TGRefresher
            handleRefresh={(e) => handleRefresh && handleRefresh(e)}
            disabled={type === "page" && refresher}
          />
        )}
        {children}
      </IonContent>
    </IonPage>
  ) : (
    <>
      {toolbar && <PageToolbar />}
      <IonContent
        scrollY={scrollY}
        className={`
        ${cssClass ? cssClass : ""}
          TGPageContent
          ${ionPadding !== false ? "ion-padding-start ion-padding-end" : "ion-no-padding"}
          ${isSafeArea ? "iosSafeAreaTopPadding" : ""}
          `}
        fullscreen={fullscreen}
      >
        {children}
      </IonContent>
    </>
  );
};

export default TGPage;
