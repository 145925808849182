import { TGItem, TGList, TGPage, TGToggle } from '../components'
import { Permissions } from '../assets/Permissions'
import { useEffect } from 'react'
import { getPermissions, handlePermissions } from '../helpers'
import { App } from '@capacitor/app'
import { Alert } from '../helpers/alert'
import { useSelector } from 'react-redux'
const ManagePermissions = () => {
  const {permissions} = useSelector(state => state.device)

  const checkPermissions = () => {
    getPermissions('location')
    getPermissions('camera')
    getPermissions('notifications')
  }

  useEffect(() => {
    checkPermissions()
    App.addListener('appStateChange', ({isActive}) => isActive && checkPermissions());
    return () => App.removeAllListeners().catch(err => Alert.error(err));
  }, [])

  return (
    <TGPage
      scrollY={false}
      backButton={true}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end"}
    >
      <TGList title="manage-permissions">
        {Permissions.map((item) => (
          <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => handlePermissions(item)}
            rightIcon={<TGToggle
                checked={
                  item.slug === "camera"
                    ? permissions.camera
                    : item.slug === "notifications" ? permissions.notifications
                    : permissions.location
                }
              />}
            leftIcon={false}
          />
        ))}
      </TGList>
    </TGPage>
  );
}

export default ManagePermissions