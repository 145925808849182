import React, {useEffect} from 'react'

const ElapsedTimer = ({ isActive, seconds, setSeconds, classname }) => {
    useEffect(() => {
        if(isActive){
            const interval = setInterval(() => {
                if (setSeconds) {
                    setSeconds(prevSeconds => prevSeconds + 1)
                }
            },1000);

            return () => clearInterval(interval);

        }
    }, [isActive, setSeconds]);

    const formatSeconds = seconds => {
        const pad = n => n<10 ? '0'+n : n;

        const h = Math.floor(seconds/3600);
        const m = Math.floor((seconds/60)-(h*60));
        const s = Math.floor(seconds - h * 3600 - m * 60);

        return `${pad(h)}:${pad(m)}:${pad(s)}`;
    }

    return (
        <p className={classname}>{formatSeconds(seconds)}</p>
    )
}

export default ElapsedTimer;