import {IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import {TGIcon, TGText} from "../../TGElements";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import {useHistory} from "react-router";
import {removeVehicle, setDefaultVehicle} from "../../../helpers";
import Vehicle from "./Vehicle";
import {useDialog} from "../../../hooks";
import { useRef } from "react";

const MyVehicle = ({ vehicle, canSetDefault }) => {
  const vehicleItem = useRef()
  const history = useHistory();
  const showDialog = useDialog();

  const closeSlidedButtons = () => {
    vehicleItem.current && vehicleItem.current.closeOpened()
  }

  const handleDeleteVehicle = () => {
    const deleteVehicle = async () => {
      const success = await removeVehicle(vehicle.plate)
      success && closeSlidedButtons()
    }
    showDialog(t("remove-vehicle-confirm"), "yes", deleteVehicle, "no");
  }

  const handleSetDefault = async () => {
    await setDefaultVehicle(vehicle.id)
    closeSlidedButtons()
  }

  const handleEdit = () => {
    closeSlidedButtons()
    history.push("/vehicles/add-vehicle/enter-plate", {
      vehicleToEdit: vehicle,
      from: "vehicles",
    })
  }

  const menuList = [
    {
      id: uniqid(),
      text: "remove-my-car",
      icon: "remove-vehicle",
      onClick: handleDeleteVehicle,
      color: "danger",
      isVisible: true
    },
    {
      id: uniqid(),
      text: "edit-vehicle",
      icon: "edit-vehicle",
      onClick: handleEdit,
      isVisible: true
    },
    {
      id: uniqid(),
      text: "make-default",
      icon: "star-outline",
      color: "success",
      disabled: vehicle.is_default,
      onClick: handleSetDefault,
      isVisible: canSetDefault
    },
  ];

  const { t } = useTranslation();

  return (
    <IonItemSliding
      ref={vehicleItem}
      class="MyVehicleWrapper ion-color-danger"
    >
      <Vehicle vehicle={vehicle} />
      <IonItemOptions side="end">
        {menuList.map((item, index) =>
          index !== 1 && item.isVisible ? (
            <IonItemOption
              color={item.color}
              onClick={() => item.onClick()}
              key={item.id}
              disabled={item.disabled}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 14,
                }}
              >
                <TGIcon name={item.icon} size={21} />
                <TGText
                  textAlign={"center"}
                  color={"var(--tg-white)"}
                  fontWeight={"medium"}
                  fontSize={"10"}
                  dangerouslySetInnerHTML={{ __html: t(item.text) }}
                />
              </div>
            </IonItemOption>
          ) : null
        )}
      </IonItemOptions>
      <div className={`MyVehicleWrapperBorder ${vehicle.is_default && 'Active'}`}></div>
    </IonItemSliding>
  );
};

export default MyVehicle;
