import React from 'react';
import {TGButton, TGPageHeader, TGText} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";
import lottiePlayer from "../LottiePlayer/LottiePlayer";
import {PlugCableAnimation} from "../../assets/lottie-animations";
import {LottiePlayer} from "../index";

const CableNotConnectedModal = ({isCableModalOpen, setCableModalOpen}) => {
    const {t} = useTranslation()
    return (
        <IonModal
            className={"TGModal"}
            isOpen={isCableModalOpen}
            breakpoints={[0.65, 0]}
            initialBreakpoint={0.65}
            backdropDismiss={false}
        >
            <div>
                <TGPageHeader backButton={false}/>
                <div style={{margin: "16px"}}>
                    <LottiePlayer
                        animationName={"plug-cable-animation"}
                        size={180}
                    />
                    <p style={{
                        fontSize: "18xp",
                        fontWeight: 600,
                        textAlign: "center",
                        marginTop: "30px"
                    }}>{t("cable-is-not-connected")}</p>
                    <p style={{
                        fontSize: "14xp",
                        textAlign: "center",
                        marginTop: "10px"
                    }}>{t("be-sure-cable-is-connected")}</p>
                    <TGButton backgroundColor={"var(--tg-dark)"} width={'100%'} margin={"40px 0 0 0"}
                              onButtonClick={() => setCableModalOpen(false)}>
                        {t("ok")}
                    </TGButton>
                </div>
            </div>
        </IonModal>
    );
};

export default CableNotConnectedModal;