//React
import { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/slices/globalSlice";
import { setStationDetailModalDismissingRoute } from "../redux/slices/modalSlice";
//Components
import { TGPage, Session, FailActiveSessionModal } from "../components";
//Helpers
import { useHistory } from "react-router";
import { getActiveSession } from "../helpers";
//Capacitor
import { App as CapacitorApp } from "@capacitor/app";
import CableNotConnectedModal from "../components/ActiveSession/CableNotConnectedModal";

const ActiveSession = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isIntervalStart, setIntervalStart] = useState(false)
  const {activeSession} = useSelector(state => state.session)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCableModalOpen, setIsCabelModelOpen] = useState(false)

  useEffect(() => {
    CapacitorApp.addListener('appStateChange', () => dispatch(setLoading(false)));
    if (history.location.state.isCableConnected !== undefined && !isModalOpen
    ) {
      setIsCabelModelOpen(!history.location.state.isCableConnected);
    }

    return () => CapacitorApp.removeAllListeners()

  }, [])

  const from =
    history.location.state && history.location.state.from
      ? history.location.state.from
      : "";

    useEffect(() => {
      if(!Object.keys(activeSession).length && isIntervalStart){
        setIsCabelModelOpen(false)
        setIsModalOpen(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSession])

  const closeModal = () => {
    setIsModalOpen(false)
    history.push("/map")
  }

  useEffect(() => {
    getActiveSession();
    setIntervalStart(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(isIntervalStart){
      const interval = setInterval(() => {
        const isLoading = false
        getActiveSession(isLoading).then((res) => {
          if (res?.total_consumption){
            setIsCabelModelOpen(false)
          }
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [isIntervalStart]);
  
  useEffect(() => {
    if (from === "station-detail") {
      dispatch(setStationDetailModalDismissingRoute(""));
    }

    return history.push(history.location.pathname, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  return (
    <TGPage toolbar={false} scrollY={false} isSafeArea={true}>
      <Session from={from} setIntervalStart={setIntervalStart}/>
      <CableNotConnectedModal isCableModalOpen={isCableModalOpen} setCableModalOpen={setIsCabelModelOpen}/>
      <FailActiveSessionModal isOpen={isModalOpen} closeModal={closeModal}/>
    </TGPage>
  );
};

export default ActiveSession;
