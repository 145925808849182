//React
import { useEffect, useState } from 'react'
//Components
import {
  TGPage,
  AddPaymentMethodForm,
} from "../components";
//Helpers
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const AddPaymentMethod = () => {
  
  const {t} = useTranslation()
  const history = useHistory()
  const type = history.location?.state?.type || "add-new"
  const [card, setCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });
  useEffect(() => {
    if(history.location?.state?.cardToEdit?.id){
      const cardToEdit = history.location?.state?.cardToEdit
      setCard({
        number: cardToEdit.number.split(' ').join(''),
        expiry: cardToEdit.expireDate.split('/').join(''),
        name: cardToEdit.name,
        cvc: cardToEdit.cvc
      })
    }
  }, [history.location?.state?.cardToEdit])
  return (
    <TGPage
      title={type === "edit" ? t("edit") : t("add-payment-method")}
      scrollY={false}
      backButton={true}
    >
      <AddPaymentMethodForm card={card} setCard={setCard} type={type} />
    </TGPage>
  );
}

export default AddPaymentMethod