//React
import { useEffect } from 'react'
//Components
import Card from './Card'
import { NoEntries } from '..'
//Redux
import { useSelector } from 'react-redux'
//Helpers
import { getPaymentMethods } from '../../helpers'

const CardList = ({setCardActionModalOpen}) => {
    const {paymentMethods} = useSelector(state => state.user)

    useEffect(() => {
        getPaymentMethods()
    }, [])
  return (
    <div className='CardList'>
        {paymentMethods?.length ? paymentMethods.map((card) => (
            <Card card={card} key={card.id} setCardActionModalOpen={setCardActionModalOpen}/>
        )) : <NoEntries />}
    </div>
    )
}

export default CardList