import {MapActionButtons, MapFilterButtons} from "./Partials";

const  MapButtons = ({map}) => {
    return (
        <>
            <MapActionButtons map={map} />
            <MapFilterButtons />
        </>
    );
}

export default MapButtons;
