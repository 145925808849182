import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch} from 'react-redux'
import {setMenuModal} from '../redux/slices/modalSlice'
import {useTranslation} from 'react-i18next'
import Balance from "../components/Wallet/balance";
import {TGPage} from "../components";
import Transactions from "../components/Wallet/transactions";
import {api} from "../helpers";
import WalletIntroductions from "../components/Wallet/walletIntroductions";

const Wallet = () => {
    const dispatch = useDispatch()
    const {push, location} = useHistory()
    const [transactionHistory, setTransactionHistory] = useState(null);

    const getTransactions = () => {
        api.getWalletTransactionHistory().then(({data}) => {
            if (data.success) {
                setTransactionHistory(data.data)
            }
        })
    }

    useEffect(() => {
        getTransactions()
    }, [])

    const {t} = useTranslation()
    const onBack = () => {
        push('/map', {menuModal: !location?.state?.hasOwnProperty('menuModal')})
    }
    useEffect(() => {
        dispatch(setMenuModal({
            status: false,
            goRouteWhileDismissing: ''
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <TGPage
            title={t("wallet")}
            scrollY={false}
            backButton={true}
            onBack={() => onBack()}
        >
            <div className={"Wallet"}>
                <Balance/>
                <div style={{marginTop: 30}}></div>
                {transactionHistory && transactionHistory.length ?
                    <Transactions transactions={transactionHistory}/>
                    :
                    <WalletIntroductions/>
                }
                <button onClick={() => push('/add-balance')} className={"AddBalanceButton"}>{t("add-balance")}</button>
            </div>
        </TGPage>
    )
}

export default Wallet