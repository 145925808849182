//components
import {TGText} from "../../TGElements";
//redux
import {useSelector} from "react-redux";

const Header = () => {
    const {currentStation} = useSelector(state => state.station)
    const {stationDetailModal} = useSelector(state => state.modal)
    return (
        <>
            <TGText fontSize={18} fontWeight={"semibold"}>
                {currentStation?.name ?? null}
            </TGText>
            {
                stationDetailModal.currentBreakpoint < 1 ? (
                    <TGText fontSize={14} fontWeight={"regular"} margin={"5px 0 0 0"}
                            color={"var(--tg-secondary-text)"}>
                        {currentStation.address}
                    </TGText>
                ) : null
            }
        </>
    );
};

export default Header;
