import {TGImg, TGText} from '../../TGElements';
import {Car, CarLights, ParkCharge} from '../../../assets/onboarding'
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from "react";

const Slide3 = ({activeIndex}) => {
    const isActive = activeIndex === 2
    const {t} = useTranslation()
    const [percentage, setPercentage] = useState(0)

    const randomPercentage = () => {
        const random = Math.floor(Math.random() * 10) + 1;

        setPercentage(prev => {
            const newPercentage = prev + random

            if (newPercentage >= 100) {
                return 100
            }

            return newPercentage
        })
    }
    
    useEffect(() => {
        if(isActive && percentage < 100){
            const timeout = setTimeout(() => {
                randomPercentage()
            }, 300)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isActive, percentage]);

    return (
        <div className='OnboardingSlide'>
            <div className='OnboardingSlideContent bg-active'>
                <TGImg
                    src={Car}
                    width={336}
                    height={177}
                    cssClass={`OnboardingSecondSlide_Car ${isActive && "animate"}`}
                />

                <TGImg
                    src={CarLights}
                    width={290}
                    height={104}
                    cssClass={`OnboardingSecondSlide_CarLights ${isActive && "animate"}`}
                />

                <div className={`OnboardingSecondSlide_Charge ${isActive && "animate"}`}>
                    <div className='OnboardingSecondSlide_Charge_firstLine'>
                        <TGImg src={ParkCharge} width={60} height={60} cssClass="OnboardingSecondSlide_ParkChargeIcon"/>
                        <TGText color={"var(--tg-primary-text)"} fontWeight={"semibold"} fontSize={37}>
                            %{percentage}
                        </TGText>
                    </div>
                    <TGText
                        color={"var(--tg-secondary-text)"}
                        fontWeight={"medium"}
                        fontSize={17}
                        textTransform="unset"
                    >
                        {t("charging")}
                    </TGText>
                </div>
            </div>
            <div className='OnboardingSlideTextContent'>
                <TGText
                    fontSize={20}
                    fontWeight={"semibold"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    margin={"36px 0 14px 0"}
                    width={"100%"}
                >
                    {t("start-charge")}
                </TGText>
                <TGText
                    fontSize={16}
                    fontWeight={"regular"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    width={"70%"}
                    textTransform={"unset"}
                >
                    {t("start-charge-text", {appName: process.env.REACT_APP_TITLE})}
                </TGText>
            </div>
        </div>
    );
}

export default Slide3;
