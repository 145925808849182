const Logo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="533.585"
      height="483.271"
      viewBox="0 0 533.585 483.271"
      {...props}
    >
      <g id="gosarj_renkli" transform="translate(0.001 -0.009)">
        <g id="_2418453940304" transform="translate(-0.001 0.009)">
          <path
            id="Path_314106"
            data-name="Path 314106"
            d="M1275.505,322.39a160.622,160.622,0,0,1-95.028-30.964,174.6,174.6,0,0,0,54.729-94.4,53.753,53.753,0,0,0,40.3,18.12V185.669h43.219v-13.4h-43.219V147.115h43.219v-13.4h-43.219V107.257a54.082,54.082,0,0,0-50.973,36.305,46.842,46.842,0,0,0-1.5,5.178H1114.79c.137-1.717.3-3.462.486-5.178a160.8,160.8,0,0,1,54.636-104.134c3.387-2.956,6.921-5.765,10.565-8.427A160.6,160.6,0,0,1,1284.437.25c84.058,4.544,151.088,74.511,152.245,158.689a161.188,161.188,0,0,1-161.177,163.453Z"
            transform="translate(-903.113 -0.009)"
            fill="#ff5125"
          />
          <path
            id="Path_314107"
            data-name="Path 314107"
            d="M322.382,162.158a155.956,155.956,0,0,1-.965,16.7A160.816,160.816,0,0,1,266.8,283q-5.107,4.423-10.618,8.446A160.6,160.6,0,0,1,152.228,322.17C68.177,317.617,1.165,247.644.014,163.479A161.209,161.209,0,0,1,256.211,31a174.62,174.62,0,0,0-54.722,94.4,53.921,53.921,0,0,0-94.16,38.836c1.57,28.7,26.253,51.505,54.988,50.918a53.206,53.206,0,0,0,19.869-4.251l-29.826-29.826a11.08,11.08,0,0,1,7.834-18.916H322.384Z"
            transform="translate(0.001 -0.026)"
            fill="#010001"
          />
          <path
            id="Path_314108"
            data-name="Path 314108"
            d="M55.042,1909.667,21.05,1926.411a48.713,48.713,0,0,0,18.221,21.261q12.655,8.468,31.541,10.723l3.965-.046v-32.468q-13.426-3.443-19.736-16.212Zm84.628-8.693a30.655,30.655,0,0,0-12.236-11.843,79.138,79.138,0,0,0-15.238-6.746q-7.021-2.133-18.111-5.516-15.739-3.979-21.168-7c-3.627-2.011-5.434-4.479-5.434-7.381a7.314,7.314,0,0,1,3.663-6.241c2.442-1.633,6.031-2.461,10.778-2.461q16.881,0,24.215,14.678l33.322-16.559a53.371,53.371,0,0,0-23.1-22.389q-15.118-7.71-34.653-7.711-23.1,0-38.546,11.29t-15.439,29.54q0,16.924,12.439,26.428t33.772,14.771q15.05,3.691,19.48,5.243,10.507,3.552,10.51,9.327,0,7.684-13.971,8.628v31.129l10.639-.129q19.994-3.016,31.541-13.44t11.549-26.6a34.588,34.588,0,0,0-4-17.009Z"
            transform="translate(-17.053 -1475.877)"
            fill="#010001"
          />
          <path
            id="Path_314109"
            data-name="Path 314109"
            d="M742.369,1875.606l-32.489,92.757H666.84l49.553-131.7h50.064l51.439,131.7H776.961Z"
            transform="translate(-540.22 -1487.915)"
            fill="#010001"
          />
          <path
            id="Path_314110"
            data-name="Path 314110"
            d="M1561.785,1867.714v29.54h21.992q7.328.191,11.995-3.951a14.659,14.659,0,0,0,0-21.449q-4.665-4.136-11.995-4.139h-21.992Zm41.318,100.658-27.1-42.522h-14.218v42.522H1522.02v-131.7h62.2q23.768,0,39.875,12.887T1640.2,1882.2a36.4,36.4,0,0,1-7.109,21.732,49.522,49.522,0,0,1-19.326,15.711l31.987,48.731H1603.1Z"
            transform="translate(-1233.017 -1487.923)"
            fill="#010001"
          />
          <path
            id="Path_314111"
            data-name="Path 314111"
            d="M2225.089,1971.187q-38.209,0-53.759-26.34l33.989-16.746a21.376,21.376,0,0,0,7.776,7.9q4.44,2.449,11.995,2.446,17.1,0,17.106-13.358V1836.66h39.763v88.993q0,21.265-15.439,33.4t-41.43,12.135Z"
            transform="translate(-1759.036 -1487.915)"
            fill="#010001"
          />
        </g>
      </g>
    </svg>
  );
};
export default Logo;
