//Ionic
import { IonBackdrop } from "@ionic/react";
//Components
import {Content} from "../components/Receipts";
import { LottiePlayer, TGPage } from "../components";
//Helpers
import { useHistory } from "react-router";

const Receipt = () => {
    const history = useHistory()
    const {receipt} = history.location.state
    return (
        <TGPage
            toolbar={false}
            scrollY={false}
            isSafeArea={true}
        >
            <div className="Receipt">
                <IonBackdrop visible={true} />
                <LottiePlayer
                    cssClass="SuccessAnim"
                    animationName={"success-animation"}
                    size={60}
                />
                <Content receipt={receipt}/>
            </div>
        </TGPage>
    );
};

export default Receipt;
