//Components
import { TGText } from "../../../TGElements";
import ExpandSocketTypesButton from "./ExpandSocketTypesButton";
import SocketTypesList from "./SocketTypesList";
//Helpers
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SocketTypes = ({ expandList, setExpandList }) => {
  const { t } = useTranslation();
  const { currentStation } = useSelector((state) => state.station);
  const connections = currentStation.DETAIL?.connections ?? [];

  return (
    <div className="SocketTypes"
    style={{
      margin: !expandList ? "30px 0 0 0" : "0 0 0 0"
    }}
    >
      <TGText fontSize={16} fontWeight={"medium"} margin="0 0 15px 0">
        {t("socket-types")}
      </TGText>
      <SocketTypesList isExpand={expandList} socketTypes={connections} />
      {connections.length > 6 ? (
        <ExpandSocketTypesButton
        expandList={expandList}
        setExpandList={setExpandList}
      />
      ) : null}
    </div>
  );
};

export default SocketTypes;
