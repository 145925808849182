import { useSelector } from "react-redux";
import { TGButton, TGIcon, TGImg, TGText } from "../../TGElements";

const ModelItem = ({image, id, modelName, handleClick}) => {
    const { brand } = useSelector((state) => state.newCar);
    return (
      <div className="ModelItem" onClick={() => handleClick(id)}>
        <div className="ModelImage">
          <TGImg src={image} width={155} height={81} reflect={true} />
        </div>
        <div className="ModelInfo">
          <TGText
            fontSize={20}
            fontWeight={"semibold"}
            color={"var(--tg-primary-color)"}
            width={125}
            wrapWithDots={true}
          >
            {brand.name}
          </TGText>
          <TGText
            fontSize={14}
            fontWeight={"regular"}
            color={"var(--tg-secondary-color)"}
            cssClass={"ModelName"}
            width={125}
            styles={{
              display: "-webkit-box",
              WebKitLineClamp: 1,
              WebKitBoxOrient: "vertical",
              overflow: "hidden",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              height: 32
            }}
          >
            {modelName}
          </TGText>
        </div>
        <TGButton
          backgroundColor={"var(--tg-black)"}
          backgroundActiveColor={"var(--tg-black)"}
          width={36}
          height={36}
          borderRadius={"36px"}
          additionalStyle={{
            minWidth: 36,
            minHeight: 36,
          }}
        >
          <TGIcon name="right-arrow-white" size={10} />
        </TGButton>
      </div>
    );
}
export default ModelItem;
