import React, { useEffect } from 'react'
import { TGIcon, TGItem, TGList, TGPage } from '../components'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setMenuModal } from '../redux/slices/modalSlice'
import { useTranslation } from 'react-i18next'
import { PaymentPageItems } from '../assets/Payments'

const Payments = () => {
    const {push} = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const onBack = () => {
        push('/map', { menuModal: true })
    }
    useEffect(() => {
        dispatch(setMenuModal({
            status: false,
            goRouteWhileDismissing: ''
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <TGPage
      title={t("payments")}
      scrollY={false}
      backButton={true}
      onBack={() => onBack()}
    >
      <TGList>
        {PaymentPageItems.map(item => (
          <TGItem
          key={item.id}
          slug={item.slug}
          handleClick={() => push(`/payments/${item.slug}`)}
          leftIcon={<TGIcon name={item.slug} size={42}/>}
        />
        ))}
      </TGList>
    </TGPage>
  )
}

export default Payments