import { IonRadio, IonRadioGroup } from "@ionic/react"

const TGRadio = ({value, checked}) => {
    return (
        <IonRadioGroup value={checked ? value : ""}>
            <IonRadio className="TGRadio" value={value} />
        </IonRadioGroup>
    )
}

export default TGRadio