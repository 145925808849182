import React from 'react';
import {TGIcon, TGPageHeader} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";

const DeleteMembershipModal = ({isDeleteModalOpen, setIsDeleteModalOpen, deleteHandler}) => {
    const {t} = useTranslation()
    return (
        <IonModal
            className={"TGModal"}
            isOpen={isDeleteModalOpen}
            breakpoints={[0.65, 0]}
            initialBreakpoint={0.65}
            onDidDismiss={() => setIsDeleteModalOpen(false)}
        >
            <div>
                <TGPageHeader backButton={false}/>
                <div className={"deleteMembershipModal"}>
                    <TGIcon name="membership-remove" width={87} height={160}/>
                    <p>{t("remove-park-membership")}</p>
                    <p>{t("remove-park-are-you-sure")}</p>

                    <button onClick={() => deleteHandler()} className={"deleteButton"}>{t("delete")}
                    </button>
                </div>
            </div>
        </IonModal>
    );
};

export default DeleteMembershipModal;