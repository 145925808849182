import { createSlice } from "@reduxjs/toolkit"

export const mapSlice = createSlice({
  name: "mapSlice",
  initialState: {
    userPosition: {lat: 41.0201508487804, lng: 28.935167739848495},
    stations: [],
    stationsLoading: false,
    mapFilter: "charge"
  },
  reducers: {
    setUserPosition: (state, action) => {
      if(state.userPosition.lat !== action.payload.lat || state.userPosition.lng !== action.payload.lng) {
        state.userPosition = action.payload
      }
    },
    setStations: (state, action) => {
      state.stations = action.payload
    },
    setMapFilter: (state, action) => {
        state.mapFilter = action.payload
    },
    setStationsLoading: (state, action) => {
      state.stationsLoading = action.payload
    }
  },
})

export const {
    setUserPosition,
    setStations,
    setMapFilter,
    setStationsLoading
} = mapSlice.actions
export default mapSlice.reducer
