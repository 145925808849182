import { useEffect, useState } from 'react'
import { TGText } from '../TGElements'
import TicketItem from './TicketItem'
import { getTickets } from '../../helpers'
import {t} from 'i18next'
const TicketList = () => {

    const [tickets, setTickets] = useState([])

    useEffect(() => {
        getTickets()
        .then(res => setTickets(res))
    }, [])

  return (
    <>
        <TGText fontWeight={500} margin={"1rem 0"}>
            {t("ticket-list")}
        </TGText>
        <div className='TicketList'>
            {tickets.map(ticket => (<TicketItem key={ticket.id} ticket={ticket}>{ticket.subject}</TicketItem>))}
        </div>
    </>
  )
}

export default TicketList