import {useSelector} from "react-redux";
import {Header, SocketTypes, ActionButton, Prices, Address, FastCharge, ComingSoon} from "./components";

const Content = ({setExpandSocketTypesList, expandSocketTypesList}) => {
  const {currentStation} = useSelector(state => state.station)
  const isScrollMargin = !(currentStation?.DETAIL?.connections?.length <= 6)

  const isActive = currentStation?.is_operational
  return (
    <div style={{
      paddingTop: 24,
      paddingLeft: 8,
      marginBottom: (isScrollMargin && expandSocketTypesList) ? "100px" : 0
    }}>
      <Header/>
      <FastCharge/>
      <SocketTypes
        expandList={expandSocketTypesList}
        setExpandList={setExpandSocketTypesList}
      />
      {isActive ? (
        <>
          <ActionButton/>
          <Prices/>
          <Address/>
        </>
      ) : <ComingSoon/>}
    </div>
  )
}

export default Content