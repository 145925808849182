import {Capacitor} from "@capacitor/core";
import {useSelector} from "react-redux";
import {FilterButtons} from "./Buttons";

const MapFilterButtons = () => {

    const isNative = Capacitor.isNativePlatform()
    const isIos = Capacitor.getPlatform() === "ios"

    const {mapLoading} = useSelector(state => state.global)

    const buttons = [
        {name: "onlyAvailable", label: "available", width: 130, icon: {name: "default-charger", width: 20.7, height: 20.7}},
        {name: "dc", label: "DC", width: 90, icon: {name: "dc-charger", width: 14.9, height: 18.9}},
        {name: "ac", label: "AC", width: 90, icon: {name: "ac-charger", width: 16.7, height: 15}}

    ]

    return (
      <div
        className={`MapFilterButtons ${isNative && isIos ? "ios" : ""} ${
          !mapLoading.status ? "active" : ""
        }`}
      >
        {buttons.map(({ name, label, width, icon }) => (
          <FilterButtons key={name} name={name} label={label} width={width} icon={icon} />
        ))}
      </div>
    );
}

export default MapFilterButtons;
