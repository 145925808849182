import { IonRefresher, IonRefresherContent } from "@ionic/react";

const TGRefresher = ({handleRefresh, disabled}) => {
    const refreshHandler = e => {
        handleRefresh(e).finally(() => e.detail.complete());
    }
  return (
    <IonRefresher disabled={!disabled} slot="fixed" onIonRefresh={refreshHandler}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};

export default TGRefresher;
