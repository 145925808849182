//Components
import { TGText } from "../../../TGElements";
//Helpers
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SessionBottom = () => {
    const {t} = useTranslation();
    const {activeSession} = useSelector(state => state.session);

    const amount = (activeSession?.price) || 0;

    return (
        <div className="BottomLabels">
            <TGText fontSize={20} fontWeight={"regular"} textTransform={"uppercase"} color={"var(--tg-secondary-text)"}>
                {t("price")}:
            </TGText>
            <TGText fontSize={20} fontWeight={"bold"} textTransform={"none"}>
                {amount}{" "} ₺
            </TGText>
        </div>
    );
};

export default SessionBottom;
