import React from 'react'
import { TGIcon, TGText } from '../../TGElements'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const FastCharge = () => {
  const {t} = useTranslation()
  const {currentStation} = useSelector(state => state.station)
    return currentStation.is_fast_charge === 1 && (
    <div className='FastCharge'>
        <TGIcon name="fast-charge" width={35.1} height={24.1}/>
        <TGText
        fontSize={14}
        fontWeight={"normal"}
        >
            {t("fast-charge-text")}
        </TGText>
    </div>
  )
}

export default FastCharge