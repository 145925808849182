import { TGLink, TGText } from '../TGElements'
import {t} from 'i18next'

const CompleteLater = ({path, additionalStyles}) => {
  return (
    <TGLink href={path} styles={additionalStyles}>
        <TGText
        color={"var(--tg-secondary-text)"}
        fontSize={16}
        fontWeight="medium"
        textAlign={"center"}
        width={"100%"}
        >
        <span style={{ textDecoration: "underline", fontWeight: "medium" }}>
            {t("complete-later")}
        </span>
        </TGText>
    </TGLink>
  )
}

export default CompleteLater