//Redux
import {useDispatch, useSelector} from "react-redux";
import {setCountry} from "../../redux/slices/userSlice";
//Components
import {TGText} from "../TGElements";
//Flag Plugin
import ReactCountryFlag from 'react-country-flag'
import {memo} from "react";

const CountryItem = ({item, setOpen}) => {
    const dispatch = useDispatch()
    const {country} = useSelector(state => state.user)

    const clickHandler = () => {
        dispatch(setCountry(item))
        setOpen(false)
    }

    return (
        <li className="CountryItem" key={item.countryCode} onClick={() => clickHandler()}>
            <ReactCountryFlag
                countryCode={item.countryCode}
                style={{
                    pointerEvents: "none",
                    width: "28px",
                    height: "auto",
                    marginRight: "5px",
                    objectFit: "contain",
                    borderRadius: "3px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}
                svg
            />
            <TGText
                width={"calc(100% - 90px)"}
                wrapWithDots={true}
                fontSize={16}
                fontWeight={country.countryName === item.countryName ? 'semibold' : 'normal'}
                color={country.countryName === item.countryName ? 'var(--tg-primary-text)' : 'var(--tg-secondary-text)'}
            >
                {item.countryName} (+{item.countryPhoneCode})
            </TGText>
        </li>
    );
};

export default memo(CountryItem);
