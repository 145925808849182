//Redux
import { useSelector } from 'react-redux'
//Components
import DirectionButton from './DirectionButton'
import StartSessionButton from './StartSessionButton'

const ActionButton = () => {

    const {stationDetailModal} = useSelector(state => state.modal)
    const station = useSelector(state => state.station.currentStation)

    return (
        <div
        className='ActionButtons'
        style={{ top: `${parseFloat(stationDetailModal.currentBreakpoint) * 100 - 10}%` }}>
            <DirectionButton station={station}/>
            <StartSessionButton station={station}/>
        </div>
    )
}

export default ActionButton