import { TGButton, TGIcon, TGText } from '../../TGElements'
import { Browser } from '@capacitor/browser';
import {t} from "i18next"
import { useDialog } from '../../../hooks';
const DirectionButton = ({station}) => {
    const showDialog = useDialog()
    const handleDirection = async () => {
        await Browser.open({ url: `https://www.google.com/maps?q=${station.lat},${station.lon}` })
    }

    const BUTTON_STYLES = {
        width: "100%",
        height: 50,
    }

  return (
        <TGButton
        borderRadius={"36px"}
        onButtonClick={() => showDialog(t("map-redirect"), "yes", handleDirection, "no")}
        backgroundColor={"var(--tg-transparent)"}
        backgroundActiveColor={"var(--tg-transparent)"}
        borderColor={"var(--tg-purple)"}
        borderStyle={"solid"}
        borderWidth={"1px"}
        cssClass={"DirectionButton"}
        additionalStyle={BUTTON_STYLES}
        >
            <TGIcon name={"navigation"} size={13.17} fill={"var(--tg-purple)"} margin={"0 5px 0 0"}/>
            <TGText fontWeight={"medium"} color={"var(--tg-purple)"} fontSize={16}>
                {t("get-directions")}
            </TGText>
        </TGButton>
  )
}

export default DirectionButton