import {t} from "i18next";
import React from "react";
import WalletListItem from "./walletListItem";

const PackageDetail = ({selectedPackage, amount}) => {
    return (
        <div className={"PackageDetailContainer"}>
            <div className={"PackageDetail"}>
                <p style={{textAlign: "center", fontSize: "16px", fontWeight: 600}}>{t('amount')}:

                    {selectedPackage.discountedPrice ?
                        <del className={"DiscountedPrice"}> {selectedPackage.originalPrice}₺</del> : null
                    }

                    <span style={{fontSize: "24px", marginLeft: "5px"}}>
                        {selectedPackage.discountedPrice ?? selectedPackage.originalPrice}₺
                    </span>
                </p>
                <div style={{fontSize: "14px", fontWeight: 450}}>
                    <WalletListItem
                        text={t("wallet-package-detail-1", {rate: selectedPackage.discountRate})}/>
                    <WalletListItem text={t("wallet-package-detail-2")}/>
                    <WalletListItem text={t("wallet-package-detail-3")}/>
                </div>
                <div className={"PackagePrice"}>
                    <p style={{paddingTop: "8px"}}>{t("total")} <span
                        style={{fontSize: "20px", fontWeight: "bold"}}>{amount}kW</span></p>
                </div>
            </div>
        </div>
    )
};

export default PackageDetail;
