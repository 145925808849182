//React
import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
//React Redux
import {store, persistor} from './redux/store';
import {Provider} from 'react-redux';
import {setDeviceReady} from './redux/slices/deviceSlice';
import { PersistGate } from 'redux-persist/integration/react';
//Translation
import "./i18n.js"

document.addEventListener(
"deviceready",
  () => {
    store.dispatch(setDeviceReady(true))
  },
  false
);

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  );
