import {TGButton, TGText} from "../TGElements";
import {useTranslation} from "react-i18next";

const CompaniesListItem = ({company, deleteHandler}) => {
    const {t} = useTranslation()

    return (
        <div className={"companies-list-item-wrapper"}>
            <div className={"companies-list-item"} style={{marginBottom: 20}}>
                <TGText fontWeight={"semibold"} wrapWithDots={true}>{t("tax-number")}</TGText>
                <TGText wrapWithDots={true}>{company.company_code}</TGText>
            </div>

            <div className={"companies-list-item"}>
                <TGText fontWeight={"semibold"} wrapWithDots={true}>{t("company-name")}</TGText>
                <TGText wrapWithDots={true}>{company.company_name || "-"}</TGText>
            </div>

            <TGButton
                styles={{width: "100%"}}
                backgroundColor={"var(--tg-error)"}
                backgroundActiveColor={"var(--tg-error)"}
                height={40}
                margin={"16px 0 0 0"}
                onButtonClick={() => deleteHandler(company.id)}
            >
                {t("delete")}
            </TGButton>
        </div>
    );
}

export default CompaniesListItem;
