import { IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import { TGIcon, TGText } from "../TGElements";
import { useTranslation } from "react-i18next";
import { deleteNotification, setNotificationRead } from "../../helpers";
import { useRef } from "react";

const Notification = ({ notification }) => {
  const {t} = useTranslation()
  const itemRef = useRef()
  const markAsRead = async () => {
    const {id} = notification
    const {success} = await setNotificationRead(id)
    if(success){
      itemRef.current && itemRef.current.closeOpened()
    }
  }
  
  const removeNotification = () => {
    const {id} = notification
    deleteNotification(id)
  }

return (
  <IonItemSliding ref={itemRef}>
    <IonItem lines="none" className="Notification ion-margin-top">
        <TGIcon
          name={`notification-${notification.template?.slug}`}
          fill={notification.readed ? "var(--tg-inactive)" : "var(--tg-primary)"}
          stroke={notification.readed ? "var(--tg-inactive)" : "var(--tg-primary)"}
          margin={"0 16px 0 0"}
        />
        <TGText
          fontWeight={"regular"}
          fontSize={14}
          color={
            notification.readed
              ? "var(--tg-secondary-text)"
              : "var(--tg-primary-text )"
          }
          textTransform={"unset"}
        >
          {notification.message}
        </TGText>
    </IonItem>
    <IonItemOptions>
      <IonItemOption color="danger" onClick={() => removeNotification()}>
        <div className="NotificationSlideButton">
          <TGIcon name="remove-vehicle" />
          <TGText color={"var(--tg-white)"} fontSize={12} fontWeight={400} margin={"0 5px 0 0"}>
            {t("delete")}
          </TGText>
        </div>
      </IonItemOption>
      <IonItemOption color={`${notification.read ? "medium" : "success"}`} disabled={notification.read} onClick={() => markAsRead()}>
      <div className="NotificationSlideButton">
        <TGIcon name="read" />
        <TGText  color={"var(--tg-white)"} fontSize={12} fontWeight={400}>
          {t("read")}
        </TGText>
        </div>
      </IonItemOption>
    </IonItemOptions>
  </IonItemSliding>
);
}

export default Notification;
