import { useHistory } from 'react-router'
import TGButton from './TGButton'
import TGIcon from './TGIcon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const TGNotificationsButton = () => {
  const history = useHistory()
  const [isRead, setRead] = useState(false)
  const {notifications} = useSelector(state => state.user)
  useEffect(() => {
    if(notifications?.length){
      notifications.forEach(element => {
        if(!element.read){
          setRead(true)
        }
      });
    }
  }, [notifications])
  return (
    <TGButton
    width={32}
    height={32}
    borderRadius={"32px"}
    backgroundActiveColor={"var(--tg-transparent)"}
    backgroundColor={"var(--tg-item-gray50)"}
    cssClass={"TGNotificationsButton"}
    onButtonClick={() => history.push("/notifications")}
    >
        <span className='NotificationDot' style={{
            display: isRead ? 'inline-block' : 'none'
        }}/>
        <TGIcon name="notifications" width={18} height={22}/>
    </TGButton>
  )
}

export default TGNotificationsButton