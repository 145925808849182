import InvoiceItem from "./InvoiceItem"
import {NoEntries} from ".."
import InvoiceSkeleton from "./InvoiceSkeleton"

import {api, getInvoiceList} from "../../helpers"
import {useEffect, useState} from "react"
import {TGVirtualScroll} from "../TGElements"
import {Alert} from "../../helpers/alert";
import {t} from "i18next";

const InvoiceList = ({isLoading, lastPage}) => {
    const [invoices, setInvoices] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [unpaidExist, setUnpaidExist] = useState(null)

    const payAll = () => {
        setInvoices([]);
        setUnpaidExist(null);
        api.checkUserAbleToStartSession(true, true, false).then((res) => {
            if (!res?.data?.response.available) {
                Alert.error("Ödeme alınırken bir hata oluştu");
                return;
            }
            getInvoices();
        })
    };

    const getDept = () => {
        api.getUserDept().then((res) => {
            if (res?.data?.response?.debt) {
                setUnpaidExist(res.data.response)
                return
            }
            setUnpaidExist(false)
        });
    }
    const getInvoices = () => {
        getInvoiceList(false, {}, currentPage)
            .then((result) => {
                setInvoices(prevInvoices => {
                    return [...prevInvoices, ...result.invoices];
                });
            }).then(() => getDept())
    };

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    if (isLoading) {
        return <InvoiceSkeleton/>
    }


    return (
        <div className="InvoiceList">
            {unpaidExist &&
                <>
                    <div className="PayAllTextWrapper">
                        <p>{unpaidExist.message}</p>
                    </div>
                    <button onClick={payAll} className="TGButton PayAllButton">
                        {t("pay-all-debt")}
                    </button>
                </>
            }
            {invoices?.length ? (
                <TGVirtualScroll
                    data={invoices}
                    item={(idx, invoice) => <InvoiceItem invoice={invoice} key={idx}/>}
                    handleEnd={() => currentPage < lastPage && setCurrentPage(prev => prev + 1)}
                />
            ) : (
                <NoEntries/>
            )}
        </div>
    );
}

export default InvoiceList
