//Components
import { TGButton, TGIcon} from '../../../../TGElements';
//Helper functions
import { getLocation } from '../../../../../helpers';

const LocateButton = ({map}) => {
    const handleClicked = async () => {
        const coords = await getLocation();
        map.map.setCenter(coords)
        map.map.setZoom(15)
    }

    return (
        <TGButton
            width={48}
            height={48}
            borderRadius={"48px"}
            backgroundColor={"var(--tg-white)"}
            expand="none"
            cssClass="TGMapButton"
            onButtonClick={() => handleClicked()}
            backgroundActiveColor={"rgb(241, 241, 241)"}
            margin={"0 0 0 6px"}
        >
            <TGIcon name="locate" width={52} height={52} style={{
                transform: "translateY(2px)"
            }}/>
        </TGButton>
    );
}

export default LocateButton;
