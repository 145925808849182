import {TGIcon} from "../TGElements";
const WalletListItem = ({text}) => {
    return (
        <p style={{display: "flex", alignItems: "center"}}>
            <TGIcon
                name={"purple-star"}
                width={16}
                height={16}
                margin={"0 5px 0 20px"}
            />
            <span style={{lineHeight: "16px"}}>{text}</span>
        </p>
    );
};

export default WalletListItem;
