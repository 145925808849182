import { IonSkeletonText } from "@ionic/react";

const TGSkeleton = ({ width, height, borderRadius = 2, type = "default", margin }) => {
  const SKELETON_STYLE = {
    width,
    height,
    "--border-radius": borderRadius + "px",
    "--background": type === "secondary" ? "var(--tg-white)" : null,
    "--background-rgb": type === "secondary" ? "255, 255, 255" : null,
    margin
  };
  return <IonSkeletonText animated={true} style={SKELETON_STYLE} />;
};

export default TGSkeleton;
