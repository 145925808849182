import centerMarker from '../../assets/center-marker.png'
import {TGImg, TGSpinner} from '../TGElements'
import {useHistory} from 'react-router'
import {useSelector} from 'react-redux'

const ActiveSessionButton = () => {
    const history = useHistory()
    const {mapLoading} = useSelector(state => state.global)
    const {activeSession} = useSelector(state => state.session)
    return Object.keys(activeSession).length ? (
        <div className={`ActiveSessionButton ${!mapLoading.status ? "active" : ""}`} onClick={() => {
            history.push("/active-session", {
                from: "map"
            })
        }}>
            <TGImg src={centerMarker} height={40} additionalStyles={{
                position: 'absolute',
                zIndex: 1,
            }}/>
            <TGSpinner size={48} primaryColor={`var(--tg-charge)`} secondaryColor={"var(--tg-white)"}/>
        </div>
    ) : null
}

export default ActiveSessionButton