import React from 'react'
import { TGIcon, TGSpinner } from '../../TGElements';

const SearchInputIcons = ({type, searchLoading, inputText, setInputText}) => {
    return type === "search" ? (
        <TGIcon
          name="search"
          width={22}
          height={23.2}
          cssClass="TGSearchIcon"
        />
      ) : type === "search-modal" ? (
        !inputText ? (
          <TGIcon
            name="search-modal-input-search"
            width={18.5}
            height={19.5}
            cssClass="TGSearchIcon"
          />
        ) : searchLoading ? (
          <TGSpinner
          size={18}
          primaryColor={"var(--tg-inactive)"}
          secondaryColor={"var(--tg-white)"}
          />
        ) : (
          <TGIcon
            name="search-modal-input-clear"
            width={18}
            height={18}
            cssClass="TGSearchIcon clear"
            onClick={() => setInputText("")}
          />
        )
      ) : null;
}

export default SearchInputIcons
