import { useTranslation } from "react-i18next";
import { TGBackButton, TGButton, TGIcon, TGText } from "../TGElements";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { useDispatch, useSelector } from "react-redux";
import { setFlashlight, setScanner } from "../../redux/slices/deviceSlice";

const Scanner = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {flashlight} = useSelector(state => state.device)

  const handleFlash = async (type) => {
    const {isEnabled} = await BarcodeScanner.getTorchState()
    if(isEnabled || type === "close"){
      BarcodeScanner.disableTorch()
      dispatch(setFlashlight(false))
      return;
    }
    BarcodeScanner.enableTorch()
    dispatch(setFlashlight(true))
  }
  const stopScan = (redirect) => {
    handleFlash("close")
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    dispatch(setFlashlight(false))
    if(redirect) {
      dispatch(setScanner({status: false, goWhenDisable: "/start-session"}))
    }else{
      dispatch(setScanner(false))
    }
    document.body.classList.remove("qrscanner");
  }
  return (
    <div className='scanner-ui'>
      <div className={`background${flashlight ? "-flash-active" : ""}`}/>
      <div className="outline">
        <span className="frame-left-bottom"/>
        <span className="frame-right-bottom"/>
        <span className="frame-left-top"/>
        <span className="frame-right-top"/>
        <span className="scan-animated"/>
      </div>
      <TGBackButton
      type="secondary"
      onBack={stopScan}
      />
      <TGText
      color={"var(--tg-white)"}
      cssClass="scanText"
      margin={"0 auto"}
      >

        {t("scan-qr-and-start-charge")}
      </TGText>
      <TGButton margin={"0 auto"} cssClass={"EnterCodeButton"} width={200} onButtonClick={() => {
          stopScan(true)
      }}>
        {t("enter-code")}
      </TGButton>
      <TGButton
      backgroundColor={"var(--tg-transparent)"}
      backgroundActiveColor={"var(--tg-transparent)"}
      width={"fit-content"}
      onButtonClick={() => handleFlash()}
      cssClass="FlashButton"
      margin={"0 auto"}
      >
        <TGText
        color={"var(--tg-white)"}
        margin={"0 15px 0 0"}
        >
          {t(`${flashlight ? "close": "open"}-flash`)}
        </TGText>
        <TGIcon name={`${flashlight ? "close": "open"}-flash`} size={34}/>
      </TGButton>
    </div>
  );
};

export default Scanner;
