//React
import { useEffect, useState } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import { ElapsedTimer } from "../../../TimeCounters";
import { TGIcon, TGText } from "../../../TGElements";

const SessionTop = () => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const {activeSession} = useSelector(state => state.session)

  useEffect(() => {
    setElapsedTime(
      activeSession?.duration
    );
  }, [activeSession]);

  return (
    <>
      <ElapsedTimer
        seconds={elapsedTime}
        setSeconds={setElapsedTime}
        isActive={true}
        classname={"ElapsedTimeText"}
      />
      {Object.keys(activeSession).length ? (
        <div className="top-animated-consumption">
          <TGIcon name={`mono-charge`} size={30}/>
          <TGText fontSize={30} fontWeight={"bold"} textTransform={"none"}>
            {activeSession.total_consumption} kwt
          </TGText>
        </div>
      ): null}
    </>
  );
};

export default SessionTop;
