import { IonItem } from "@ionic/react";
import { TGImg, TGText } from "../../TGElements";

const Vehicle = ({ vehicle }) => {
  return (
    <IonItem className={`MyVehicle MyVehicleItem ${vehicle.is_default ? "DefaultVehicle" : ""}`}>
      <div>
        <TGImg
          src={vehicle.image}
          width={155}
          height={95}
          reflect={true}
          cssClass="VehicleImage"
        />
        <div
          style={{
            transform: "translateX(-25px)",
          }}
        >
          <TGImg src={vehicle.logo} size={40} />
          <div>
            <TGText
              fontSize={14}
              fontWeight={"medium"}
              color={"var(--tg-secondary-text)"}
              wrapWithDots={true}
            >
              {vehicle.brand_name}
            </TGText>
            <TGText
              fontSize={14}
              fontWeight={"medium"}
              width={120}
              wrapWithDots={true}
            >
              {vehicle.model_text}
            </TGText>
          </div>
          <div
            style={{
              border: "1px solid var(--tg-inactive)",
              width: 150,
              padding: "8px 0",
              borderRadius: 8,
              marginTop: 13,
            }}
          >
            <TGText
              margin={"0 auto"}
              textTransform={"uppercase"}
              fontSize={20}
              fontWeight={"bold"}
            >
              {vehicle.plate}
            </TGText>
          </div>
        </div>
      </div>
    </IonItem>
  );
};

export default Vehicle;
