import React, { useEffect, useState } from "react";
import { TGButton, TGPage, TGText, TGTextarea } from "../components";
import { useHistory, useParams } from "react-router";
import { t } from "i18next";
import { closeTicket, formatDate, getTicketDetails, sendTicketAnswer } from "../helpers";
import TicketItem from "../components/ContactUs/TicketItem";

const TicketDetail = () => {
  const history = useHistory();
  const params = useParams();
  const [ticketDetail, setTicketDetail] = useState(null)
  const [answer, setAnswer] = useState("")

  useEffect(() => {
    params.id && getTicketDetails(params.id).then(res => setTicketDetail(res));
  }, [params.id]);

  const sendAnswer = async () => {
    sendTicketAnswer(params.id, answer).then(res => {
      res.success && getTicketDetails(params.id).then(res => setTicketDetail(res));
    })
  }

  return (
    ticketDetail && (
      <TGPage
        title={t("contact-us")}
        scrollY={true}
        backButton={true}
        onBack={() => history.push("/support/tickets")}
        ionPadding={false}
        cssClass={"ion-padding-start ion-padding-end TicketDetail"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TGButton
            backgroundColor={"var(--tg-error)"}
            backgroundActiveColor={"var(--tg-error)"}
            width={123}
            height={35}
            margin={"1rem 0"}
            onButtonClick={() =>
              closeTicket(params.id).then(
                (res) =>
                  res.success && history.push("/support/tickets")
              )
            }
          >
            {t("close")}
          </TGButton>
        </div>
        <TicketItem ticket={ticketDetail} isDetail={true} />
        <TGTextarea rows={5} setValue={(e) => setAnswer(e.detail.value)} />
        <TGButton margin={"1rem 0"} onButtonClick={() => sendAnswer()}>
          {t("send")}
        </TGButton>
        {ticketDetail.contents.map((thread) => (
          <div
            key={thread.id}
            className={`TicketThread ${
              thread.user_id ? "customer" : "supervisor"
            }`}
          >
            <div className="ThreadTop">
              <TGText
                textTransform={"capitalize"}
                color="var(--tg-primary-text)"
                textDecoration={"underline"}
              >
                {thread.user?.display_name ?? thread.supervisor?.name}
              </TGText>
              <TGText
                textTransform={"capitalize"}
                color="var(--tg-primary-text)"
              >
                {formatDate(thread.created_at)}
              </TGText>
            </div>
            <TGText
              textTransform={"capitalize"}
              color="var(--tg-primary-text)"
              dangerouslySetInnerHTML={{ __html: thread.message }}
            />
          </div>
        ))}
      </TGPage>
    )
  );
};

export default TicketDetail;
