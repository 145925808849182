import { Logo, LogoMono } from "../assets/Custom/Logo";
const CustomLogo = ({ type, width, height, style, cssClass }) => {
  const props = { width, height, style, className: cssClass, type };
  if (type === "default") {
    return <Logo {...props} />;
  } else {
    return <LogoMono {...props} />;
  }
};

export default CustomLogo;
