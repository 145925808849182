import { IonAccordion, IonAccordionGroup, IonItem } from "@ionic/react";
import TGText from "./TGText";
const TGAccordionGroup = ({children, value, multiple=false}) => {
    return (
        <IonAccordionGroup multiple={multiple} value={value}>
            {children}
        </IonAccordionGroup>
    )
}
const TGAccordion = ({title, children, value, ...props}) => {
    return (
        <IonAccordion className="TGAccordion" value={value} {...props}>
            <IonItem slot="header" className="TGAccordionHeader" lines="none">
                <TGText fontWeight={"medium"} fontSize={16} width={"100%"} margin={"16px 0"} >
                    {title}
                </TGText>
                <span className="TGAccordionIcon"/>
            </IonItem>
            <div slot="content" className="TGAccordionContent">
                <TGText color="var(--tg-secondary-text)" fontWeight={"regular"} fontSize={14} textTransform={"none"} dangerouslySetInnerHTML={{ __html: children }}/>
            </div>
        </IonAccordion>
    );
};

TGAccordion.Group = TGAccordionGroup

export default TGAccordion;
