import CompaniesListItem from "./CompaniesListItem";
import "./CompaniesList.css"
import {useTranslation} from "react-i18next";

const CompaniesList = ({companies, deleteHandler}) => {
    const {t} = useTranslation()

    if (!companies.length) {
        return (
            <div className="companies-list">
                <div className="companies-list__empty">{t("no-company-found")}</div>
            </div>
        )
    }

    return (
        <div className={"companies-list"}>
            {companies.map(({company}) => <CompaniesListItem key={company.id} company={company} deleteHandler={deleteHandler}/>)}
        </div>
    );
}

export default CompaniesList;
