//Redux
import { useDispatch, useSelector } from 'react-redux'
import { setSearchModal } from '../../../../redux/slices/modalSlice'
//Components
import { TGIcon, TGSearchInput } from '../../../TGElements'
import { setSearchLoading, setSearchResults } from '../../../../redux/slices/searchSlice'
import { memo, useEffect, useState } from 'react'
import { getStations } from '../../../../helpers'
import { Alert } from '../../../../helpers/alert'
import {t} from 'i18next'

const SearchModalHeader = ({inputPlaceholder, modalRef, setModalFull, isModalFull}) => {
  
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("");
  const {userPosition} = useSelector(state => state.map)

  const handleFocus = (isFocus = true) => {
    if(!isFocus) {
      setModalFull(false)
    }else{
      if(!isModalFull){
        modalRef?.current?.setCurrentBreakpoint(1)
        setModalFull(true)
      }
    }
  }

  useEffect(() => {
    if(searchText === "") {
      dispatch(setSearchResults([]))
      return;
    }

    dispatch(setSearchLoading(true))
    const timer = setTimeout(() => {
      search()
    },1000);
    return () => clearTimeout(timer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const search = async () => {
    const {lat, lng} = userPosition
    getStations(lat, lng, searchText)
      .then(stations => dispatch(setSearchResults(stations)))
      .catch(() => Alert.error(t("try-again")))
      .finally(() => dispatch(setSearchLoading(false)))
  }

  return (
    <div className="SearchModalHeader">
      <TGIcon
        name={"search-modal-back"}
        size={34}
        onClick={() => {
          dispatch(setSearchModal(false));
          dispatch(setSearchResults([]))
        }}
      />
      <TGSearchInput
        type="search-modal"
        placeholder={inputPlaceholder}
        onSearchTextChange={e => setSearchText(e.target.value)}
        onInputFocus={handleFocus}
        onInputBlur={() => handleFocus(false)}
      />
      {/* <TGFabButton /> */}
    </div>
  );
}

export default memo(SearchModalHeader)