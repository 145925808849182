import { TGPage } from '../components'
import { Languages } from '../assets/Languages'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from '../redux/slices/userSlice'
import { TGIcon, TGItem, TGList, TGRadio } from '../components/TGElements'
const ChangeLanguage = () => {
  const dispatch = useDispatch()
  const {language} = useSelector(state => state.user)
  const handlleClick = (lng) => {
    dispatch(setLanguage({
      code: lng.code,
      name: lng.slug
    }))
  }
  return (
    <TGPage
      scrollY={false}
      backButton={true}
    >
      <TGList>
        {Languages.map(item => (
          <TGItem
            key={item.id}
            handleClick={() => handlleClick(item)}
            slug={item.slug}
            rightIcon={<TGRadio value={item.slug} checked={language.code === item.code} />}
            leftIcon={<TGIcon name={item.slug} size={36} style={{ borderRadius: 36 }}/>}
          />
        ))}
      </TGList>
    </TGPage>
  )
}

export default ChangeLanguage