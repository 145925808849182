//Ionic React
import { useRef, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setSearchModal } from "../../redux/slices/modalSlice";
//Components
import { TGModal } from "../TGElements";
import { SearchModalHeader, SearchModalContent } from "./components";
//Helpers
import { useTranslation } from "react-i18next";

const SearchModal = () => {
  const modalRef = useRef()
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { searchModal } = useSelector((state) => state.modal);
  const { mapFilter } = useSelector((state) => state.map);
  
  const [isModalFull, setModalFull] = useState(false)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0.3)

  // search input placeholder by station type
  const searchInputPlaceHolder =
    mapFilter === "park-charge"
      ? t("search-park-charge")
      : mapFilter === "charge"
      ? t("search-charge-station")
      : t("search-park");

  return (
    <TGModal
      isOpen={searchModal.status}
      setIsOpen={(status) => {
        dispatch(setSearchModal(status))
        setModalFull(false)
        setCurrentBreakpoint(0.3)
      }}
      onBreakpointDidChange={ev => {
        setCurrentBreakpoint(ev.detail.breakpoint)
      }}
      scrollY={false}
      toolbar={false}
      modalHandler={true}
      ionPadding={false}
      cssClass="SearchModal"
      breakpoints={[0, 0.3, 0.9, 1]}
      initialBreakpoint={0.3}
      iosSafeArea={false}
      reference={modalRef}
      fullScreen={true}
    >
      <SearchModalHeader
        inputPlaceholder={searchInputPlaceHolder}
        modalRef={modalRef}
        isModalFull={isModalFull}
        setModalFull={setModalFull}
      />
      <SearchModalContent currentBreakpoint={currentBreakpoint}/>
    </TGModal>
  );
};
export default SearchModal;
