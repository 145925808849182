//Components
import { TGBackButton, TGButton, TGIcon } from "../../../TGElements";
//Redux
import { useDispatch } from "react-redux";
import { setSessionInfoModal } from "../../../../redux/slices/modalSlice";
//Helpers
import { useHistory } from "react-router";
import ChargingSpeed from "./ChargingSpeed";

const SessionHeader = ({from}) => {
  const dispatch = useDispatch();
  const history = useHistory()
  return (
    <header className="SessionHeader">
      <TGBackButton type="secondary" onBack={() => {
        history.push("/map", from === "station-detail" ?{
          stationDetailModal: true
        } : {})
      }}/>
      <div>
        <TGButton
          width={40}
          height={40}
          backgroundColor={"var(--tg-transparent)"}
          backgroundActiveColor={"var(--tg-transparent)"}
          additionalStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onButtonClick={() => dispatch(setSessionInfoModal(true))}
        >
          <TGIcon name="session-info" size={22} />
        </TGButton>
        <ChargingSpeed />
      </div>
    </header>
  );
};

export default SessionHeader;
