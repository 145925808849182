import {useTranslation} from 'react-i18next';
import {TGImg, TGText} from "../../TGElements";
import {LastSlideCar, LastSlidePhone, LastSlideRoad} from "../../../assets/onboarding";

const Slide4 = ({activeIndex}) => {
    const isActive = activeIndex === 3
    const {t} = useTranslation()

    return (
        <div className='OnboardingSlide'>
            <div className='OnboardingSlideContent'>
                <TGImg
                    width={178}
                    height={371}
                    src={LastSlidePhone}
                    cssClass={`OnboardingLastSlidePhone ${isActive && "animate"}`}
                />
                <TGImg
                    src={LastSlideRoad}
                    cssClass={`OnboardingLastSlideRoad ${isActive && "animate"}`}
                />
                <TGImg
                    src={LastSlideCar}
                    cssClass={`OnboardingLastSlideCar ${isActive && "animate"}`}
                />
            </div>
            <div className='OnboardingSlideTextContent'>
                <TGText
                    fontSize={20}
                    fontWeight={"semibold"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    margin={"36px 0 14px 0"}
                    width={"100%"}
                >
                    {t("save-time")}
                </TGText>
                <TGText
                    fontSize={16}
                    fontWeight={"regular"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    width={"100%"}
                    textTransform={"unset"}
                    dangerouslySetInnerHTML={{__html: t("save-time-text")}}
                />
            </div>
        </div>
    );
}

export default Slide4;
