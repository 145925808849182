//Component
import { TGText } from '../../TGElements'
//Helpers
import { useTranslation } from 'react-i18next'
//Redux
import { useSelector } from 'react-redux'
import { ResultNotFound, SearchResultsList } from './SearchResults/index'

const SearchResults = () => {
    const {t} = useTranslation()
    const {searchResults} = useSelector(state => state.search)
    return (
        <div className='SearchResults'>
            <TGText
            fontWeight={"semibold"}
            fontSize={14}
            padding={"8px 16px"}
            >
                {t("searches-results")}
            </TGText>
            { !searchResults.length ? (
                    <ResultNotFound />
                ):(
                    <SearchResultsList />
                )}
        </div>
    )
}

export default SearchResults