import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import {TGIcon, TGPage} from "../components";
import PaymentMethods from "../components/Wallet/paymentMethods";
import Packages from "../components/Wallet/packages";
import {useEffect, useState} from "react";
import {api} from "../helpers";
import {Alert} from "../helpers/alert";

const AddBalance = () => {

    const {push} = useHistory()
    const {t} = useTranslation()
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [amount, setAmount] = useState(null)
    const [defaultCard, setDefaultCard] = useState(null);

    const onBack = () => {
        push('/wallet', {menuModal: true})
    }

    const buyBalance = () => {
        if (!defaultCard){
            Alert.error(t("no-registered-cards-description"))
            return
        }



        let res;
        if (selectedPackage) {
            res = api.buyBalanceWithPackageId(selectedPackage.id);
        }

        res.then((r) => {
            if (r?.data?.success){
                push('/balance-added', {amount:amount})
            }
        })


    }


    const getDefaultCard =  async () => {
        const result = await api.getDefaultCard()
        if(result.status === 200 && result.data?.data !== null){
            setDefaultCard(result.data.data[0])
        }
    };

    useEffect(() => {
        getDefaultCard()
    },[])



    return (
        <TGPage
            title={t("add-balance")}
            scrollY={false}
            backButton={true}
            onBack={() => onBack()}
        >
            <div className={"Wallet"}>
                <Packages amount={amount} setAmount={setAmount}
                          selectedPackage={selectedPackage}
                          setSelectedPackage={setSelectedPackage}/>

                <PaymentMethods defaultCard={defaultCard}/>
                {amount ?
                    <div className={`AddBalanceConfirmContainer`}>
                        <div style={{paddingLeft:"10px"}}>
                            <p className={"AmountToBePaidLabel"}>{t("amount-to-be-paid")}</p>
                            <p className={"AmountToBePaid"}>{selectedPackage.discountedPrice ?? selectedPackage.originalPrice}₺</p>
                        </div>
                        <button onClick={() => buyBalance()}
                                className={`AddBalanceConfirmButton ${!defaultCard && "disabled" }`}>
                            <TGIcon style={{marginRight:"7px"}} name={"wallet-with-plus"}/> {t("pay-balance")}</button>
                    </div>
                    : null
                }
            </div>
        </TGPage>
    )
}

export default AddBalance