import { useDispatch } from 'react-redux';
import { TGButton, TGIcon } from '../../../../TGElements';
import { setSearchModal } from '../../../../../redux/slices/modalSlice';

const SearchButton = () => {
    const dispatch = useDispatch()
    const searchOpen = () => {
        dispatch(setSearchModal(true))
    }
    return (
        <TGButton
        width={48}
        height={48}
        borderRadius={"48px"}
        backgroundColor={"var(--tg-white)"}
        expand="none"
        cssClass="TGMapButton"
        onButtonClick={() => searchOpen()}
        backgroundActiveColor={"var(--tg-item-gray)"}
        >
            <TGIcon name="search" width={22} height={23.2} />
        </TGButton>
    );
}

export default SearchButton;
