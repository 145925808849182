import React from "react";
import BottomToolbarItem from "./BottomToolbarItem";

// TODO: Remove this component and use `BottomToolbar.js` when park and park-charge is available

const BottomToolbarTemp = () => {
  return (
    <div className="BottomToolbarTemp">
      <BottomToolbarItem label={"charge-station"} name="charge" />
    </div>
  );
};

export default BottomToolbarTemp;