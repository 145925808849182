import Lottie from "lottie-react";

import {
    CardAddedAnimation,
    DeleteCardAnimation,
    MailAnimation,
    PlugCableAnimation,
    SetDefaultAnimation,
    SuccessAnimation
} from '../../assets/lottie-animations'

const LottiePlayer = ({animationName, width, height, margin, size, loop=false, cssClass, ...props}) => {
    const setAnimation = () => {
        switch (animationName) {
            case "mail-animation":
                return MailAnimation
            case "success-animation":
                return SuccessAnimation
            case "delete-card-animation":
                return DeleteCardAnimation
            case "set-default-animation":
                return SetDefaultAnimation
            case "add-card-animation":
                return CardAddedAnimation
            case "plug-cable-animation":
                return PlugCableAnimation
            default:
                return null
        }
    }
    const animation = setAnimation()
    const AnimationStyle = {
        width: size ?? width,
        height: size ?? height,
        margin: margin || "0 auto",
    }
    return <Lottie className={cssClass} animationData={animation} style={AnimationStyle} loop={loop} {...props}/>
}

export default LottiePlayer;
