import {useDispatch, useSelector} from "react-redux";
import {AppRate, AppRateReviewTypeAndroid, AppRateReviewTypeIos} from "@awesome-cordova-plugins/app-rate";
import {useEffect} from "react";
import {resetAppRate, setLastAnswer, setLastRateDate} from "../../redux/slices/appRateSlice";
import { setIsRequestedAppRate } from "../../redux/slices/userSlice";
import {store} from "../../redux/store";
import {Capacitor} from "@capacitor/core";

const useAppRate = (shown) => {
    const now = new Date()
    const dispatch = useDispatch()
    const {lastAnswerId, lastRateDate} = useSelector(state => state.appRate)
    // This variable is using for app re-openning scenerio
    const {isRequestAppRate} = useSelector(state => state.user)

    useEffect(() => {
        const lastRateAt = new Date(lastRateDate)
        // request once in 30 days
        if(Math.ceil((now - lastRateAt) / (1000 * 60 * 60 * 24)) >= 30) {
            dispatch(resetAppRate())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastRateDate])

    useEffect(() => {
        if (Capacitor.isNativePlatform() && shown) {
            const shouldRequestByAnswer = lastAnswerId === 2 || lastAnswerId === 0
            shouldRequestByAnswer && !isRequestAppRate && promptForRating()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const promptForRating = () => {
        AppRate.setPreferences({
            usesUntilPrompt: 2,
            simpleMode: true,
            showPromptForInAppReview: true,
            promptAgainForEachNewVersion: true,
            useLanguage: store.getState().user.language.code,
            reviewType: {
                ios: AppRateReviewTypeIos.InAppReview,
                android: AppRateReviewTypeAndroid.InAppReview
            },
            storeAppURL: {
                ios: process.env.REACT_APP_IOS_APP_ID,
                android: 'https://play.google.com/store/apps/details?id=' + process.env.REACT_APP_ANDROID_APP_ID,
            },
            callbacks: {
                onButtonClicked: (buttonIndex) => {
                    if (buttonIndex === 0) {
                        return;
                    }
                    dispatch(setLastAnswer(buttonIndex))
                    dispatch(setIsRequestedAppRate(true))
                    dispatch(setLastRateDate(now.toString()))
                },
            },
        })

        AppRate.promptForRating(true);
    }
}

export {useAppRate}
