import { TGButton, TGIcon } from '../../../../TGElements';
import {useHistory} from "react-router";

const CampaignButton = () => {
    const history = useHistory()

    return (
        <TGButton
        width={48}
        height={48}
        borderRadius={"48px"}
        backgroundColor={"var(--tg-primary)"}
        expand="none"
        cssClass="TGMapButton"
        onButtonClick={() => history.push("/campaign")}
        backgroundActiveColor={"var(--tg-item-gray)"}
        >
            <TGIcon name="gift-box" width={24.86} height={24.86} />
        </TGButton>
    );
}

export default CampaignButton;
