import { BottomToolbar } from "../BottomToolbar/index.js";
import CustomLogo from "../CustomLogo.js";
import TGIcon from "./TGIcon.js";

const TGBottomToolbar = () => {
    return (
        <div className='TGBottomToolbar'>
            <TGIcon name="anniversary" width={98} height={26} margin="0 0 8px 16px" fill="#ce0024"/>
            <CustomLogo width={100} type="vertical" cssClass="MonoLogo"/>
            <BottomToolbar />
        </div>
    );
}

export default TGBottomToolbar;
