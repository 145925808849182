//React & Ionic
import { useState } from 'react';
import { IonInput, IonItem } from '@ionic/react';
//Components
import TGText from './TGText';
import { SearchInputIcons } from '../Search';
//Redux
import { useSelector } from 'react-redux';

const TGInput = ({
    type,
    label,
    value,
    name,
    autofocus,
    disabled,
    required,
    textAlign,
    placeholder,
    onInputChange,
    onInputFocus,
    onInputBlur,
    borderRadius,
    margin,
    error,
    errorText,
    reference,
    inputmode,
    pattern,
    maxlength,
    additionalStyles,
    register
}) => {

    const [focus, setFocus] = useState(false)
    const [inputText, setInputText] = useState("")
    const {searchLoading} = useSelector(state => state.search)

    const WRAPPER_STYLE = {
        width: type === "search-modal" ? "calc(100% - 50px)" : "100%", // TODO: "calc(100% - 50px)" condition should be updated as "calc(100% - 130px)"
        margin: margin ? margin : "0px",
        ...additionalStyles
    }
    
    return (
        <div style={WRAPPER_STYLE}>
            <IonItem lines='none' className={`${type === "search-modal" ? "TGSearchModalInputWrapper" : "TGInputWrapper"}`}>
                <div className={`TGInputBorder ${focus || (inputText || value) ? "focus" : ""} ${error ? "error": ""}`}
                style={{
                    borderRadius: borderRadius || 60
                }}
                >
                    <IonInput
                    id="TGInput"
                    name={name}
                    className={`TGInput ${focus || (inputText || value) ? "focus" : ""}`}
                    type={type === "search" || type === "search-modal" ? "text" : type}
                    disabled={disabled}
                    required={required}
                    autofocus={autofocus}
                    placeholder={placeholder}
                    onIonInput={e => {
                        onInputChange && onInputChange(e)
                        setInputText(e.detail.value)
                    }}
                    onIonFocus={(evt) => {
                        onInputFocus && onInputFocus(evt)
                        setFocus(true)
                    }}
                    onIonBlur={(e) =>{
                        onInputBlur && onInputBlur(e)
                        setFocus(false)
                    }}
                    style={{textAlign: textAlign ? textAlign : "left"}}
                    value={value !== undefined ? value : inputText}
                    ref={reference}
                    inputmode={inputmode}
                    pattern={pattern}
                    maxlength={maxlength}
                    {...register}
                    />
                    {label && <label className={`TGInputLabel ${placeholder || focus || inputText ? "focus" : ""} ${error ? "--error" : ""}`}>{label}</label>}
                    <SearchInputIcons
                    searchLoading={searchLoading}
                    setInputText={setInputText}
                    inputText={inputText}
                    type={type}
                    />
                </div>
            </IonItem>
            {(error && errorText) && <TGText
            color={"var(--tg-error)"}
            fontSize={17}
            fontWeight={"medium"}
            margin={"10px 0 0 27px"}
            textTransform={"unset"}
            >    
                {errorText}
            </TGText>}
        </div>
    )
}

export default TGInput;
