import { IonList } from '@ionic/react'
import { useSelector } from 'react-redux'
import MyVehicle from './MyVehicle'
import { NoEntries } from '../..'
const MyVehicleList = () => {
  const {vehicles} = useSelector(state => state.user)
  return (
    <IonList>
      {vehicles.length ? (
        vehicles.map((vehicle) => (
          <MyVehicle vehicle={vehicle} key={vehicle.id} canSetDefault={!vehicle.is_default && vehicles.length !== 1}/>
        ))
      ) : (
        <NoEntries />
      )}
    </IonList>
  );
}

export default MyVehicleList
