import Cards from 'react-credit-cards-2';
import { TGIcon } from '../TGElements';
import { useDispatch } from 'react-redux';
import { setCardActionsModal } from '../../redux/slices/modalSlice';
const Card = ({card}) => {
  const dispatch = useDispatch()
  // const expiryMonth = card.card.exp_month.toString().replace(/\b(\d)\b/, '0$1');
  return (
      <div className="CreditCardWrapper" onClick={() => {
        dispatch(setCardActionsModal({status: true, selectedCard: card}))
    }}>
        <div className={`CreditCardBorder${card.default ? " Default" : ""}`}>
          <TGIcon name="more" size={29} />
          <Cards
            number={`**** **** **** ${card.card.last4}`}
            // expiry={`${expiryMonth}/${card.card.exp_year}`}
            expiry={"**/**"}
            cvc={"***"}
            name={card.holder_name}
            preview={true}
          />
        </div>
      </div>
  );
};

export default Card;
