//React
import { useEffect, useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
//Components
import { TGPage, InvoiceList, InvoiceFiltersModal } from "../components";
//Helpers
import { getInvoiceList } from "../helpers";
import { useDispatch } from "react-redux";
import { setInvoices } from "../redux/slices/userSlice";

const Invoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState(null)
  const [filtersModal, setFiltersModal] = useState(false);

  const DEFAULT_FILTERS = {
    startDate: null,
    category_id: null, 
    status_id: null
  }

  useEffect(() => {
    getInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvoices = (filters=DEFAULT_FILTERS) => {
    dispatch(setInvoices([]))
    getInvoiceList(false, filters)
    .then(res => {
      setLastPage(res.lastPage)
      dispatch(setInvoices(res.invoices))
    })
    .finally(() => setLoading(false));
  }

  return (
    <TGPage
      title={t("invoices")}
      scrollY={false}
      backButton={true}
      cssClass="InvoicesPage"
      ionPadding={false}
    >
      <InvoiceList isLoading={isLoading} lastPage={lastPage}/>
      <InvoiceFiltersModal
        isOpen={filtersModal}
        setOpen={setFiltersModal}
        getInvoices={getInvoices}
      />
    </TGPage>
  );
};

export default Invoices;
