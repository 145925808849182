import React from 'react';
import {TGIcon, TGSkeleton, TGText} from "../../TGElements";
import {useTranslation} from "react-i18next";

const HeaderInvoiceType = ({isBusiness}) => {
  const {t} = useTranslation()
  return (
    <>
      {isBusiness === undefined && (
        <TGSkeleton width={112} height={17} margin={'7px 0 0'}/>
      )}

      {isBusiness === 'business' && (
        <div className="invoice_business">
          <TGIcon name={"invoice-receipt"} height={17}/>
          <TGText styles={{lineHeight:'17px'}} fontSize={14} fontWeight={"semibold"}>
            {t("business_invoice")}
          </TGText>
        </div>
      )}

      {isBusiness === 'personal' && (
        <div style={{height:17, marginTop:7}}></div>
      )}
    </>
  );
};

export default HeaderInvoiceType;