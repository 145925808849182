import { useEffect } from 'react'
import { ContactForm, TGPage, TGText } from '../components'
import {t} from 'i18next'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setMenuModal } from '../redux/slices/modalSlice'

const ContactUs = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setMenuModal({ status: false, goRouteWhileDismissing: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TGPage
      title={t("contact-us")}
      scrollY={false}
      backButton={true}
      onBack={() => history.push("/support")}
    >
      <TGText fontWeight={500} margin={"1rem 0"}>
        {t("contact-form")}
      </TGText>
      <ContactForm />
    </TGPage>
  );
}

export default ContactUs