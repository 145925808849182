const TGSpinner = ({text, textColor, size, primaryColor, secondaryColor, styles}) => {
    return (
      <div className="TGSpinner" style={styles}>
        <div
          className="TGSpinnerWrapper"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: `${size}px`,
            background: `linear-gradient(to top, ${primaryColor}, ${secondaryColor})`,
          }}
        >
          <span
            className="TGSpinnerWhite"
            style={{
              background: `${secondaryColor}`,
            }}
          />
        </div>
        {text ? (
          <span
            className="TGSpinnerText"
            style={{
              color: `${textColor}`,
            }}
          >
            {text}
          </span>
        ) : null}
      </div>
    );
}

export default TGSpinner;
