import { CapacitorHttp } from '@capacitor/core';
import { store } from '../redux/store';

let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    Locale: store.getState().user.language.code
};

const handleToken = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const _token = user?.token;

    if (_token) {
        headers.Authorization = `Bearer ${_token}`;
        return true
    } else if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/register-phone" &&
        window.location.pathname !== "/register-phone/confirm"
        ) {
        window.location = "/register-phone";
        return false
    }
}

const get = async (path) => {
    const isToken = handleToken()
    const OPTIONS = {
        url: `${process.env.REACT_APP_API_URL}${path}`,
        headers: headers,
    };
    if(isToken){
        const response = await CapacitorHttp.get(OPTIONS);
        return response
    }
}
const post = async (path, data) => {
    const isToken = handleToken()
    const OPTIONS = {
        url: `${process.env.REACT_APP_API_URL}${path}`,
        headers: headers,
        data
    };
    if(isToken){
        const response = await CapacitorHttp.post(OPTIONS);
        return response
    }
}

export const fetch = async (type, path, data) => {
    if(type.toUpperCase() === "GET"){
        return await get(path)
    }else{
        return await post(path, data)
    }
}