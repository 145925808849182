//React
import { useEffect, useRef, useState } from "react";
//Components
import FiltersModalHeader from "./FiltersModalHeader";
import { TGButton, TGModal, TGSelect, TGText } from "../TGElements";
//Helpers
import { useTranslation } from "react-i18next";
import { getInvoiceFilters, handleDateFilter } from "../../helpers";
import { timeFilters } from "../../assets/InvoiceFilters";
import { Alert } from "../../helpers/alert";

const InvoiceFiltersModal = ({ isOpen, setOpen, getInvoices }) => {
  const modalRef = useRef()
  const {t} = useTranslation()
  const [filters, setFilters] = useState({})
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null)
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(null)

  useEffect(() => {
    isOpen && getFilters()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  
  const getFilters = async () => {
    const response = await getInvoiceFilters()
    if(response.success){
      setFilters(response.filters)
      return;
    }
    Alert.error(t("try-again"))
    setOpen(false)
  }

  const applyFilters = () => {
    const FILTERS = {
      startDate: handleDateFilter(selectedTimeFilter) || null,
      category_id: selectedCategory ?? null, 
      status_id: selectedInvoiceType ?? null
    }
    getInvoices(FILTERS)
      .then(() => setOpen(false))
      .catch(() => Alert.error(t("try-again")))
  }

  const clearFilters = () => {
    setSelectedCategory(null)
    setSelectedInvoiceType(null)
    setSelectedTimeFilter(null)
  }

  return Object.keys(filters).length > 0 ? (
    <TGModal
      isOpen={isOpen}
      setIsOpen={() => setOpen(false)}
      breakpoints={[0, 0.69]}
      initialBreakpoint={0.69}
      reference={modalRef}
      toolbar={false}
      scrollY={false}
    >
      <div className="InvoiceFiltersModalContent">
        <FiltersModalHeader clearFilters={clearFilters}/>
        <TGSelect
          title={t("time-period")}
          optionList={timeFilters}
          actionSheetOptions={{
            header: t("time-period"),
            subHeader: t("select-time-period")
          }}
          placeholder={t("this-month")}
          setSelected={setSelectedTimeFilter}
          selected={selectedTimeFilter}
          translation={true}
        />
        <TGSelect
          title={t("category")}
          optionList={filters.category}
          actionSheetOptions={{
            header: t("category"),
            subHeader: t("select-category"),
          }}
          placeholder={t("select-category")}
          setSelected={setSelectedCategory}
          selected={selectedCategory}
        />
        <TGSelect
          title={t("invoice-type")}
          optionList={filters.statuses}
          actionSheetOptions={{
            header: t("invoice-type"),
            subHeader: t("select-invoice-type"),
          }}
          placeholder={t("select-invoice-type")}
          setSelected={setSelectedInvoiceType}
          selected={selectedInvoiceType}
        />
        <TGButton
        onButtonClick={applyFilters}
        additionalStyle={{
          position: "absolute",
          top: `calc(70% - 85px)`,
          width: "calc(100% - 32px)"
        }}
        >
          <TGText color={"var(--tg-white)"}>
            {t("apply")}
          </TGText>
        </TGButton>
      </div>
    </TGModal>
  ) : null;
};

export default InvoiceFiltersModal;
