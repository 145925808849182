import { TGImg, TGText } from '../../../TGElements'
import SocketTypeAvailability from './SocketTypeAvailability'
const SocketTypeItem = ({item}) => {
  const {connection_type_name,icon_url, last_status} = item
  return (
    <div className='SocketTypeItem'>
        <TGImg type={"avatar"} src={icon_url} size={25}/>
        <div className='SocketTypeItemInfo'>
          <TGText
          fontSize={14}
          fontWeight={"semibold"}
          width={"115px"}
          wrapWithDots={true}
          >
            {connection_type_name}
          </TGText>
          <div className='SocketTypeItemInfoSecondLine'>
            <TGText
            color={"var(--tg-gray-2)"}
            fontSize={12}
            fontWeight={"regular"}
            >
            {item.power_kw} kW
            </TGText>
            <SocketTypeAvailability status={last_status}/>
          </div>
        </div>
    </div>
  )
}

export default SocketTypeItem