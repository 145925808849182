import { TGImg } from '../TGElements'
import Hidden_Car from '../../assets/images/hidden-car.png'
const HiddenCar = () => {
  return (
    <div className='HiddenCar'>
        <TGImg src={Hidden_Car} width={278} height={178} alt={"HIDDEN_CAR"} cssClass={"HiddenCarImage"}/>
        <div className='HiddenCarCircleFirst' />
        <div className='HiddenCarCircleSecond' />
        <div className='HiddenCarCircleThird' />
    </div>
  )
}

export default HiddenCar
