import TGButton from './TGButton';
import TGPlus from './TGPlus';
import TGText from './TGText';
import { useTranslation } from 'react-i18next';
const TGAddVehicleButton = ({onButtonClick, styles}) => {
    const {t} = useTranslation()
    return (
        <TGButton
        backgroundColor={"var(--tg-white)"}
        backgroundActiveColor="var(--tg-gray)"
        height={70}
        borderColor={"var(--tg-inactive-border)"}
        borderRadius={"20px"}
        borderStyle={"solid"}
        borderWidth={"1px"}
        onButtonClick={() => {
            onButtonClick && onButtonClick()
        }}
        additionalStyle={styles}
        >
            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", padding: "0 16px"}}>
                <TGText color={"var(--tg-primary-text)"}>{t("add-vehicle")}</TGText>
                <TGPlus />
            </div>
        </TGButton>
    );
}

export default TGAddVehicleButton;
