import {useEffect, useRef, useState} from "react";
import {StartSessionForm, TGPage, TGText, TGTooltip} from "../components";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {setStationDetailModalDismissingRoute} from "../redux/slices/modalSlice";
import {useTranslation} from "react-i18next";
import {isUserAbleToStartSession, startCharge} from "../helpers";
import {resetQrRedirectionState, setLoading} from "../redux/slices/globalSlice";

const StartSession = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [formError, setFormError] = useState(false);
    const historyRef = useRef(history.location?.state ?? null);

    useEffect(() => {

        if (historyRef?.current) {
            const {from, connection_id} = historyRef.current
            if (from === "station-detail") {
                dispatch(setStationDetailModalDismissingRoute(""));
            }
            !!connection_id && startSessionWithExternalQR(connection_id)
        }

        return () => {
            dispatch(resetQrRedirectionState())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyRef]);


    const startSession = async (code) => {
        const {start, isCableConnected} = await startCharge(code)
        dispatch(setLoading(false))
        start && history.push("/active-session", {isCableConnected: isCableConnected})
        return start
    }

    const startSessionWithExternalQR = async (code) => {
        dispatch(setLoading({status: true, message: t("charge-starting")}))
        const isReady = await isUserAbleToStartSession()
        isReady && startSession(code)
    }

    const submitHandler = async (code) => {
        dispatch(setLoading({status: true, message: t("charge-starting")}))
        const start = await startSession(code)
        setFormError(!start)
    }

    return (
        <TGPage
            toolbar={true}
            cssClass={"StartSession"}
            backButton={true}
            onBack={() => {
                history.push("/map", {stationDetailModal: historyRef?.current?.from === "station-detail"});
            }}
            scrollY={false}
            fullscreen={true}
        >
            <div className="StartSessionContent">
                <div className={"StartSessionHeader"}>
                    <TGText fontWeight={600} textAlign={"center"} margin={"0px auto"} textTransform={"unset"}>
                        {t("manual-charge")}
                    </TGText>

                    <div className="TooltipText">
                        <TGText
                            width={"fit-content"}
                            fontWeight={400}
                            fontSize={16}
                            color={"var(--tg-primary-text)"}
                            textAlign={"center"}
                            textTransform={"unset"}
                            dangerouslySetInnerHTML={{__html: t("enter-4digit-code")}}
                        />
                        <TGTooltip id="scan-tooltip">
                            <TGText fontSize={12} textTransform={"unset"}>
                                {t("charge-code-tip")}
                            </TGText>
                        </TGTooltip>
                    </div>
                </div>

                <StartSessionForm submitHandler={submitHandler} formError={formError}
                                  codeFromQR={historyRef.current?.connection_id}/>
            </div>
        </TGPage>
    );
};

export default StartSession;
