import {TGIcon, TGImg, TGText} from "../TGElements";
import {NoVehicleSearch} from "../../assets/images";
import {t} from "i18next";
import React, {useRef, useState} from "react";
import MembershipVehicle from "./MembershipVehicle";
import {
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
} from "@ionic/react";
import uniqid from "uniqid";
import DeleteMembershipModal from "./DeleteMembershipModal";
import {api} from "../../helpers";

const MembershipVehicles = ({
                                vehicles,
                                withDelete = true,
                                selectedVehicle = null,
                                setSelectedVehicle = null,
                                getMembershipDetail = null,
                                parkId = null
                            }) => {
    const subItem = useRef()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [removedVehicleId, setRemovedVehicleId] = useState(null)
    const openDeleteModal = (id) => {
        setRemovedVehicleId(id)
        setIsDeleteModalOpen(true);
    }

    const handleRemoveMembershipVehicle = () => {
        api.deleteMembershipVehicle(parkId, removedVehicleId).then((r) => {
            setIsDeleteModalOpen(false)
            getMembershipDetail(parkId, removedVehicleId);
        })
    }

    const menuList = [
        {
            id: uniqid(),
            text: "delete",
            icon: "remove-vehicle",
            onClick: openDeleteModal,
            color: "danger",
            isVisible: true
        },
    ];

    return (
        <div className={"MembershipVehicles"}>
            {vehicles && vehicles.length ?
                vehicles.map((vehicle) => (
                    withDelete ?
                        <IonItemSliding key={"slide"+vehicle.id} ref={subItem} className={"SubscriptionSlideItem"}>
                            <MembershipVehicle style={{backgroundColor:"white"}} vehicle={vehicle}/>
                            <IonItemOptions no-lines side="end">
                                {menuList.map((item, index) =>
                                    index !== 1 && item.isVisible ? (
                                        <IonItemOption
                                            color={item.color}
                                            onClick={() => item.onClick(vehicle.uid)}
                                            style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}
                                            key={vehicle.id}
                                            disabled={item.disabled}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    gap: 14,
                                                }}
                                            >
                                                <TGIcon name={item.icon} size={21}/>
                                                <TGText
                                                    textAlign={"center"}
                                                    color={"var(--tg-white)"}
                                                    fontWeight={"medium"}
                                                    fontSize={"10"}
                                                    dangerouslySetInnerHTML={{__html: t(item.text)}}
                                                />
                                            </div>
                                        </IonItemOption>
                                    ) : null
                                )}
                            </IonItemOptions>
                        </IonItemSliding>
                        :
                        <MembershipVehicle style={{marginTop: "10px"}}  vehicle={vehicle}/>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg src={NoVehicleSearch}/>
                    <p>{t("no-vehicles-added")}</p>
                    <p>{t("parking-benefit")}</p>
                </div>
            }

            <DeleteMembershipModal isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen}
                                  deleteHandler={handleRemoveMembershipVehicle}/>
        </div>
    );
};


export default MembershipVehicles;
