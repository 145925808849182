import { t } from "i18next";
import { TGPage, TicketList } from "../components";
import { useHistory } from "react-router";

const Tickets = () => {
  const history = useHistory();
  return (
    <TGPage
    title={t("contact-us")}
    scrollY={true}
    backButton={true}
    onBack={() => history.push("/support")}
    ionPadding={false}
    cssClass={"ion-padding-start ion-padding-end"}
    >
      <TicketList />
    </TGPage>
  );
};

export default Tickets;
