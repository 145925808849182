import { useSelector } from 'react-redux'
import { TGIcon, TGText } from '../../TGElements'
import { Browser } from '@capacitor/browser'
import { useDialog } from '../../../hooks'
import {t} from 'i18next'

const Address = () => {
    const showDialog = useDialog()
    const {currentStation} = useSelector(state => state.station)
    const {stationDetailModal} = useSelector(state => state.modal)
    const handleDirection = async () => {
        await Browser.open({ url: `https://www.google.com/maps?q=${currentStation.lat},${currentStation.lon}` })
    }
  return stationDetailModal.currentBreakpoint === 1 && (
    <div className='Address'>
        <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: "flex-start"
        }}
        onClick={() => showDialog(t("map-redirect"), "yes", handleDirection, "no")}
        >
            <TGText
            fontSize={14}
            fontWeight={"regular"}
            width={300}
            >
                {currentStation.address}
            </TGText>
            <div className='AddressIconWrapper'>
                <TGIcon name={"navigation"} size={13.17} fill={"var(--tg-white)"}/>
            </div>
        </div>
    </div>
  )
}

export default Address