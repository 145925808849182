import {IonSelect, IonSelectOption} from "@ionic/react";
import {t} from "i18next";
import React, {useEffect} from "react";

const ParkListDropdown = ({parkList, selectedPark, setSelectedPark}) => {

    useEffect(() => {
        if (parkList && !selectedPark){
            setSelectedPark(parkList[0].id)
        }

    }, [parkList])


    return (
        <IonSelect onIonChange={(e) => setSelectedPark(e.target.value)}
                   className={`ParkListDropdown ${selectedPark !== "" && "active"}`}
                   value={selectedPark}>
            <IonSelectOption value="">{t("choose-park-point")}</IonSelectOption>
            {parkList && parkList.map((park, key) => (
                <IonSelectOption key={key} value={park.id}>{park.name}</IonSelectOption>
            ))}
        </IonSelect>
    );
};

export default ParkListDropdown;
