import {Capacitor} from '@capacitor/core';
import {useSelector} from 'react-redux';
import {LocateButton, MenuButton, ScanButton, SearchButton} from "./Buttons";
import CampaignButton from "./Buttons/CampaignButton";

const MapActionButtons = ({map}) => {
    const {mapLoading} = useSelector(state => state.global)
    const {is_qr: isQrFromConfig} = useSelector(state => state.config)
    const isNative = Capacitor.isNativePlatform()
    const isIos = Capacitor.getPlatform() === "ios"
    
    return (
        <>
            <div className={`MapActionButtonsTop ${(isNative && isIos) ? "ios" : ""} ${!mapLoading.status ? "active" : ""}`}>
                <MenuButton />
                <SearchButton />
                <CampaignButton />
            </div>
            <div className={`MapActionButtonsBottom ${!mapLoading.status ? "active" : ""}`}>
                {(isNative && isQrFromConfig) && <ScanButton />}
                <LocateButton map={map} />
            </div>
        </>
    );
}

export default MapActionButtons;
