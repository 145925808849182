import ModelItem from './ModelItem';
import { useDispatch, useSelector } from 'react-redux';
import { setBrand, setModel } from '../../../redux/slices/newCarSlice';
import {setAddNewVehicleModal} from '../../../redux/slices/modalSlice';
import { BrandItem } from './BrandItem';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

const SetModel = ({activeTab, models, brands, isEdit, from}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {brand} = useSelector(state => state.newCar)
    const [sortedBrands, setSortedBrands] = useState([])

    const handleClick = model =>  {
        dispatch(setModel(model))
        dispatch(setAddNewVehicleModal(false))
        history.push("/vehicles/add-vehicle/vehicle-preview", {
            isEdit,
            from
        })
    }
    
    const sortBrands = () => {
        //create a new list of unselected brands
        let sortedArray = brands.filter(item => item.name !== brand.name);
        //add selected to first place
        sortedArray.unshift(brand);

        setSortedBrands(sortedArray)
    }

    useEffect(() => {
        if(activeTab === "model") sortBrands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand])

    return (
        <div className={`SetVehicle ${activeTab === "model" ? "active" : ""}`}>
            <div className='BrandsSlide'>
                {sortedBrands.length && sortedBrands?.map(b => <BrandItem key={b.id} img={b.thumb} name={b.name} onBrandClick={() => {
                    dispatch(setBrand(b))
                }}/>)}
            </div>
            <div className='Models'>
            {models && models.map(m => <ModelItem key={m.id} id={m.id} image={m.image_url} modelName={m.name} handleClick={() => handleClick(m)}/>)}
            </div>
        </div>
    );
}

export default SetModel;
