//Components
import { setMenuModal } from '../../../../../redux/slices/modalSlice';
import { TGButton, TGIcon } from '../../../../TGElements';
//Helpers
import { useDispatch } from 'react-redux';
import {isUserLoggedIn} from "../../../../../helpers";
import {useIonRouter} from "@ionic/react";

const MenuButton = () => {
    const dispatch = useDispatch()
    const router = useIonRouter()

    const onClickHandler = () => {
        isUserLoggedIn()
          ? dispatch(setMenuModal(true))
            : router.push('/register-phone')
    }

    return (
        <TGButton
        width={48}
        height={48}
        borderRadius={"48px"}
        backgroundColor={"var(--tg-white)"}
        expand="none"
        cssClass="TGMapButton"
        onButtonClick={onClickHandler}
        backgroundActiveColor={"var(--tg-item-gray)"}
        >
            <TGIcon name="menu" width={21} height={20.2}/>
        </TGButton>
    );
}

export default MenuButton;
