import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch} from 'react-redux'
import {setMenuModal} from '../redux/slices/modalSlice'
import {useTranslation} from 'react-i18next'
import {TGPage} from "../components";
import {api, getVehicles} from "../helpers";
import ParkListDropdown from "../components/Membership/ParkListDropdown";
import ParkDetailContainer from "../components/Membership/ParkDetailContainer";

const Membership = () => {
    const dispatch = useDispatch()
    const {push, location} = useHistory()
    const [selectedPark, setSelectedPark] = useState("");
    const [parkList, setParkList] = useState(null);
    const {t} = useTranslation()

    useEffect(() => {
        getVehicles();
        api.getParkList().then((r) => {
            r.data?.data && setParkList(r.data.data);
        });
    }, []);


    const onBack = () => {
        push('/map', {menuModal: !location?.state?.hasOwnProperty('menuModal')})
    }
    useEffect(() => {
        dispatch(setMenuModal({
            status: false,
            goRouteWhileDismissing: ''
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <TGPage
            title={t("membership")}
            scrollY={false}
            backButton={true}
            className={"Membership"}
            onBack={() => onBack()}
        >

            <ParkListDropdown parkList={parkList} selectedPark={selectedPark}
                              setSelectedPark={setSelectedPark}></ParkListDropdown>

            {selectedPark && <ParkDetailContainer selectedPark={selectedPark}/>}


        </TGPage>
    )
}

export default Membership