//Components
import {TGIcon, TGPage} from '../components';

const MyProfile = () => {
    return (
        <TGPage
        title={"my profile"}
        >
            <TGIcon name="search" width={22} height={23.2} />
        </TGPage>
    );
}

export default MyProfile;
