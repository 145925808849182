import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const useCustomPhoneValidation = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone))
  } catch (e) {
    return false;
  }
}