//Components
import { TGIcon, TGImg, TGText } from "../../../TGElements";
import SessionCar from "../../../../assets/images/session-car.png";
import ParkingArea from "../../../../assets/images/parking-area.svg";
import SessionVehicleClippingMask from "../../../../assets/SessionVehicleClippingMask";
//Helpers
import { useTranslation } from "react-i18next";

const ChargingVehicle = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="AnimatedVehicleContainer">
        <div className="box">
          <div className="main-liquid"></div>
          <div className="secondary-liquid"></div>
        </div>
        <SessionVehicleClippingMask />
        <TGImg src={ParkingArea} alt="PARKING_AREA" cssClass={"ParkingArea"} />
      </div>
      <TGImg src={SessionCar} alt="SESSION_CAR" cssClass={"SessionCar"} />
      <div className="ChargingText">
        <TGIcon name="charging" size={48} />
        <TGText fontWeight={"medium"} fontSize={13} color={"var(--tg-white)"}>
          {t("charging")}
        </TGText>
      </div>
    </>
  );
};

export default ChargingVehicle;
