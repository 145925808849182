import { IonItem, IonTextarea } from '@ionic/react'
import TGText from './TGText'

const TGTextarea = ({placeholder, setValue, error, onInputBlur, counter=false, errorText="", register, rows=10}) => {
  return (
    <>
    <IonItem lines="none" className='TGTextareaContainer'>
      <IonTextarea
        className={`TGTextarea ${error ? "error" : ""}`}
        onIonInput={setValue}
        rows={rows}
        onIonBlur={onInputBlur}
        counter={counter}
        counterFormatter={(inputLength) => `${inputLength}`}
        {...register}
      />
      {placeholder && <label className={`TGTextareaLabel ${error ? "error" : ""}`}>{placeholder}</label>}
    </IonItem>
      {(error && errorText) && <TGText
      color={"var(--tg-error)"}
      fontSize={17}
      fontWeight={"medium"}
      margin={"10px 0 0 27px"}
      textTransform={"unset"}
      >    
          {errorText}
      </TGText>}
    </>
  )
}

export default TGTextarea