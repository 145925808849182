import React from 'react';
import TGIcon from './TGIcon';

const TGPlus = () => {
    return (
        <span className='TGPlus'>
            <TGIcon name={"plus"} size={11.44}/>
        </span>
    );
}

export default TGPlus;
