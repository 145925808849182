import React from 'react'
import CustomLogo from '../../CustomLogo'
import { TGText } from '../../TGElements'
import TextLoading from './TextLoading'
import {t} from 'i18next'
import HeaderInvoiceType from "./HeaderInvoiceType";

const Header = ({id, isBusiness}) => {
  return (
    <div className="--invoice">
        <div className="line">
          <div className="left">
            <CustomLogo type={"mono"} width={93}/>
            <HeaderInvoiceType isBusiness={isBusiness}/>
          </div>
          <div className="right">
            <TGText fontSize={14}>{t("invoice-no")}:</TGText>
            <TextLoading fontSize={14} fontWeight={"semibold"}>
              {id ? `#${id}` : undefined}
            </TextLoading>
          </div>
        </div>
    </div>
  )
}

export default Header