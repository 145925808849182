import React from 'react';
import {TGButton, TGImg, TGModal, TGText} from "../TGElements";
import {useDispatch, useSelector} from "react-redux";
import {setCheckUserModal} from "../../redux/slices/modalSlice";
import {useTranslation} from "react-i18next";
import {useIonRouter} from "@ionic/react";
import paymentMethodImage from "../../assets/images/no-paymetMethod.svg";
import vehicleImage from "../../assets/images/no-vehicle.svg";
import debtImage from "../../assets/images/no-debt.svg";

const CheckUserModal = () => {
  const {t} = useTranslation()
  const checkUserModal = useSelector((state) => state.modal.checkUserModal);
  const dispatch = useDispatch();
  const router = useIonRouter();
  const isOpen = Object.values(checkUserModal).some((value) => value);

  // this type is vehicle, debt, paymentMethod
  const modalType = Object.keys(checkUserModal).find((key) => checkUserModal[key]);

  const onCloseHandler = () => {
    dispatch(setCheckUserModal({
      debt: false,
      vehicle: false,
      paymentMethod: false
    }));
  }

  const buttonClickHandler = () => {
    onCloseHandler();
    switch (modalType) {
      case "debt":
        router.push("/payments/invoices");
        break;
      case "vehicle":
        router.push("/vehicles/add-vehicle/enter-plate");
        break;
      case "paymentMethod":
        router.push("/payments/add-payment-method");
        break;
      default:
        break;
    }
  }

  return (
    <TGModal
      isOpen={isOpen}
      setIsOpen={onCloseHandler}
      scrollY={false}
      toolbar={true}
      modalHandler={false}
      breakpoints={[0.6]}
      initialBreakpoint={0.6}
      modalCloseButton={true}
      animated={isOpen}
    >
      {modalType && (
        <div style={{width:'100%',textAlign:'center'}}>
          <TGImg
            src={
              modalType === "paymentMethod" ? paymentMethodImage :
                modalType === "vehicle" ? vehicleImage :
                  debtImage
            }
            margin={modalType === "paymentMethod" && '0 22px 0 0'}
          />
        </div>
      )}

      <TGText
        textTransform="none"
        color="var(--tg-primary-text)"
        fontSize={20}
        fontWeight="semibold"
        textAlign="center"
        width="100%"
        margin={"35px 0 10px 0"}
      >
        {t(`checkUserModal.${modalType}.title`)}
      </TGText>
      <TGText
        textTransform="none"
        color={"var(--tg-secondary-text)"}
        fontSize={16}
        fontWeight="regular"
        textAlign={"center"}
        width="100%"
        margin={"0 0 25px 0"}
      >
        {t(`checkUserModal.${modalType}.description`)}
      </TGText>
      <TGButton onButtonClick={buttonClickHandler}>
        <TGText
          color={"var(--tg-white)"}
          fontSize={18}
          fontWeight="semibold"
        >
          {t(`checkUserModal.${modalType}.button`)}
        </TGText>
      </TGButton>
    </TGModal>
  );
};

export default CheckUserModal;