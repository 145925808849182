//Ionic & React
import { useEffect, useState } from "react"
//Components
import { TGButton, TGText } from "../../../TGElements"
import {NoRecentSearch, RecentSearchesList} from "./index"
//Helpers
import { useTranslation } from "react-i18next"
//Redux
import { useDispatch, useSelector } from "react-redux"
import { resetRecentSearches } from "../../../../redux/slices/searchSlice"

const RecentSearches = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {recentSearches} = useSelector(state => state.search)
  const [recentSearchesCopy, setRecentSearchesCopy] = useState([])
  
  const clearRecentSearches = () => dispatch(resetRecentSearches())

  useEffect(() => {
    let stringTypeOfRecentSearches = JSON.stringify(recentSearches)
    setRecentSearchesCopy(JSON.parse(stringTypeOfRecentSearches))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSearches]);

  return (
    <div className='RecentSearches'>
        <div className="RecentSearchesTitle">
          <TGText
          fontWeight={"semibold"}
          fontSize={14}
          padding={"8px 16px"}
          >
              {t("recent-searches")}
          </TGText>
          {recentSearches.length ? <TGButton
          width={60}
          height={22}
          backgroundColor={"var(--tg-gray)"}
          margin={"0 16px"}
          onButtonClick={() => clearRecentSearches()}
          backgroundActiveColor={"var(--tg-item-gray)"}
          >
            <TGText
            fontSize={12}
            fontWeight={"regular"}
            color={"var(--tg-white)"}
            >
              {t("clear")}
            </TGText>
          </TGButton> : null}
        </div>
        { recentSearchesCopy.length === 0 ? (
          <NoRecentSearch />
        ):(
          <RecentSearchesList
          recentSearchesCopy={recentSearchesCopy}
          setRecentSearchesCopy={setRecentSearchesCopy}
          />
        )}
    </div>
  )
}

export default RecentSearches