import {TGImg} from "../TGElements";
import {NoVehicleSearch} from "../../assets/images";
import {t} from "i18next";
import React, {useState} from "react";
import MembershipVehicles from "./MembershipVehicles";
import MembershipAddVehicleModal from "./MembershipAddVehicleModal";
import {api} from "../../helpers";

const MembershipVehiclesTab = ({vehicles, parkId, getMembershipDetail}) => {
    const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
    const [plate, setPlate] = useState(null)
    const addMembershipVehicle = () => {
        api.addMembershipVehicle(parkId, plate).then((r) => {
            if (r.data.success){
                getMembershipDetail()
                setIsAddVehicleModalOpen(false)
                setPlate(null)
            }
        })
    }

    return (
        <>
            {vehicles && vehicles.length ? (
                <MembershipVehicles parkId={parkId} vehicles={vehicles} getMembershipDetail={getMembershipDetail}/>
            ) : (
                <div className={"NoItemContainer"}>
                    <TGImg src={NoVehicleSearch}/>
                    <p>{t("no-vehicles-added")}</p>
                    <p>{t("parking-benefit")}</p>
                </div>
            )}
            <div onClick={() => setIsAddVehicleModalOpen(true)} className={"MembershipBottomGreenButton"}>
                {t("add-vehicle")}
            </div>
            <MembershipAddVehicleModal buttonHandler={addMembershipVehicle} plate={plate} setPlate={setPlate} isAddVehicleModalOpen={isAddVehicleModalOpen}
                                       setIsAddVehicleModalOpen={setIsAddVehicleModalOpen}/>
        </>
    );
};


export default MembershipVehiclesTab;
