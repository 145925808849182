import { useTranslation } from 'react-i18next'
import { TGImg, TGText } from '../../../TGElements'
import NotFound from '../../../../assets/no_search.png'
const ResultNotFound = () => {
    const {t} = useTranslation()
  return (
    <div className="SearchNotFound">
        <TGImg src={NotFound} width={228.3} height={124} margin={"30px 0 0 0"}/>
        <TGText width={268} textAlign={"center"} margin={"38px auto"}>
            {t("search-not-found")}
        </TGText>
    </div>
  )
}

export default ResultNotFound