import { useHistory } from 'react-router'
import TGButton from './TGButton'
import TGIcon from './TGIcon'
import TGText from './TGText'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const TGBackButton = ({onBack, type='primary', text="back"}) => {
  const history = useHistory()  
  const {t} = useTranslation()
  const {currentPath} = useSelector(state => state.global.route)
    const backButtonHandler = () => {
      if(onBack){
        onBack()
      }else{
        history.goBack()
      }
    }
    return type === 'primary' ? (
      <TGButton
      width={"fit-content"}
      height={19}
      backgroundColor={"var(--tg-transparent)"}
      backgroundActiveColor={"var(--tg-transparent)"}
      onButtonClick={() => backButtonHandler()}
      cssClass="TGBackButton"
      >
          <TGIcon name={"left-arrow"} width={11.57} height={11.57}/>
          <TGText color={"var(--tg-secondary-text)"}>{t(text)}</TGText>
      </TGButton>
    ) : (
      <TGButton
      width={"fit-content"}
      height={40}
      backgroundColor={"var(--tg-transparent)"}
      backgroundActiveColor={"var(--tg-transparent)"}
      onButtonClick={() => backButtonHandler()}
      cssClass="TGBackButton"
      >
        <div className='TGBackButtonIconWrapper'>
          <TGIcon name={"left-arrow"} width={11.57} height={11.57} fill={"white"}/>
        </div>
          <TGText color={currentPath === "active-session" ? "var(--tg-secondary-text)" : "var(--tg-white)"} fontWeight={"medium"}>{t(text)}</TGText>
      </TGButton>
    )
}

export default TGBackButton