import React from 'react';
import comingSoonImage from '../../../assets/images/coming-soon.svg';
import {TGImg} from "../../TGElements";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ComingSoon = () => {
  const {t} = useTranslation()
  const {currentStation} = useSelector(state => state.station)

  return (
    <div className="ComingSoon">
      <TGImg src={comingSoonImage} alt="Coming Soon"/>
      <div className="alert-wrapper">
        <span>{currentStation?.installation_date}</span>
        <p className="ion-no-margin">
          {currentStation?.installation_date ? t('install_date') : t('unknown_date')}
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;