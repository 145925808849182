import {useEffect, useState} from "react";
import CountryList from "./CountryList";
import {TGModal} from "../TGElements";
import SearchCountryInput from "./SearchCountryInput";
import {IonContent} from "@ionic/react";
import countryNames from "react-phone-number-input/locale/en.json";
import {defaultCountries} from "react-international-phone";

const SelectCountryModal = ({isOpen, setOpen}) => {
    const [countries, setCountries] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {

        setCountries(defaultCountries.map((country) => {
            const [countryName, countryObject, countryCode, countryPhoneCode] = country;

            return {
                countryName,
                countryPhoneCode,
                countryCode
            };
        }));
    }, []);

    useEffect(() => {
        const filtered = countries.filter((country) => {
            const isNum = /^\d+$/.test(filterText);

            if (isNum) {
                return country.countryPhoneCode.startsWith(filterText);
            }

            if (filterText.startsWith("+")) {
                return country.countryPhoneCode.startsWith(filterText.replace("+", ""));
            }

            return country.countryName.toLowerCase().includes(filterText.toLowerCase());
        })

        setFilteredCountries(filtered);
    }, [countries, filterText])

    const closeHandler = () => {
        setOpen(false);
        setFilteredCountries(countries)
        setFilterText("");
    }

    return (
        <TGModal
            isOpen={isOpen}
            setIsOpen={setOpen}
            scrollY={false}
            toolbar={false}
            modalHandler={true}
        >
            <IonContent>
                <SearchCountryInput
                    isOpen={isOpen}
                    onChange={(e) => setFilterText(e.target.value)}
                    setOpen={closeHandler}
                />
                <CountryList isOpen={isOpen} countries={filteredCountries} setOpen={closeHandler}/>
            </IonContent>
        </TGModal>
    );
};

export default SelectCountryModal;
