//Helpers
import { getStationDetail } from '../../../../helpers'
//Redux
import { IonContent } from '@ionic/react'
//Components
import {SearchResultsItem} from '../SearchResults/index'

const RecentSearchesList = ({recentSearchesCopy}) => {

    const handleClick = (resultItem) => {
        let station = resultItem
        getStationDetail(station, true, "search")
      }
    const setHeight = () => {
    let height = recentSearchesCopy.length*70
    if(height > 300){
        return 300
    }else{
        return height
    }
    }
    return (
        <div className='SearchResultsList' style={{
            height: setHeight()
        }}>
            <IonContent
            scrollY={setHeight() < 300 ? false : true}
            >
            {
                recentSearchesCopy.map(result => (
                    <SearchResultsItem key={result.id} result={result} handleClick={() => handleClick(result)}/>
                ))
            }
            </IonContent>
        </div>
  )
}

export default RecentSearchesList