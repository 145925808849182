import { general } from './MenuItems'
import {useDispatch, useSelector} from 'react-redux'
import { setMenuModal } from '../../../redux/slices/modalSlice'
import {TGIcon, TGItem, TGList, TGText} from '../../TGElements'
import {t} from "i18next";
import React from "react";
const MenuList = () => {
  const dispatch = useDispatch()
  const {language} = useSelector(state => state.user)
  const handleClick = (arg) => {
    dispatch(setMenuModal({
      status: false,
      goRouteWhileDismissing: `/${arg}`
    }))
  }
  const LeftIcon = ({...props}) => {
    return (
      <div className='TGItemIconWrapper'>
        <TGIcon {...props}/>
      </div>
    )
  }
  return (
    <div className="MenuList">
      <TGList>
        {process.env.REACT_APP_IS_WALLET_ACTIVE === "true" ?
        <div className="TGItem TGItem-dark" onClick= { () => { handleClick('wallet') }}>
          <div>
            <TGIcon
                name={`wallet-higlight-${language.code}`}
                width={119}
                height={39.53}
            />
            <div>
              <div className='MenuHighlightsNewWrapper'>{t("new").toLowerCase()}</div>
            </div>
          </div>
          <TGIcon name={"right-arrow"} fill={"#F3F4F4"} cssClass={"ItemArrow"} />
        </div>
        : null}
        {process.env.REACT_APP_IS_MEMBERSHIP_ACTIVE === "true" ?
            <div className="TGItem TGItem-blue" onClick= { () => { handleClick('membership') }}>
                <div style={{display:"flex"}}>
                  <TGIcon
                      style={""}
                      name={`menu-park`}
                      width={39}
                      height={38}
                  />
                  <p style={{color:"white", fontWeight:500}}>{t("park-membership")}</p>
                </div>
              <TGIcon name={"right-arrow"} fill={"white"} cssClass={"ItemArrow"} />
            </div>
            : null}
        {general.map((item) => (
          <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => handleClick(item.slug)}
            leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width} height={item.leftIcon.height}/>}
          />
        ))}
      </TGList>
    </div>
  );
}

export default MenuList
