import { useEffect, useState } from 'react';
import OtpInput from 'react18-input-otp';

const TGSplitInput = ({
    countInputs,
    isSeperator=false,
    inputClass = "",
    containerClass = "",
    isSuccess,
    isNumber=true,
    onSubmit
}) => {
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    if(inputValue.length >= countInputs) {
      onSubmit(inputValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const Seperator = () => <span>-</span> 
  return (
    <OtpInput
      value={inputValue}
      onChange={(otp) => setInputValue(otp)}
      numInputs={countInputs}
      separator={isSeperator ? <Seperator /> : false}
      separateAfter={countInputs/2}
      inputStyle={`TGOTPInput ${inputClass}`}
      containerStyle={`TGOTPInputContainer ${containerClass}`}
      focusStyle="TGOTPInputFocus"
      successStyle="TGOTPInputSuccess"
      errorStyle="TGOTPInputError"
      isSuccessed={inputValue.length >= countInputs && isSuccess}
      hasErrored={inputValue.length >= countInputs && !isSuccess}
      shouldAutoFocus={true}
      isInputNum={isNumber}
    />
  );
};

export default TGSplitInput;
