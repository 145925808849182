//Ionic & React
import React, { memo, useEffect } from "react"
import { IonRouterOutlet } from "@ionic/react";
//Router
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
//Components & Pages
import { NotFound, VehiclePreview } from "./components"
import {
  Map,
  RegisterPhone,
  MyProfile,
  ConfirmPhone,
  Onboarding,
  EditUser,
  TermsConditions,
  About,
  PrivacyPolicy,
  Statistics,
  License,
  Settings,
  Payments,
  Vehicles,
  ChangeLanguage,
  ManagePermissions,
  AddVehicles,
  EnterPlate,
  RegisteredCards,
  Invoices,
  ActiveSession,
  Notifications,
  AddPaymentMethod,
  InvoiceDetail,
  Receipt,
  StartSession,
  Corporate,
  Pricing,
  FAQPage,
  Agreements,
  ContactUs,
  Support,
  Tickets,
  TicketDetail,
  Wallet
} from "./pages";
//Helpers
import { checkLocalStorage } from "./helpers";
import AppUrlListener from "../src/AppUrlListener";
//Redux
import { useSelector } from "react-redux";
import Campaign from "./pages/Campaign";
import AddBalance from "./pages/AddBalance";
import BalanceAdded from "./pages/BalanceAdded";
import Membership from "./pages/Membership";
import AboutGrouped from "./pages/AboutGrouped";

const GuardedRoute = ({path, render}) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
  return (
    <Route exact path={path} render={() => user.token ? render() : <Redirect to={"/register-phone"}/>}/>
  )
}

  const Routes = () => {
    let user_info = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
    const {user} = useSelector(state => state.user)
    const isFirst = user;

    useEffect(() => {
      checkLocalStorage('user');
    }, [])

    const initRouteRenderMethod = () => {
      if (user_info.id) {
        return <Redirect to={"/map"} />;
      }
      // Onboarding route disabled for now
      // remove false to enable
      // if (isFirst) {
      //   return <Redirect to={"/onboarding"} />;
      // }
      return <Redirect to={"/register-phone"} />;
    };

    return (
      <IonReactRouter>
        <AppUrlListener/>
        <IonRouterOutlet>
          <Switch>
            <Route exact path="/" render={initRouteRenderMethod}/>
            <Route exact path="/onboarding" render={() => isFirst ? <Onboarding /> : <Redirect to={"/register-phone"}/>}/>
            <Route exact path="/register-phone" render={() => <RegisterPhone />}/>
            <Route exact path="/register-phone/confirm" render={() => <ConfirmPhone />}/>
            <GuardedRoute exact path="/edit-user" render={() => <EditUser/>}/>
            <Route exact path="/map" render={() => <Map />} />
            <GuardedRoute exact path="/my-profile" render={() => <MyProfile />} />
            <GuardedRoute exact path="/notifications" render={() => <Notifications />} />
            <GuardedRoute exact path="/vehicles" render={() => <Vehicles />} />
            <GuardedRoute exact path="/start-session" render={() => <StartSession />} />
            <GuardedRoute exact path="/active-session" render={() => <ActiveSession />} />
            <GuardedRoute exact path="/receipt" render={() => <Receipt />} />
            <GuardedRoute exact path="/vehicles/add-vehicle" render={() => <AddVehicles />} />
            <GuardedRoute exact path="/vehicles/add-vehicle/enter-plate" render={() => <EnterPlate />} />
            <GuardedRoute exact path="/vehicles/add-vehicle/vehicle-preview" render={() => <VehiclePreview />} />
            <GuardedRoute exact path="/payments" render={() => <Payments />} />
            <GuardedRoute exact path="/payments/invoices" render={() => <Invoices />} />
            <GuardedRoute exact path="/payments/invoices/detail/:id" render={() => <InvoiceDetail />} />
            <GuardedRoute exact path="/payments/registered-cards" render={() => <RegisteredCards />} />
            <GuardedRoute exact path="/payments/add-payment-method" render={() => <AddPaymentMethod />} />
            <GuardedRoute exact path="/payments/corporate-account" render={() => <Corporate/>}/>
            <GuardedRoute exact path="/agreements/license" render={() => <License />} />
            <GuardedRoute exact path="/agreements/terms-and-conditions" render={() => <TermsConditions />} />
            <GuardedRoute exact path="/statistics" render={() => <Statistics />} />
            <GuardedRoute exact path="/agreements/privacy-and-policy" render={() => <PrivacyPolicy />} />
            <GuardedRoute exact path="/about-grouped" render={() => <AboutGrouped />} />
            <GuardedRoute exact path="/about" render={() => <About />} />
            <Route exact path="/campaign" render={() => <Campaign />} />
            <GuardedRoute exact path="/settings" render={() => <Settings />} />
            <GuardedRoute exact path="/agreements" render={() => <Agreements />} />
            <GuardedRoute exact path="/settings/change-language" render={() => <ChangeLanguage />} />
            <GuardedRoute exact path="/settings/manage-permissions" render={() => <ManagePermissions />} />
            <GuardedRoute exact path="/pricing" render={() => <Pricing />} />
            <GuardedRoute exact path="/support" render={() => <Support />} />
            <GuardedRoute exact path="/support/faq" render={() => <FAQPage />} />
            <GuardedRoute exact path="/support/tickets" render={() => <Tickets />} />
            <GuardedRoute exact path="/support/tickets/:id" render={() => <TicketDetail />} />
            <GuardedRoute exact path="/support/send-ticket" render={() => <ContactUs />} />
            <GuardedRoute exact path="/wallet" render={() => <Wallet />} />
            <GuardedRoute path="/membership/:id?" render={() => <Membership />} />
            <GuardedRoute exact path="/add-balance" render={() => <AddBalance />} />
            <GuardedRoute exact path="/balance-added" render={() => <BalanceAdded />} />
            <Route path={"*"} render={() => <NotFound />} />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    );
  };

  export default memo(Routes);
