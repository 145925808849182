import {TGIcon, TGInput} from "../TGElements";
import {useTranslation} from "react-i18next";

const SearchCountryInput = ({onChange, setOpen}) => {
    const {t} = useTranslation();

    return (
        <div className="SearchCountryInputWrapper">
            <div style={{height:34}} onClick={setOpen}>
                <TGIcon name="search-modal-back" width={34} height={34}/>
            </div>

            <TGInput
                type={"search-modal"}
                onInputChange={onChange}
                placeholder={t("search-country-or-code") + '...'}
                additionalStyles={{width: "100%", marginLeft: 10}}
            />
        </div>
    );
}

export default SearchCountryInput;
