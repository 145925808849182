import React from 'react'
import {TGIcon, TGText} from './'
import {t} from 'i18next'
const TGItem = ({handleClick, leftIcon, rightIcon, slug}) => {
  return (
    <div className="TGItem" onClick={handleClick}>
      <div>
        {leftIcon ?? <TGIcon name={slug} size={24}/>}
        <TGText>{t(slug)}</TGText>
      </div>
      {rightIcon ?? <TGIcon name={"right-arrow"} fill={"#939598"} cssClass={"ItemArrow"} />}
    </div>
  );
}

export default TGItem