import {setAlert} from "../redux/slices/globalSlice";
import {store} from "../redux/store";

export class Alert {

  static #tempDelay = 3000;

  /**
   * @param message {string} - Message to show in alert
   * @param type {string | "success", "error", "warning"} - Type of alert (success, error, warning, info)
   * @param delay {number} - Delay in milliseconds to close alert
   */
  static #showAlert(message, type, delay) {
    store.dispatch(setAlert({status: true, message, type}));
    setTimeout(() => {
      this.#closeAlert();
    }, delay);
  }

  /**
   * Close alert
   */
  static #closeAlert() {
    store.dispatch(setAlert({status: false}));
  }

  /**
   * @param message {string} - Message to show in alert
   * @param delay {number} - Delay in milliseconds to close alert
   */
  static success(message,delay = this.#tempDelay) {
    this.#showAlert(message, "success", delay);
  }

  /**
   * @param message {string} - Message to show in alert
   * @param delay {number} - Delay in milliseconds to close alert
   */
  static error(message, delay = this.#tempDelay) {
    this.#showAlert(message, "error", delay);
  }

  /**
   * @param message {string} - Message to show in alert
   * @param delay {number} - Delay in milliseconds to close alert
   */
  static warning(message, delay = this.#tempDelay) {
    this.#showAlert(message, "warning", delay);
  }

  /**
   * @param message {string} - Message to show in alert
   * @param delay {number} - Delay in milliseconds to close alert
   */
  static info(message, delay = this.#tempDelay) {
    this.#showAlert(message, "info", delay);
  }

  /**
   * Close alert
   */
  static close() {
    this.#closeAlert();
  }
}
