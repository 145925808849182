import { useSelector } from 'react-redux';
import { TGIcon, TGText } from '../TGElements';
import { useTranslation } from 'react-i18next';
import {formatDistance, getStationDetail} from '../../helpers';
const BottomToolbarItem = ({name, label}) => {
    const {t} = useTranslation()
    const {stations} = useSelector(state => state.map)

    return (
      <div className="BottomToolbarItem" onClick={() =>
        stations.length ? getStationDetail(stations[0], true) : null}>
        <div className="BottomToolbarItemRight">
          <TGIcon name={name} size={50} fill={"var(--tg-primary)"}/>
          <div className="BottomToolbarItemInformation">
            <div className="BottomToolbarItemInformation_FirstLine">
              <TGText
                fontSize={14}
                fontWeight={"medium"}
                color={"var(--tg-secondary-text)"}
                margin={"0 0 5px 0"}
              >
                {t(label)}
              </TGText>
                <div className="BottomToolbarItemInformation_ClosestStationDistanceWrapper" style={{
                  backgroundColor: !stations.length ? "var(--tg-transparent)" : "var(--tg-primary-text)"
                }}>
                  <TGText
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"var(--tg-white)"}
                    textTransform={"none"}
                  >
                    {formatDistance(stations[0]?.distance)}
                  </TGText>
                </div>
            </div>
              <TGText
                fontSize={15}
                fontWeight={"semibold"}
                color={"var(--tg-primary-text)"}
                width={180}
                wrapWithDots={true}
              >
                {stations[0]?.name}
              </TGText>
          </div>
        </div>
        {stations.length > 0 ? <TGIcon
          name="right-arrow-menu"
          size={30}
        /> : null}
      </div>
    );
}

export default BottomToolbarItem;
