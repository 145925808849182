//Ionic & React
import { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setMenuModal } from "../redux/slices/modalSlice";
//Components
import { SetUser } from "../components/Register";
import { TGPage } from "../components";
//Helpers
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const EditUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);
  const {previousPath} = useSelector(state => state.global.route)

  const handleBack = () => {
    if(previousPath.includes("register")) history.goBack()
    history.push("/map", {
      menuModal: true,
    });
  };

  useEffect(() => {
    dispatch(setMenuModal({
      status: false,
      goRouteWhileDismissing: ''
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isDone) {
      if(!previousPath.includes("register")){
        handleBack();
      }else{
        history.push("/vehicles/add-vehicle")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);
  return (
    <TGPage
      title={t("edit-profile")}
      scrollY={false}
      backButton={!previousPath.includes("register") ? true : false}
      onBack={() => handleBack()}
    >
      <SetUser setIsDone={setIsDone} />
    </TGPage>
  );
};

export default EditUser;
