//Components
import { TGModal, TGText } from "../TGElements";
import LottiePlayer from "../LottiePlayer/LottiePlayer";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setActionSuccessModal } from "../../redux/slices/modalSlice";

const ActionSuccessModal = () => {
  const dispatch = useDispatch();

  const {
    action,
    status: modalStatus,
    successText,
  } = useSelector((state) => state.modal.actionSuccessModal);

  const onEnd = () => {
    dispatch(setActionSuccessModal({status: false, action: "", successText: ""}));
  };

  return (
    <TGModal
      isOpen={modalStatus}
      toolbar={false}
      setIsOpen={() =>
        !modalStatus &&
        dispatch(
          setActionSuccessModal({ status: false, action: "", successText: "" })
        )
      }
      onDidDismiss={onEnd}
      breakpoints={[0, 0.5]}
      initialBreakpoint={0.5}
      ionPadding={true}
      cssClass={"ActionSuccessModal"}
      scrollY={false}
    >
      <div className="Content">
        <TGText textAlign={"center"} fontSize={22} fontWeight={600}>
          {successText}
        </TGText>
        <LottiePlayer
          animationName={`${action}-animation`}
          onComplete={() => onEnd()}
        />
      </div>
    </TGModal>
  );
};

export default ActionSuccessModal;
