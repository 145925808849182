import {BalanceContainer} from "../../assets/wallet";
import {TGIcon} from "../TGElements";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {api} from "../../helpers";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const Balance = () => {
    const [balance, setBalance] = useState(0);
    const {language} = useSelector(state => state.user)
    const {push} = useHistory()

    const getBalance = () => {
        api.getWalletBalance().then(({data}) => {
            if (data.success) {
                setBalance(data.data);
            }
        })
    }

    useEffect(() => {
        getBalance()
    }, [])


    return (
        <div className={"Balance"} style={{backgroundImage: `url(${BalanceContainer})`}}>
            <div className={"BalanceWalletIcon"}>
                <TGIcon
                    name={`wallet-higlight-${language.code}`}
                    width={140}
                    height={46}
                    margin={"10px 0 0 0"}
                />
            </div>
            <div className={"BalanceAmount"}>
                <p>{t("balance")}</p>

                {balance ?
                    <p>{balance} kW</p>
                    : <p onClick={() => push("add-balance")} style={{fontSize:"16px"}}>{t("add-balance")}</p>}
            </div>
        </div>
    );
};

export default Balance;
