import React from "react";
import { TGSkeleton, TGText } from "../../TGElements";

const TextLoading = ({ children, ...textProps }) => {

  return children === undefined ? (
    <TGSkeleton width={112} height={17} margin={0} />
  ) : (
    <TGText styles={{lineHeight: '17px'}} {...textProps}>
      {children}
    </TGText>
  );
};

export default TextLoading;
