import { createSlice } from "@reduxjs/toolkit"

export const newCarSlice = createSlice({
  name: "newCarSlice",
  initialState: {
    newPlate: "",
    brand: {},
    model: {}
  },
  reducers: {
    setNewPlate: (state, action) => {
        state.newPlate = action.payload
    },
    setBrand: (state, action) => {
        state.brand = action.payload
    },
    setModel: (state, action) => {
        state.model = action.payload
    }
  },
})

export const {
    setNewPlate,
    setBrand,
    setModel
} = newCarSlice.actions
export default newCarSlice.reducer
