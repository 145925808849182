import {TGButton, TGInput, TGPageHeader} from "../TGElements";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const CreateCompanyContent = ({setModal, createCompany, isError}) => {
    const {t} = useTranslation()
    const [taxNumber, setTaxNumber] = useState("");
    const [companyName, setCompanyName] = useState("");

    const addCompany = () => {
        createCompany(taxNumber, companyName)
        setCompanyName("")
        setTaxNumber("")
    }

    return (
        <div>
            <TGPageHeader backButton={true} onBack={() => setModal(false)}/>

            <div style={{margin: "16px"}}>
                <TGInput
                    type="number"
                    label={t("tax-number")}
                    value={taxNumber}
                    error={isError}
                    onInputChange={(e) => setTaxNumber(e.detail.value)}
                />

                <TGButton styles={{width: "100%"}} margin={"16px 0 0 0"} onButtonClick={addCompany}>
                    {t("add-company")}
                </TGButton>
            </div>
        </div>
    );
}

export default CreateCompanyContent;
