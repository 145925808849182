import Header from "./Header";
import StationInformation from "./StationInformation";
import SessionInformation from "./SessionInformation";
const Content = () => {
  return (
    <div className="InformationModalContent">
      <Header />
      <StationInformation />
      <SessionInformation />
    </div>
  );
};

export default Content;
