import { createSlice } from "@reduxjs/toolkit"

export const stationSlice = createSlice({
  name: "stationSlice",
  initialState: {
    currentStation: {}
  },
  reducers: {
    setCurrentStation: (state, action) => {
        state.currentStation = action.payload
    },
  },  
})

export const {
    setCurrentStation
} = stationSlice.actions
export default stationSlice.reducer
