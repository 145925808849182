import { useDispatch, useSelector } from 'react-redux'
import { TGModal} from '../../TGElements'
import { setSessionInfoModal } from '../../../redux/slices/modalSlice'
import Content from  './Content'

const SessionInformationModal = () => {
    const {status} = useSelector(state => state.modal.sessionInfoModal)
    const dispatch = useDispatch()
  return (
    <TGModal
    toolbar={false}    
    isOpen={status}
    setIsOpen={() => status && dispatch(setSessionInfoModal(false))}
    breakpoints={[0, 0.6]}
    initialBreakpoint={0.6}
    scrollY={false}
    >
        <Content />
    </TGModal>
  )
}

export default SessionInformationModal
