//React
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStation } from "../../redux/slices/stationSlice";
import { setStationDetailModalOpen, setStationDetailModalBreakpoint, setSearchModal } from "../../redux/slices/modalSlice";
//Components
import { TGModal } from "../TGElements";
import Content from "./Content";

const StationDetailModal = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const stationDetailModalRef = useRef(null);

  const {currentStation} = useSelector((state) => state.station);
  const {status, currentBreakpoint, goRouteWhileDismissing} = useSelector((state) => state.modal.stationDetailModal);
  const {activeSession} = useSelector((state) => state.session)
  const [expandSocketTypesList, setExpandSocketTypesList] = useState(false);

  const handleBackButton = (status) => {
    dispatch(setStationDetailModalOpen(status));
    if(currentStation.FROM === "search"){
      dispatch(setSearchModal(true));
    }
    if(!activeSession.type) {
      dispatch(setCurrentStation({}));
    }
  };

  useEffect(() => {
    if (status) {
      dispatch(setStationDetailModalBreakpoint(0.6));
    }else {
      currentBreakpoint !== 0 && dispatch(setStationDetailModalBreakpoint(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if(currentBreakpoint !== 1) {
      setExpandSocketTypesList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoint]);

  useEffect(() => {
    if (expandSocketTypesList) {
      stationDetailModalRef?.current?.setCurrentBreakpoint(1);
    }
  }, [expandSocketTypesList]);

  const scrollY = Boolean(currentBreakpoint === 1 && expandSocketTypesList)

  return (
    <TGModal
      scrollY={scrollY}
      toolbar={false}
      modalHandler={true}
      initialBreakpoint={0.6}
      cssClass="StationDetail"
      breakpoints={[0, 0.6, 1]}
      title={currentStation.name}
      isOpen={status}
      reference={stationDetailModalRef}
      setIsOpen={(status) => handleBackButton(status)}
      onBreakpointDidChange={(e) =>
        dispatch(setStationDetailModalBreakpoint(e.detail.breakpoint))
      }
      iosSafeArea={false}
      onWillDismiss={() => {
        if(goRouteWhileDismissing === "") return;
        history.push(goRouteWhileDismissing, {
          from: "station-detail"
        })
      }}
    >
      <Content
      expandSocketTypesList={expandSocketTypesList}
      setExpandSocketTypesList={setExpandSocketTypesList}
      />
    </TGModal>
  );
};

export default StationDetailModal;
