import CountryItem from "./CountryItem";
import {memo} from "react";

const CountryList = ({ countries, setOpen }) => {

  return (
      <ul className="CountryListWrapper">
        {countries.map((country) => (
          <CountryItem
            key={country.countryCode}
            item={country}
            setOpen={setOpen}
          />
        ))}
      </ul>
  );
};

export default memo(CountryList);
