//React
import {useEffect, useState} from "react";
//Components
import {
    TGPage,
    HiddenCar,
    TGInput,
    TGButton,
    AddNewVehicleModal,
    TGImg,
} from "../components";
//Redux
import {useDispatch, useSelector} from "react-redux";
import {setNewPlate} from "../redux/slices/newCarSlice";
import {setAddNewVehicleModal} from "../redux/slices/modalSlice";
//Helpers
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const EnterPlate = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false);
    const [vehicleToEdit, setVehicleToEdit] = useState({});
    const {newPlate} = useSelector((state) => state.newCar);
    const {vehicleToEdit: vehicleToEditState, from} = history.location.state ?? {}
    const {registerPagesNeverAppear} = useSelector((state) => state.user);

    const decideBackRoute = () => {
        if (history?.location?.state?.from) {
            return history.location.state.from
        } else if (registerPagesNeverAppear) {
            return '/vehicles'
        } else {
            return '/vehicles/add-vehicle'
        }
    }

    const goBack = () => {
        history.push(decideBackRoute());
    }
    useEffect(() => {
        if (vehicleToEditState?.plate) {
            dispatch(setNewPlate(vehicleToEditState?.plate));
            setVehicleToEdit(vehicleToEditState);
            setEdit(true);
        } else {
            dispatch(setNewPlate(""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <TGPage title={t("enter-plate")} scrollY={false} backButton={true} onBack={() => goBack()}>
            <div className="EnterPlateContent">
                {vehicleToEdit.id ? (
                    <TGImg
                        src={vehicleToEdit.image}
                        width={200}
                        additionalStyles={{
                            transform: "translateY(50%)",
                            margin: "0 auto",
                        }}
                    />
                ) : (
                    <HiddenCar/>
                )}
                <TGInput
                    label={t("enter-plate")}
                    additionalStyles={{
                        transform: "translateY(165px)",
                    }}
                    onInputChange={(e) => dispatch(setNewPlate(e.detail.value))}
                    placeholder={newPlate}
                />
                <TGButton
                    disabled={!(newPlate.length > 4)}
                    additionalStyle={{
                        transform: "translateY(200px)",
                        width: "100%",
                    }}
                    onButtonClick={() => dispatch(setAddNewVehicleModal(true))}
                >
                    {t("next")}
                </TGButton>
            </div>
            <AddNewVehicleModal
                isEdit={isEdit}
                from={from ? from : ""}
            />
        </TGPage>
    );
};

export default EnterPlate;
