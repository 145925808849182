//Components
import { TGIcon } from "../../../TGElements";
import ActionButton from "./ActionButton";
import SessionHeader from "./SessionHeader";
import SessionHero from "./SessionHero";

const SessionContent = ({from, setIntervalStart}) => {
  
  return (
    <div className="SessionContent">
      <TGIcon
        name="session-background"
        size={528}
        cssClass={"SessionBackground"}
      />
      <SessionHeader from={from}/>
      <SessionHero />
      <ActionButton setIntervalStart={setIntervalStart}/>
    </div>
  );
};

export default SessionContent;
