import { TGButton, TGText } from './TGElements';
import { useTranslation } from 'react-i18next';
import { logout } from '../helpers';

const Logout = () => {
  const { t } = useTranslation()

  const isDeleteAccountActive = process.env.REACT_APP_DELETE_ACCOUNT_ACTIVE === 'true'

  return (
    <div className="LogoutWrapper">
      <TGButton
        width={"fit-content"}
        height={20}
        backgroundColor={"var(--tg-transparent)"}
        backgroundActiveColor={"var(--tg-transparent)"}
        onButtonClick={() => logout()}
      >
        <TGText color={"var(--tg-link)"} fontSize={14} fontWeight={"regular"}>
          {t("logout")}
        </TGText>
      </TGButton>

      {isDeleteAccountActive && (
        <TGButton
          width={"fit-content"}
          height={20}
          backgroundColor={"var(--tg-transparent)"}
          backgroundActiveColor={"var(--tg-transparent)"}
          onButtonClick={() => logout()}
        >
          <TGText color={"var(--tg-error)"} fontSize={14} fontWeight={"regular"}>
            {t("remove-user")}
          </TGText>
        </TGButton>
      )}
    </div>
  );
}

export default Logout