//Components
import ChargingVehicle from "./ChargingVehicle";
import ParkingVehicle from './ParkingVehicle';
//Redux
import { useSelector } from 'react-redux';
const SessionVehicle = () => {
    const {activeSession} = useSelector(state => state.session)
    const isParkCharge = true // TODO: if active session is park and charge
  return activeSession.type === "park" ? (
    <ParkingVehicle isParkCharge={isParkCharge}/>
  ) : (
    <ChargingVehicle />
  )
}

export default SessionVehicle