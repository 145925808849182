//Redux
import { useSelector } from "react-redux"
import { Swiper, SwiperSlide } from "swiper/react"
import NearbyStationSliderItem from "./NearbyStationSliderItem"
import {formatDistance, getStationDetail} from "../../../../helpers"
import NoNearBy from "./NoNearBy"
import {t} from 'i18next'

const NearbyStationSlider = () => {
    const {stations, mapFilter} = useSelector(state => state.map)

  return stations.length > 0 ? (
    <Swiper
    slidesPerView={1.2}
    className="NearbyStationsSwiper"
    >
        {stations.length > 0 && stations.map(station => (
            <SwiperSlide key={station.id} onClick={() => {
              getStationDetail(station, true, "search");  // first argument is station id for getStationDetail,
                                                          // second one is boolean for open station detail modal
                                                          // third for triggered from
            }}>
                <NearbyStationSliderItem
                label={t("charging-station")}
                name={station.name}
                iconName={mapFilter}
                iconFill={`var(--tg-${mapFilter})`}
                distance={formatDistance(station.distance)}
                />
            </SwiperSlide>
        ))}
    </Swiper>
  ) : (
    <NoNearBy />
  )
}

export default NearbyStationSlider