import {TGIcon} from "../TGElements";

const Transactions = ({transactions}) => {
    return (
        <div className={"Transactions"}>
            {transactions ? transactions.map((transaction, index) => (
                <div key={index} className={"TransactionItemContainer"}>
                    <p style={{fontSize: "12px", fontWeight: 500}}>{transaction.day}</p>
                    {transaction?.data.map((data, key) => (
                        <div key={key} className={`TransactionItem`}>
                            <div className={"TransactionItemContent"}>
                                <div
                                    className={`TransactionIcon ${data.symbol === "-" ? "NegativeTransaction" : "PositiveTransaction"}`}>
                                    <TGIcon
                                        name={data.symbol === "-" ? "charge-transaction" : "wallet"}
                                        className={"ChargeTransactionIcon"}
                                        margin={"10px"}/>
                                </div>
                                <div className={"TransactionMiddleSection"}>
                                    <p>{data.transactionType}</p>
                                    <p>{data.transactionDetail}</p>
                                </div>
                                <div
                                    className={`TransactionEndSection ${data.symbol === "-" ? "NegativeTransaction" : "PositiveTransaction"}`}>
                                    <p>{data.symbol + data.price}kW</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )) : null}
        </div>
    )
};

export default Transactions;
