import { IonButton } from '@ionic/react';
const TGButton = ({
    children,
    type,
    disabled,
    margin,
    width,
    height,
    expand,
    cssClass,
    borderColor,
    borderRadius,
    borderStyle,
    borderWidth,
    backgroundColor,
    onButtonClick,
    backgroundActiveColor,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingTop,
    boxShadow,
    additionalStyle
}) => {

    const ButtonStyles = {
        "--background-activated": backgroundActiveColor || "var(--tg-button-effect)",
        "--background-focused": backgroundActiveColor || "var(--tg-button-effect)",
        margin: margin || 0,
        "--background" : backgroundColor ? backgroundColor : disabled ? "var(--tg-inactive)" : "var(--tg-primary)",
        width: width === "fit-content" ? width : width+"px" || "100%",
        height: height === "fit-content" ? height : height+"px" || "55px",
        "--bordercolor" : borderColor || "",
        "--border-radius": borderRadius || "",
        "--border-style": borderStyle || "",
        "--border-width": borderWidth || "",
        border: `${borderWidth} ${borderStyle} ${borderColor}` || "",
        borderRadius: borderRadius || "",
        "--padding-start": paddingLeft || 0,
        "--padding-end": paddingRight || 0,
        "--padding-top": paddingTop || 0,
        "--padding-bottom": paddingBottom || 0,
        paddingLeft: paddingLeft || 0,
        paddingRight: paddingRight || 0,
        paddingTop: paddingTop || 0,
        paddingBottom: paddingBottom || 0,
        "--box-shadow": boxShadow || "none", 
        ...additionalStyle
    }
    return (
      <IonButton
        onClick={onButtonClick}
        style={ButtonStyles}
        disabled={disabled ? disabled : false}
        className={`${cssClass ? cssClass : "TGButton"}`}
        type={type}
        expand={expand !== "none" && "full"}
        shape="round"
      >
        {children}
      </IonButton>
    );
}

export default TGButton;
