import { useDispatch } from "react-redux";
import { setBrand } from "../../../redux/slices/newCarSlice";
import { BrandItem } from "./BrandItem";

const SetBrand = ({activeTab, setActiveTab, brands}) => {
    const dispatch = useDispatch()
    return (
        <div className={`SetVehicle Brands ${activeTab === "brand" ? "active" : activeTab === "model" ? "done" : ""}`}>
            {brands.map(b =><BrandItem key={b.id} img={b.thumb} name={b.name} onBrandClick={() => {
                dispatch(setBrand(b))
                setActiveTab("model")
                }}/>)}
        </div>
    );
}

export default SetBrand;
