//React
import { useEffect, useState } from "react";
//Components
import { TGButton, TGIcon, TGText } from "../../TGElements";
//Helpers
import { useTranslation } from "react-i18next";
import {isUserAbleToStartSession, isUserLoggedIn, startScan} from "../../../helpers";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setStationDetailModalDismissingRoute, setStationDetailModalOpen } from "../../../redux/slices/modalSlice";
import {useIonRouter} from "@ionic/react";

const StartSessionButton = ({ station }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const history = useIonRouter();
  const {is_qr: isQrFromConfig} = useSelector(state => state.config)

  const handleButton = async () => {

    if (!isUserLoggedIn()) {
      history.push("/register-phone")
      dispatch(setStationDetailModalOpen(false));
      return;
    }

    const isAble = await isUserAbleToStartSession()
    if(!isAble){
      dispatch(setStationDetailModalOpen(false));
      setDisabled(true);
      return { start: false };
    }

    
    if(!isQrFromConfig){
      dismissStationDetail("/start-session");
      return;
    }

    const response = await startScan();
    if (response.start) {
      dismissStationDetail("/active-session");
    } else if (!response.scanner) {
      dismissStationDetail("/start-session");
    }
    
  };
  const BUTTON_STYLES = {
    width: "100%",
    height: 50,
  };

  const dismissStationDetail = (path) => {
    dispatch(setStationDetailModalDismissingRoute(path));
    dispatch(setStationDetailModalOpen(false));
  };
  return (
    <TGButton
      onButtonClick={() => handleButton()}
      additionalStyle={BUTTON_STYLES}
      cssClass={"StationDetailActionButton"}
      disabled={isDisabled}
    >
      <TGIcon name="charging-lightning-second" />
      <TGText fontWeight={500} color={"var(--tg-white)"}>
        {isUserLoggedIn() ? t("start-charge") : t("login")}
      </TGText>
    </TGButton>
  );
};

export default StartSessionButton;
