import { useTranslation } from 'react-i18next'
import RecentSearchNotFound from '../../../../assets/no_recent_search.png'
import { TGImg, TGText } from '../../../TGElements'
const NoRecentSearch = () => {
    const {t} = useTranslation()
  return (
    <div className="SearchNotFound">
        <TGImg src={RecentSearchNotFound} width={228.3} height={124} margin={"30px 0 0 0"}/>
        <TGText width={268} textAlign="center" margin="38px auto" textTransform="unset">
            {t("recent-search-not-found")}
        </TGText>
    </div>
  )
}

export default NoRecentSearch