import { createSlice } from "@reduxjs/toolkit"

export const searchSlice = createSlice({
    name: "searchSlice",
    initialState: {
        searchResults: [],
        recentSearches: [],
        searchLoading: false,
        stationFilters: {
            ac: false,
            dc: false,
            onlyAvailable: false
        }
    },
    reducers: {
        setSearchResults: (state, action) => {
            state.searchResults = action.payload
        },
        setRecentSearches: (state, action) => {
            state.recentSearches.push(action.payload)
        },
        resetRecentSearches: (state, action) => {
            state.recentSearches = []
        },
        setSearchLoading: (state, action) => {
            state.searchLoading = action.payload
        },
        setStationFilters: (state, action) => {
            const {type, status} = action.payload
            if(!type){
                state.stationFilters = action.payload
                return;
            }
            state.stationFilters[type] = status
        }
    },
})

export const {
    setSearchResults,
    setRecentSearches,
    resetRecentSearches,
    setSearchLoading,
    setStationFilters
} = searchSlice.actions
export default searchSlice.reducer
