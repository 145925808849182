import { useHistory } from "react-router";
import { TGButton, TGText } from "../TGElements";
import {t} from 'i18next'
const ActionButton = () => {
  const history = useHistory();
  return (
    <TGButton
      backgroundColor={"var(--tg-primary-text)"}
      backgroundActiveColor={"var(--tg-black-active)"}
      additionalStyle={{
        position: "absolute",
        bottom: 30,
        width: "calc(100% - 32px)",
      }}
      onButtonClick={() =>
        history.push("/map")
      }
    >
      <TGText color={"var(--tg-white)"}>
      {t("done")}
      </TGText>
    </TGButton>
  );
};

export default ActionButton;
