import React from "react";
import { TGText } from "../../TGElements";
import TextLoading from "./TextLoading";
import { t } from "i18next";
const Vehicle = ({ displayName, plate, modelText }) => {
  return (
    <div className="--invoice">
      <div className="line">
        <TGText>{t("vehicle-plate")}</TGText>
        <TextLoading
          fontWeight={"medium"}
          textTransform={"uppercase"}
        >
          {plate}
        </TextLoading>
      </div>
      <div className="line">
        <TGText>{t("model-brand")}</TGText>
        <TextLoading
          fontWeight={"medium"}
          width={200}
          wrapWithDots={true}
          textAlign={"right"}
        >
          {modelText}
        </TextLoading>
      </div>
    </div>
  );
};

export default Vehicle;
