import { TGText } from '../../../TGElements'
import {t} from "i18next"
const SocketTypeAvailability = ({status}) => {

    const handleColor = () => {

        if(!status) {
            return "var(--tg-gray-2)";
        }

        switch(status.state_id){
            case 1:
                return "var(--tg-primary)";
            case 4:
            case 5:
                return "var(--tg-error)";
            default:
                return "var(--tg-orange)"
        }
    }

    return (
        <div className='SocketTypeAvailability'>
            <span style={{
                width: 10,
                height: 10,
                borderRadius: 10,
                display: "inline-block",
                backgroundColor: handleColor(),
            }}/>
            <TGText fontSize={10} fontWeight={"semibold"}>
                {status ? status.name : t("offline")}
            </TGText>
        </div>
    )
}

export default SocketTypeAvailability