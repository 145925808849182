import { isCreditCard } from "validator";
import { handleError } from "./handleError";

export const validate = {
  creditCard: ({ data, type }) => {
    const formattedCardNumber = data.split(" ").join("");
    if (!isCreditCard(formattedCardNumber)) {
      return handleError(type, "invalid-card", false);
    }
    return handleError(type);
  },
  cvc: ({ data, type }) => {
    const cvcNumber = data;
    if (cvcNumber.length < 3) {
      return handleError(type, "invalid-cvc", false);
    }
    return handleError(type);
  },
  name: ({ data, type }) => {
    const name = data.split(" ");
    if (name.length === 1 || name[0] < 3) {
      return handleError(type, "invalid-name", false);
    }
    return handleError(type);
  },
  expire: ({ data, type }) => {
    const month = +data.split("/")[0];
    const year = +`20${data.split("/")[1]}`;
    const today = new Date();

    if (year < today.getFullYear()) {
      return handleError(type, "invalid-expiry", false);
    } else if (
      year === today.getFullYear() &&
      (month < today.getMonth() + 1 || month > 12)
    ) {
      return handleError(type, "invalid-expiry", false);
    }
    return handleError(type);
  },
};
