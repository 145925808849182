import {store} from "../redux/store";
import {resetSlice, setUser} from "../redux/slices/userSlice";
import {setMenuModal} from "../redux/slices/modalSlice";
import {startCharge, stopCharge} from "./apiFunctions";
import md5 from "blueimp-md5";
import {StatusBar, Style} from "@capacitor/status-bar";
import {Capacitor} from "@capacitor/core";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import {Alert} from "./alert";
import {t} from 'i18next'
import {setFlashlight, setScanner} from "../redux/slices/deviceSlice";
import {setLoading} from "../redux/slices/globalSlice";

export const checkLocalStorage = (type) => {
    if (type === 'user') {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
        if (user.id) {
            store.dispatch(setUser(user))
        }
    }
}
export const logout = async () => {
    store.dispatch(setMenuModal(false))
    store.dispatch(resetSlice())
    localStorage.clear()
    window.location.reload()
}
export const stopSession = async () => {
    return await stopCharge();
}

//Create Hash token with timestamp__formatted by UTC timezone and HashKey_from env file
export const createHash = () => {
    const HASH_KEY = process.env.REACT_APP_HASH_KEY
    let dateStamp = Date.now() / 1000 | 0
    let str = `${HASH_KEY}-${dateStamp}`
    return md5(str);
}

export const handleStatusbar = async (isDark) => {
    if (isDark) {
        await StatusBar.setStyle({style: Style.Dark})
        return;
    }
    await StatusBar.setStyle({style: Style.Light})
}

export const startScan = async () => {
    if (!Capacitor.isNativePlatform()) {
        return {scanner: false};
    }

// Check camera permission
    const permission = await BarcodeScanner.checkPermission({force: true});
    if (!permission.granted) {
        stopScan()
        Alert.error(t("grant-camera-permission"));
        return {scanner: false};
    }

// else scan starting
    BarcodeScanner.prepare();
    document.body.classList.add("qrscanner");
    BarcodeScanner.hideBackground(); // make background of WebView transparent
    store.dispatch(setScanner(true));

    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
    if (result.hasContent) {
        // if the result has content
        let content = result.content;
        stopScan(); // stop scanning
        store.dispatch(setScanner(false));
        store.dispatch(setLoading({status: true, message: t("charge-starting")}))
        if (content.includes("http")) {
            content = content.split("/").pop();
        }
        const {start,isCableConnected} = await startCharge(content);
        store.dispatch(setLoading(false))
        return {start, isCableConnected};
    }
};

const stopScan = async () => {
    const {isEnabled} = await BarcodeScanner.getTorchState();
    if (isEnabled) {
        BarcodeScanner.disableTorch();
    }
    store.dispatch(setFlashlight(false));
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.body.classList.remove("qrscanner");
};

export const parseParamsString = (stringParams) => {
    const params = {}
    stringParams.forEach((value, key) => params[key] = value);
    return params
}

export const getPlatformByUserAgent = () => {
    if (/iphone/i.test(navigator.userAgent)) {
        return "iOS";
    } else if (/ipad/i.test(navigator.userAgent)) {
        return "iOS";
    } else if (/android/i.test(navigator.userAgent)) {
        return "android";
    }
}

export const formatDistance = (distance) => {
    if (distance > 1) {
        return Math.round(distance) + ' km';
    } else {
        return Math.round(distance * 1000) + ' m';
    }
}

//TODO::TEMP SOLUTION. UNTIL DETERMINE HOW PRICING LOOK LIKE
export const formatStationPrice = (connections) => {
    let acPrice = null;
    let dcPrice = null;

    connections.forEach(function (connection)  {
        if (acPrice && dcPrice) {
            return;
        }

        if (connection.connection_type_name === "AC" && !acPrice) {
            acPrice = connection.price_per_kwh;
        }

        if (connection.connection_type_name === "DC" && !dcPrice) {
            dcPrice = connection.price_per_kwh;
        }
    })

    if (acPrice && dcPrice) {
        return `AC: ${acPrice}₺ / DC: ${dcPrice}₺`;
    } else if (dcPrice) {
        return `${dcPrice}₺`;
    } else {
        return `${acPrice}₺`;
    }
}


export const isUserLoggedIn = () => {
    return store.getState().user.user.token.length > 0;
}