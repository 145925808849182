import {createSlice} from "@reduxjs/toolkit"

export const appRateSlice = createSlice({
    name: "appRateSlice",
    initialState: {
        lastAnswerId: 0,
        lastRateDate: "",
    },
    reducers: {
        setLastAnswer: (state, action) => {
            state.lastAnswerId = action.payload
        },
        setLastRateDate: (state, action) => {
            state.lastRateDate = action.payload
        },
        resetAppRate: (state) => {
            state.lastAnswerId = 0;
            state.lastRateDate = "";
        }
    },
})

export const {setLastAnswer, setLastRateDate, resetAppRate} = appRateSlice.actions
export default appRateSlice.reducer
