import { createSlice } from "@reduxjs/toolkit"

export const modalSlice = createSlice({
  name: "modalSlice",
  initialState: {
    stationDetailModal: {
        status: false,
        currentBreakpoint: 0,
        goRouteWhileDismissing: ""
    },
    addNewVehicleModal: {
        status: false
    },
    searchModal: {
        status: false,
    },
    menuModal: {
        status: false,
        goRouteWhileDismissing: ''
    },
    scanModal: {
        status: false
    },
    confirmMailModal: {
        status: false
    },
    sessionInfoModal: {
        status: false
    },
    cardActionsModal: {
        status: false,
        selectedCard: null 
    },
    actionSuccessModal: {
        status: false,
        action: "",
        successText: ""
    },
    checkUserModal: {
        debt: false,
        vehicle: false,
        paymentMethod: false
    }
},
  reducers: {
    setStationDetailModalOpen: (state, action) => {
        state.stationDetailModal.status = action.payload
    },
    setStationDetailModalBreakpoint: (state, action) => {
        state.stationDetailModal.currentBreakpoint = action.payload
    },
    setStationDetailModalDismissingRoute: (state, action) => {
        state.stationDetailModal.goRouteWhileDismissing = action.payload
    },
    setAddNewVehicleModal: (state, action) => {
        state.addNewVehicleModal.status = action.payload
    },
    setConfirmMailModal: (state, action) => {
        state.confirmMailModal.status = action.payload
    },
    setSearchModal: (state, action) => {
        state.searchModal.status = action.payload
    },
    setMenuModal: (state, action) => {
        if(typeof action.payload === 'boolean') {
            state.menuModal.status = action.payload
        }else{
            state.menuModal = action.payload
        }
    },
    setScanModal: (state, action) => {
        state.scanModal.status = action.payload
    },
    setSessionInfoModal: (state, action) => {
        state.sessionInfoModal.status = action.payload
    },
    setCardActionsModal: (state, action) => {
        state.cardActionsModal = action.payload
    },
    setActionSuccessModal: (state, action) => {
        state.actionSuccessModal = action.payload
    },
    setCheckUserModal: (state, action) => {
        state.checkUserModal = action.payload
    }
  },  
})

export const {
    setStationDetailModalOpen,
    setStationDetailModalBreakpoint,
    setStationDetailModalDismissingRoute,
    setAddNewVehicleModal,
    setConfirmMailModal,
    setSearchModal,
    setMenuModal,
    setScanModal,
    setSessionInfoModal,
    setCardActionsModal,
    setActionSuccessModal,
    setCheckUserModal
} = modalSlice.actions
export default modalSlice.reducer
