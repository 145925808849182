import React from 'react'
import { ActionSuccessModal, RegisteredCardsContent, TGPage } from '../components'
import { useTranslation } from 'react-i18next'

const RegisteredCards = () => {
  const {t} = useTranslation()

  return (
    <TGPage
    title={t("registered-cards")}
    scrollY={false}
    backButton={true}
  >
    <RegisteredCardsContent />
    <ActionSuccessModal />
  </TGPage>
  )
}

export default RegisteredCards