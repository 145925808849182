import { TGIcon, TGText } from '../../TGElements'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setMenuModal } from '../../../redux/slices/modalSlice'

const Payments = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  return (
    <div className='MenuHighlight Payments' onClick={() => {
      dispatch(setMenuModal({
      status: false,
      goRouteWhileDismissing: `/payments`
    }))
    }}>
      <div className='IconWrapper'>
        <TGIcon
        name={"payments-higlight"}
        width={19.5}
        height={13.7}
        />
      </div>
      <TGText
      fontSize={16}
      fontWeight={"semibold"}
      color={"var(--tg-white)"}
      margin={"8px 0 0 0"}
      >
        {t("payments")}
      </TGText>
      <div className='MenuHighlightsInfoIconWrapper'>
        <TGIcon
          name="info"
          size={17}
          cssClass={"MenuHighlightsInfoIcon"}
        />
      </div>
    </div>
  )
}

export default Payments