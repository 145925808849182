import {TGImg} from "../TGElements";
import {NoParkSearch} from "../../assets/images";
import {t} from "i18next";

const Subscriptions = ({subscriptions}) => {
    return (
        <div className={"ParkDetailTabSubscriptions"}>
            {subscriptions && subscriptions.length ?
                subscriptions.map((subscription) => (
                    <Subscriptions key={subscription.id}/>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg src={NoParkSearch}/>
                    <p>{t("no-subscriptions")}</p>
                    <p>{t("add-plate-for-subscription")}</p>
                </div>
            }
        </div>
    );
};

export default Subscriptions;
