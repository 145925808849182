import { useDispatch, useSelector } from 'react-redux';
import { TGModal, TGText, TGSearchInput, TGBackButton } from '../../TGElements';
import { setAddNewVehicleModal } from '../../../redux/slices/modalSlice';
import { useEffect, useState } from 'react';
import {SetBrand, SetModel} from './';
import { getVehicleBrands, getVehicleModels } from '../../../helpers';
import { useTranslation } from 'react-i18next';
const AddNewVehicleModal = ({isEdit = false, from = ""}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const { addNewVehicleModal } = useSelector((state) => state.modal);
    const { brand } = useSelector((state) => state.newCar);
    const [activeTab, setActiveTab] = useState("brand")
    const [searchText, setSearchText] = useState("")
    const [brands, setBrands] = useState([])
    const [models, setModels] = useState([])
    const [filteredBrands, setFilteredBrands] = useState([])
    const [filteredModels, setFilteredModels] = useState([])

    useEffect(() => {
        if(addNewVehicleModal.status){
            if(activeTab === "brand"){
                brands.length === 0 && getVehicleBrands().then(res => setBrands(res))
            }
        }else{
            setActiveTab("brand")
            setModels([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab, addNewVehicleModal.status])

    useEffect(() => {
        if(brand.id) getVehicleModels(brand.id).then(res => setModels(res))
    }, [brand])

    const handleSearch = () => {
        if(activeTab === "brand"){
            setFilteredBrands(brands.filter(b => b.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())))
        }else{
            setFilteredModels(models.filter(m => m.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())))
        }
    };

    useEffect(() => {
        if(searchText !== ""){
            handleSearch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])
    
    useEffect(() => {
        setSearchText("")
    }, [activeTab])

    return (
      <TGModal
        isOpen={addNewVehicleModal.status}
        setIsOpen={(status) => {
          dispatch(setAddNewVehicleModal(status));
        }}
        initialBreakpoint={0.9}
        breakpoints={[0, 0.9, 1]}
        canDismiss={true}
        toolbar={false}
        iosSafeArea={false}
        scrollY={false}
      >
        <div
          style={{
            paddingTop: 24,
          }}
        >
          {activeTab === "model" ? (
            <div
              style={{
                position: "absolute",
                paddingTop: 5,
              }}
            >
              <TGBackButton
                onBack={() => {
                  setActiveTab("brand");
                }}
              />
            </div>
          ) : null}
          <TGText
            color={"--tg-primary-text"}
            fontSize={20}
            fontWeight="semibold"
            textTransform={"capitalize"}
            margin={"0 auto"}
          >
            {activeTab === "brand" ? t("choose-brand") : t("choose-model")}
          </TGText>
          <TGSearchInput
            type="search"
            margin={"16px 0"}
            value={searchText}
            onSearchTextChange={(e) => setSearchText(e.detail.value)}
            label={`${
              activeTab === "brand" ? t("search-brand") : t("search-model")
            }`}
          />
          <SetBrand
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            brands={searchText === "" ? brands : filteredBrands}
          />
          <SetModel
            from={from}
            activeTab={activeTab}
            models={searchText === "" ? models : filteredModels}
            brands={brands}
            isEdit={isEdit}
          />
        </div>
      </TGModal>
    );
}

export default AddNewVehicleModal;
