import {useTranslation} from 'react-i18next'
import {TGIcon, TGPage} from "../components";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";

const BalanceAdded = () => {
    const {t} = useTranslation()
    const [amount, setAmount] = useState(0);
    const {push, location} = useHistory()

    useEffect(() => {
        if (location.state.amount) {
            setAmount(location.state.amount);
        }
    }, [location.state.amount]);

    return (
        <TGPage
            scrollY={false}
            backButton={false}
        >
            <div className={"Wallet"}>
                <TGIcon
                    name={"wallet-fancy"}
                    width={257}
                    height={164}
                    margin={"200px 0 0 0"}
                />

                <p style={{fontSize: "18px", fontWeight: "600", marginTop: "48px"}}>{t("balance-added")}</p>
                <p style={{textAlign: "center", fontWeight: "400", marginTop:"0"}}  dangerouslySetInnerHTML={{
                    __html: t("balance-added-description", {amount:amount})
                }}></p>

                <button style={{
                    marginTop:"100px",
                    backgroundColor:"var(--tg-dark)",
                    width:"100%",
                    padding:"5px",
                    borderRadius:"25px",
                    color:"var(--tg-white)",
                    height:"50px",
                    fontWeight:500
                }} onClick={() => push("map")}>{t("return-to-map")}</button>
            </div>

        </TGPage>
    )
}

export default BalanceAdded