import { useSelector } from "react-redux"
import { TGImg, TGText } from "../../TGElements"
export const BrandItem = ({name, img, onBrandClick}) => {
    const {brand} = useSelector(state => state.newCar)
    return (
        <div className={`BrandItem ${brand.name === name ? "Active":""}`} onClick={() => onBrandClick()}>
            <div className="BrandLogo">
            <TGImg src={img} width={56} height={56}/>
            </div>
            <div className="BrandName">
                <TGText
                fontSize={12}
                fontWeight="semibold"
                >
                    {name}
                </TGText>
            </div>
        </div>
    )
}