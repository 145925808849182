//Components
import {TGIcon, TGItem, TGList, TGModal, TGText} from '../TGElements'
//Helpers
import uniqid from 'uniqid'
import {useTranslation} from 'react-i18next';
//Redux
import {useDispatch, useSelector} from 'react-redux';
import {setActionSuccessModal, setCardActionsModal} from '../../redux/slices/modalSlice';
import { useDialog } from '../../hooks';
import { getPaymentMethods, removeCreditCard, setDefaultCard } from '../../helpers';
import { Alert } from '../../helpers/alert';

const CardActionModal = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {status, selectedCard} = useSelector(state => state.modal.cardActionsModal)
    const actionList = [
        {id: uniqid(), slug: "make-default-card", notVisible: selectedCard?.default},
        {id: uniqid(), slug: "remove-card"},
    ];
    const showDialog = useDialog()
  const handleAction = item => {
    if(item.slug === "remove-card"){
      handleRemoveCard()
    }else{
      handleSetDefaultCard()
    }
  }

  const handleSetDefaultCard = async () => {
    if(!selectedCard.default){
      const res = await setDefaultCard(selectedCard.id)
      if(res.success){
        Alert.success(t("default-card-success", {
          last4: selectedCard?.card?.last4
        }))
        dispatch(
          setActionSuccessModal({
            status: true,
            action: "set-default",
            successText: t("default-card-success", {
              last4: selectedCard?.card?.last4
            }),
          })
        );
        getPaymentMethods()
        dispatch(setCardActionsModal({status: false, selectedCard: null}))
      }
    }else{
      Alert.warning(t("default-card-warning", {
        last4: selectedCard?.card?.last4
      }))
      dispatch(setCardActionsModal({status: false, selectedCard: null}))
    }
  }

  const handleRemoveCard = () => {
    const confirmHandler = async () => {
      const res = await removeCreditCard(selectedCard.id);
      if(res.success){
        dispatch(
          setActionSuccessModal({
            status: true,
            action: "delete-card",
            successText: t("remove-card-success", {
              last4: selectedCard?.card?.last4
            })
          })
        );
        Alert.success(t("remove-card-success", {
          last4: selectedCard?.card?.last4
        }))
        getPaymentMethods()
        dispatch(setCardActionsModal({status: false, selectedCard: null}))
      }
    }
    const cancelHandler = () => dispatch(setCardActionsModal({status: false, selectedCard: null}))
    showDialog(`remove-card-dialog`, "yes", confirmHandler, "no", cancelHandler)
  }
    return (
        <TGModal
            isOpen={status}
            setIsOpen={(status) =>
                dispatch(setCardActionsModal({status: status, selectedCard: null}))
            }
            toolbar={false}
            breakpoints={[0, 0.4]}
            initialBreakpoint={0.4}
            modalHandler={true}
            scrollY={false}
            cssClass={"CardActionModal"}
        >
            <div className="Content">
                <TGText
                    textTransform={"unset"}
                    fontWeight={500}
                    fontSize={18}
                    margin="30px 0"
                    textAlign="center"
                    width="100%"
                    dangerouslySetInnerHTML={{__html: t("confirm-card-action", {last4: selectedCard?.card?.last4})}}
                />
                <TGList className="Menu">
                    {actionList.filter(i => !i.notVisible).map(item => (
                        <TGItem
                            key={item.id}
                            slug={item.slug}
                            handleClick={() => handleAction(item)}
                            rightIcon={false}
                            leftIcon={<TGIcon name={item.slug} size={40}/>}
                        />
                    ))}
                </TGList>
            </div>
        </TGModal>
    );
}

export default CardActionModal
