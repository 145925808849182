import { TGButton, TGText } from '../TGElements'
import {t} from "i18next"

const FiltersModalHeader = ({clearFilters}) => {
  return (
    <div className='FiltersModalHeader'>
        <TGText fontWeight={"semibold"} fontSize={18}>
            Filtrele
        </TGText>
        <TGButton
        width={"fit-content"}
        height={"fit-content"}
        paddingLeft={"8px"}
        paddingRight={"8px"}
        paddingTop={"4px"}
        paddingBottom={"4px"}
        backgroundActiveColor={"var(--tg-gray)"}
        backgroundColor={"var(--tg-transparent)"}
        onButtonClick={clearFilters}
        >
            <TGText color={"var(--tg-error)"}>
                {t("clear")}
            </TGText>
        </TGButton>
    </div>
  )
}

export default FiltersModalHeader