import {useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux";
import { Alert } from "../../helpers/alert";
import { Capacitor } from "@capacitor/core";
import TGIcon from "./TGIcon";

const TGAlert = () => {
  const {alert, route: {currentPath}} = useSelector(state => state.global)

  const [touchStartValue, setTouchStartValue] = useState(null)
  const [transitionValue, setTransitionValue] = useState(0)
  const [pressed, setPressed] = useState(false)

  const isIos = Capacitor.getPlatform() === "ios"
  const ref = useRef()
  
  const onMouseMove = (e) => {
    if (touchStartValue === null) return
    pressed && touchStartValue - e.nativeEvent.pageY >= ref.current.clientHeight && Alert.close()
    setTransitionValue(touchStartValue - e.nativeEvent.pageY)
  }
  const onTouchStart = (e) => {
    setPressed(true)
    setTouchStartValue(e.nativeEvent.pageY)
  }
  
  useEffect(() => {
    if(alert.status && transitionValue !== 0 && !pressed){
      setTransitionValue(0)
      setTouchStartValue(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, pressed])

  useEffect(() => {
    Alert.close()
  }, [currentPath])
  
  return (
    <div
      className={`TGAlert -${alert.type ?? ""} ${alert.status && " open"} ${isIos && "iosSafeAreaTopMargin"}`}
      onMouseMove={onMouseMove}
      onTouchMove={onMouseMove}
      onTouchStart={onTouchStart}
      onTouchEnd={() => setPressed(false)}
      style={{ transform: `translate(-50%, ${-transitionValue}px)` }}
      ref={ref}
    >
      <span className="TGAlertIcon" />
      <div dangerouslySetInnerHTML={{__html: alert.message}} style={{ width: "calc(100% - 24px)" }}/>
      <TGIcon name="close" fill={"var(--tg-gray)"} onClick={() => Alert.close()} size={24}/>
    </div>
  )
}

export default TGAlert
