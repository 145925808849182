// Components
import { NoEntries, Notification, TGPage } from '../components'
//Redux
import { useSelector } from 'react-redux'
//Translation
import { useTranslation } from 'react-i18next'
import { deleteNotification } from '../helpers'

const Notifications = () => {
  const {t} = useTranslation()
  const {notifications} = useSelector(state => state.user)

  return (
    <TGPage
      title={t("notifications")}
      backButton={true}
      toolbar={true}
      cssClass="NotificationsPage"
      rightButton={{
        text: t("clear"),
        onClick: () => deleteNotification()
      }}
    >
      {notifications?.length ? (
        notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))
      ) : (
        <NoEntries />
      )}
    </TGPage>
  );
}

export default Notifications