import {IonApp} from '@ionic/react'
import Routes from './Routes'
import {TGAlert, TGLoader} from './components'
import React from "react";

const TGApp = () => {
    const app = process.env.REACT_APP_TITLE
    return (
        <IonApp className={`scanner-hide ${app}`}>
            <Routes/>
            <TGLoader/>
            <TGAlert/>
        </IonApp>
    )
}

export default TGApp