import {TGIcon} from "../TGElements";
import {t} from "i18next";

const walletIntroductions = () => {
    return (
        <div>
            <p style={{fontSize: "18px", fontWeight: 600, textAlign: "center"}}>{t("wallet-welcome")}</p>
            <p style={{fontSize: "14px", fontWeight: 400, textAlign: "center"}}>{t("wallet-welcome-description")}</p>
            <div className={"IntroductionIconContainer"}>
                <div className={"IntroductionIcon"}>
                    <TGIcon
                        name="wallet-plus-with-wallet"
                        className={"ChargeTransactionIcon"}
                        margin={"10px"}
                        width={43}
                        height={44}
                    />
                    {t("wallet-introductions-text-1")}
                </div>
                <div className={"GraySeperator"}></div>
                <div className={"IntroductionIcon"}>
                    <TGIcon
                        name="wallet-currency-with-wallet"
                        className={"ChargeTransactionIcon"}
                        margin={"10px"}
                        width={39}
                        height={43}
                    />
                    {t("wallet-introductions-text-2")}
                </div>
                <div className={"GraySeperator"}></div>
                <div className={"IntroductionIcon"}>

                    <TGIcon
                        name="wallet-medal"
                        className={"ChargeTransactionIcon"}
                        width={43}
                        height={42}
                        margin={"10px"}/>
                    {t("wallet-introductions-text-3")}
                </div>
            </div>
        </div>
    )
};

export default walletIntroductions;
