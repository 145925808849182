import { TGIcon, TGText } from '../../../TGElements'

const SearchResultsItem = ({result, handleClick}) => {
  return (
    <div className='SearchResultsItem' onClick={() => handleClick()}>
        <div>
            <TGIcon name='mono-charge' size={30.8} />
        </div>
        <div className='SearchResultsText'>
            <TGText
            fontSize={14}
            fontWeight="semibold"
            width={"90%"}
            wrapWithDots={true}
            >
                {result.name}
            </TGText>
            <TGText
            fontSize={12}
            fontWeight="regular"
            color={"var(--tg-text-secondary)"}
            width={"80%"}
            wrapWithDots={true}
            >
                {result.address}
            </TGText>
        </div>
    </div>
  )
}

export default SearchResultsItem