import { useEffect } from 'react'
import { MyVehicles, TGPage } from '../components'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setMenuModal } from '../redux/slices/modalSlice'
import { useTranslation } from 'react-i18next'
import { getVehicles } from '../helpers'

const Vehicles = () => {
    const {push} = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const onBack = () => {
        push('/map', { menuModal: true })
    }
    useEffect(() => {
        dispatch(setMenuModal({
            status: false,
            goRouteWhileDismissing: ''
        }))
        getVehicles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <TGPage
      title={t("vehicles")}
      scrollY={false}
      backButton={true}
      onBack={() => onBack()}
    >
      <MyVehicles />
    </TGPage>
  )
}

export default Vehicles