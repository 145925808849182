import {useDispatch, useSelector} from "react-redux";
import {TGButton, TGIcon, TGText} from "../../../../TGElements";
import {setStationFilters} from "../../../../../redux/slices/searchSlice";
import { useTranslation } from "react-i18next";

const FilterButtons = ({name, label, icon}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {stationFilters} = useSelector(state => state.search)
    const status = stationFilters[name]

    return (
        <TGButton
            height={35}
            borderRadius={"35px"}
            backgroundColor={status ? "var(--tg-black)" : "var(--tg-white)"}
            expand="none"
            cssClass={`TGMapFilterButton ${name}`}
            onButtonClick={() => dispatch(setStationFilters({type: name, status: !status}))}
            backgroundActiveColor={status ? "rgb(22, 22, 22)" : "rgb(241, 241, 241)"}
        >
            <div className={`MapFilterIconsWrapper ${status ? "--active" : null}`}>
                <TGIcon name={icon.name} width={icon.width} height={icon.height} fill={status ? "var(--tg-primary-text)" : "var(--tg-white)"}/>
            </div>
            <div>
                <TGText
                    fontSize={13}
                    fontWeight={"semibold"}
                    margin={"0 9px 0 9px"}
                    color={status ? "var(--tg-white)" : "var(--tg-primary-text)"}
                >{t(label)}</TGText>
            </div>
        </TGButton>
    );
}

export default FilterButtons;
