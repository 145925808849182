import { TGIcon, TGText } from './TGElements'
import {t} from 'i18next'
import { useSelector } from 'react-redux'
const CallCustomerServices = () => {
  const {company: {tel, tel_formatted, mail}} = useSelector(state => state.config)
  return (
    <div className="CallCustomerServices">
     <TGIcon name ="call-customer-services" width={66.9} height={63.8} fill={"var(--tg-primary)"}/>
     <TGText fontSize={16} color={"var(--tg-primary-text)"} margin={"15px 0 0 0"}>
        {t("contact-us")}
      </TGText> 
      <hr style={{ width: 100, backgroundColor: "var(--tg-inactive)", margin: "15px 0" }} />
      <a href={`tel:${tel_formatted}`} className='tel'>
        {tel}
      </a>
      <a href={`mailto:${mail}`}>
        {mail}
      </a>
    </div>
  );
}

export default CallCustomerServices