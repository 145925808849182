import React from 'react'
import { TGIcon, TGText } from '../../../TGElements'
import { useTranslation } from 'react-i18next'

const NearbyStationSliderItem = ({label, name, iconName, iconFill, distance}) => {
    const {t} = useTranslation()
    return label !== "no-item" ? (
        <div className='NearbyStationsSliderItem'>
            <TGIcon name={iconName} size={50} fill={iconFill}/>
            <div className='NearbyStationsSliderItemInformation'>
                <div className='NearbyStationsSliderItemInformation_FirstLine'>
                    <TGText
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"var(--tg-secondary-text)"}
                    margin={"0 0 5px 0"}
                    >{t(label)}</TGText>
                    <div className='NearbyStationsSliderItemInformation_ClosestStationDistanceWrapper'>
                        <TGText
                        fontSize={14}
                        fontWeight={"medium"}
                        color={"var(--tg-white)"}
                        textTransform={"none"}
                        >{distance}</TGText>
                    </div>
                </div>
                <TGText
                fontSize={15}
                fontWeight={"semibold"}
                color={"var(--tg-primary-text)"}
                cssClass="StationName"
                width={"200px"}
                wrapWithDots={true}
                >
                    {name}
                </TGText>
            </div>
        </div>
    ) : (
        <div className='NearbyStationsSliderItem no-item'>
            <TGIcon name={"plugs"} width={112} height={75} />
            <TGText
            fontSize={14}
            textTransform={"none"}
            fontWeight={"semibold"}
            dangerouslySetInnerHTML={{ __html: t("no-nearby")}}
            />
        </div>
    )
}

export default NearbyStationSliderItem