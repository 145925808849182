import { useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";

const useDialog = () => {
  const [showAlert] = useIonAlert();
  const { t } = useTranslation();

  const showDialog = (
    message,
    confirmText,
    confirmHandler,
    cancelText,
    cancelHandler
  ) => {
    showAlert({
      cssClass: "TGDialog",
      message: t(message),
      buttons: [
        {
          text: t(cancelText) ?? t("no"),
          handler: () => {
            if (cancelHandler) {
              cancelHandler();
            }
          },
        },
        {
          text: t(confirmText) ?? t("yes"),
          handler: () => {
            if (confirmHandler) {
              confirmHandler();
            }
          },
        },
      ],
    });
  };
  return showDialog;
};
export { useDialog };
