import React from 'react'
import { TGButton, TGIcon, TGText } from '../../../TGElements';
import { useTranslation } from 'react-i18next';

const ExpandSocketTypesButton = ({expandList, setExpandList}) => {
    const {t} = useTranslation()
    const expandButtonTransformStyle = expandList
    ? "rotate(180deg) translateY(2px)"
    : "rotate(0deg) translateY(2px)";
  return (
    <TGButton
        backgroundColor={"var(--tg-transparent)"}
        backgroundActiveColor={"var(--tg-transparent)"}
        cssClass={"ShowAllSocketsButton"}
        onButtonClick={() => setExpandList((prev) => !prev)}
      >
        <TGText fontSize={12} fontWeight={"semibold"} margin={"0 12px 0 0"}>
          {!expandList ? t("show-all") : t("hide")}
        </TGText>
        <TGIcon
          name={"down-arrow"}
          size={11}
          style={{
            transform: expandButtonTransformStyle,
            paddingBottom: 5,
          }}
        />
      </TGButton>
  )
}

export default ExpandSocketTypesButton