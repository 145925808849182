//Ionic & React
import React, {useEffect, useState} from "react";
//Components
import {
  MapButtons,
  TGMap,
  TGPage,
  TGBottomToolbar,
  Modals,
  ActiveSessionButton,
} from "../components";
//Helpers
import {getActiveSession, getNotifications, isUserLoggedIn} from "../helpers";
import { useHistory } from "react-router";
//Redux
import { useDispatch } from "react-redux";
import { setMenuModal, setStationDetailModalOpen } from "../redux/slices/modalSlice";
import WalletPopup from "../components/Wallet/walletPopup";

const Map = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [map, setMap] = useState({});
  
  // Open Modals when the user came back from the menu items
  const { menuModal, stationDetailModal } = history?.location?.state || {
    menuModal: false,
    stationDetailModal: false,
  };

  useEffect(() => {

    if (isUserLoggedIn()){
      getActiveSession()
      getNotifications()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    menuModal && dispatch(setMenuModal(menuModal));
    stationDetailModal && dispatch(setStationDetailModalOpen(stationDetailModal));
    return history.push(history.location.pathname, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuModal])

  return (
    <TGPage
      scrollY={false}
      fullscreen={true}
      ionPadding={false}
      toolbar={false}
      cssClass={"TGMapPageContent"}
    >
      <WalletPopup/>
      <TGMap map={map} setMap={setMap}/>
      <MapButtons map={map}/>
      <Modals />
      <ActiveSessionButton />
      <TGBottomToolbar />
    </TGPage>
  );
};

export default Map;
