//components
import { useSelector } from 'react-redux';
import { TGText } from './index'
//helpers
import { useTranslation } from 'react-i18next';

const TGList = ({title, className, children}) => {
  const {currentPath} = useSelector(state => state.global.route)
  const {t} = useTranslation()
    return (
      <div className={`TGList ${className} ${currentPath === "map" || currentPath === "settings" || currentPath === "payments/registered-cards" ? "Menu" : ""}`}>
        {title ? <TGText fontSize={14} fontWeight={"semibold"} color={"var(--tg-primary-text)"} padding={"16px 0 0 0"}>
            {t(title)}
        </TGText> : null}
        <div className='List'>
            {children}
        </div>
      </div>
    );
}

export default TGList
