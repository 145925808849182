//Capacitor & React
import { useEffect, useState } from "react";
//Components
import { TGButton, TGInput } from "../TGElements";
import SetProfileImage from "./SetProfileImage";
import CompleteLater from "./CompleteLater";
//Helpers
import { editUser } from "../../helpers";
import { useValidation } from "../../hooks";
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setConfirmMailModal } from "../../redux/slices/modalSlice";
import { Alert } from "../../helpers/alert";

const SetUser = ({ setIsDone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidation();
  const { previousPath } = useSelector((state) => state.global.route);
  const {info: user} = useSelector(state => state.user.user)
  const {keyboardHeight} = useSelector(state => state.device)
  
  const isEdit = previousPath === "map"

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
  });
  
  const [errors, setErrors] = useState([
    { type: "fullname", message: "", success: true },
    { type: "surname", message: "", success: true },
    { type: "email", message: "", success: true },
  ]);

  useEffect(() => {
    if(isEdit){
      setFormData({
        name: user.first_name,
        surname: user.last_name,
        email: user.email
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  const handleValidate = (object) => {
    if (object.type) {
      const err = validate(object);
      const newState = errors.map((obj) => {
        if (err.type && obj.type === err.type) {
          return { ...obj, message: err.message, success: err.success };
        }
        return obj;
      });
      setErrors(newState);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let userData = {
      first_name: formData.name,
      last_name: formData.surname,
      email: formData.email,
    };
    if (userData.first_name !== "" || userData.last_name !== "" || userData.email !== "") {
      editUser(userData).then((response) => {
        if (response?.status === 200) {
          dispatch(setConfirmMailModal(true));
          setIsDone && setIsDone(true);
        }
      });
    } else {
      Alert.error(t("fill-form"));
    }
  };

  const handleInputChange = (type, ev) => {
    const value = ev.target.value
    setFormData((prev) => ({ ...prev, [type]:  value}))
  }

  return (
    <>
      <form
        className={`SetupProfileForm ${
          keyboardHeight !== 0 ? "scrollable" : ""
        }`}
        onSubmit={handleSubmit}
      >
        <SetProfileImage />
        <TGInput
          label={t("name")}
          value={formData?.name}
          placeholder={user.first_name ? user.first_name : null}
          onInputChange={ev => handleInputChange("name", ev)}
          onInputBlur={(ev) => handleValidate({ type: "fullname", value: ev.target.value })}
          error={!errors?.find((err) => err.type === "fullname").success}
          errorText={t(
            errors.find((err) => err.type === "fullname").message || ""
          )}
        />
        <TGInput
          label={t("surname")}
          value={formData?.surname}
          placeholder={user.last_name ? user.last_name : null}
          onInputChange={ev => handleInputChange("surname", ev)}
          onInputBlur={(ev) => handleValidate({ type: "surname", value: ev.target.value })}
          error={!errors?.find((err) => err.type === "surname").success}
          errorText={t(
            errors.find((err) => err.type === "surname").message || ""
          )}
        />
        <TGInput
          inputmode={"email"}
          label={t("email")}
          value={formData?.email}
          placeholder={user.email ? user.email : null}
          onInputChange={ev => handleInputChange("email", ev)}
          onInputBlur={(ev) => handleValidate({ type: "email", value: ev.target.value })}
          error={!errors?.find((err) => err.type === "email").success}
          errorText={t(
            errors.find((err) => err.type === "email").message || ""
          )}
        />
        <TGButton
          margin="26px 0 0 0"
          additionalStyle={{
            minHeight: 55,
          }}
          disabled={errors.find((err) => err.success === false)}
          type={"submit"}
        >
          {t("save")}
        </TGButton>
      </form>
      {!isEdit && keyboardHeight === 0 ? (
        <CompleteLater path="/vehicles/add-vehicle" />
      ) : null}
    </>
  );
};

export default SetUser;
