import { createSlice } from "@reduxjs/toolkit"

export const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState: {
    activeSession: {}
  },
  reducers: {
    setActiveSession: (state, action) => {
        state.activeSession = action.payload
    },
  },
})

export const {
    setActiveSession
} = sessionSlice.actions
export default sessionSlice.reducer
