import React from 'react';
import {TGButton, TGPageHeader, TGText} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";

const FailActiveSessionModal = ({isOpen, closeModal}) => {
  const {t} = useTranslation()
  return (
    <IonModal
      className={"TGModal"}
      isOpen={isOpen}
      breakpoints={[.5]}
      initialBreakpoint={0.3}
      backdropDismiss={false}
    >
      <div>
        <TGPageHeader backButton={false}/>
        <div style={{margin: "16px"}}>
          <TGText textAlign={'center'}>{t('fail-start-charge')}</TGText>
          <TGButton width={'100%'} margin={"16px 0 0 0"} onButtonClick={closeModal}>
            {t("goToMap")}
          </TGButton>
        </div>
      </div>
    </IonModal>
  );
};

export default FailActiveSessionModal;