//Helpers
import {getActiveSession, getMyCompanies, stopSession, transactionUpdate} from "../../../../helpers";
import {useTranslation} from "react-i18next";
//Components
import {TGButton, TGSelect, TGText} from "../../../TGElements";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import { setLoading } from "../../../../redux/slices/globalSlice";
import {Alert} from "../../../../helpers/alert";

const ActionButton = ({setIntervalStart}) => {
    const {t} = useTranslation();
    const history = useHistory()
    const dispatch = useDispatch()
    const {activeSession} = useSelector(state => state.session)
    const [companies, setCompanies] = useState([]);
    const isDisabled = activeSession?.total_consumption === 0;

    useEffect(() => {
        getMyCompanies().then(({data}) => {
            setCompanies(() => {
                return [
                    {name: t("personal-account"), id: null},
                    ...data.map(({company}) => ({name: company.company_name, id: company.id}))
                ]
            })
        })
    }, []);

    const handleClick = async () => {
        setIntervalStart(false)
        dispatch(setLoading({status: true, message: t("charge-ending")}))
        const stop = await stopSession();
        dispatch(setLoading(false))
        if (stop) {

            history.push("/receipt", {receipt: stop.response});
        }
    };

    const handleSelect = (company_id) => {
        transactionUpdate(activeSession.id, company_id).then(() => {
            getActiveSession(false)
        })
    }

    return (
        <div style={{position: "absolute", bottom: 20, width: "calc(100% - 32px)"}}>

            <TGSelect
                title={""}
                itemStyle={{borderRadius: 50}}
                optionList={companies}
                placeholder={t("select-account")}
                setSelected={handleSelect}
                selected={activeSession?.company_id || null}
                actionSheetOptions={{header: t("select-account")}}
            />

            <TGButton
                backgroundColor={"var(--tg-primary-text)"}
                backgroundActiveColor={"var(--tg-black-active)"}
                width={"100%"}
                margin={"10px 0 0 0"}
                onButtonClick={handleClick}>
                <TGText color={"var(--tg-white)"} fontSize={16} fontWeight={"medium"}>
                    { t("stop-charge")}
                </TGText>
            </TGButton>
        </div>
    );
};

export default ActionButton;
