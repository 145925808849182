const TGImg = ({src, alt, width, height, size, borderRadius, type, reflect, margin, cssClass, additionalStyles, reference}) => {
    const imgWidth = typeof width === 'number' ? `${width}px` : width
    const imgHeight = typeof height === 'number' ? `${height}px` : height
    const TGImgStyle = {
        width: type === 'avatar' || size ? `${size}px` : imgWidth,
        height: type === 'avatar' || size ? `${size}px` : imgHeight,
        borderRadius: type === 'avatar' ? `${size}px` : `${borderRadius}px`,
        transform: reflect ? "scaleX(-1)" : "",
        margin: margin ? margin : "",
        ...additionalStyles
    }
    return <img ref={reference} className={cssClass} src={src} alt={alt} width={width} height={height} style={TGImgStyle}/>
}

export default TGImg;
