import { TGImg, TGText } from "../../TGElements";

const VehiclePreviewAnimated = ({vehicleModel, vehicleBrand}) => {
    return (
        <div className="VehiclePreviewAnimated">
            <div className="VehiclePreviewAnimatedBackground">
                <div className="VehiclePreviewAnimatedInner">
                    <TGImg src={vehicleModel.image_url} height={"40%"} reflect={true}/>
                    <div className="VehiclePreviewAnimatedInner_VehicleInfo">
                        <TGImg src={vehicleBrand.thumb}/>
                        <div className="VehiclePreviewAnimatedInner_VehicleInfoText">
                                <TGText
                                fontSize={20}
                                fontWeight={"semibold"}
                                color="var(--tg-primary-text)"
                                >
                                    {vehicleBrand.name}
                                </TGText>
                                <TGText
                                fontSize={16}
                                fontWeight={"regular"}
                                color="var(--tg-secondary-text)"
                                >
                                    {vehicleBrand.name}
                                </TGText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VehiclePreviewAnimated;
