import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {TGButton, TGInput, TGText} from "../TGElements";
import {useSelector} from "react-redux";
import {useIonViewDidEnter} from "@ionic/react";

const StartSessionForm = ({submitHandler, formError, codeFromQR}) => {
    const {t} = useTranslation()
    const [code, setCode] = useState("");
    const {keyboardHeight} = useSelector(state => state.device)
    const inputRef = useRef(null);

    useIonViewDidEnter(() => {
        inputRef.current?.setFocus();
    });

    useEffect(() => {
        if(!!codeFromQR){
            setCode(codeFromQR);
        }
    }, [codeFromQR])

    const handleCodeChange = (e) => {
        const value = e.target.value;
        // only allow numbers
        if (isNaN(value)) return;
        setCode(value)
    }

    return (
        <div className={"StartSessionForm"}>
            <TGInput
                reference={inputRef}
                type={"tel"}
                placeholder={"0000"}
                value={code}
                onInputChange={handleCodeChange}
                textAlign={"center"}
                error={formError}
                maxlength={4}
            />

            <div className={`ConfirmButtonWrapper keyboard-${!!keyboardHeight}`}>
                <TGButton
                    disabled={!!code === false}
                    onButtonClick={() => submitHandler(code)}
                >
                    <TGText color={"#fff"}>{t("confirm")}</TGText>
                </TGButton>
            </div>
        </div>
    );
}

export default StartSessionForm;
