import {TGInput, TGPageHeader} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";
import TGImg from "../TGElements/TGImg";
import {EmptyPlateVehicle, WalletNotice} from "../../assets/images";
import React from "react";

const MembershipAddVehicleModal = ({
                                       isAddVehicleModalOpen,
                                       setIsAddVehicleModalOpen,
                                       plate,
                                       setPlate,
                                       buttonHandler
                                   }) => {


    const {t} = useTranslation()
    return (
        <IonModal
            className={"TGModal"}
            isOpen={isAddVehicleModalOpen}
            breakpoints={[0.65, 0]}
            initialBreakpoint={0.65}
            onDidDismiss={() => setIsAddVehicleModalOpen(false)}
        >
            <div>
                <TGPageHeader backButton={false}/>
                <div className={"addVehicleModal"}>
                    <p>{t("add-plate")}</p>
                    <p>{t("enter-the-licence-plate-of-the-vehicle")}</p>
                    <div style={{position: "relative"}}>
                        <TGImg src={EmptyPlateVehicle}/>
                        <div className={"vehicleImgLabel"}>{plate}</div>
                    </div>
                    <div style={{width: "calc(100% - 20px)"}}>
                        <p style={{padding: "22px 0 0 10px", fontSize: "16px", fontWeight: 500}}>{t("enter-plate")}</p>
                        <input className={"vehicleInput"} maxLength={8} value={plate}
                               onChange={(e) => setPlate(e.target.value.toUpperCase())} type="text"/>
                    </div>

                    <button className={"addButton"} onClick={buttonHandler}>{t("save")}</button>
                </div>
            </div>
        </IonModal>
    );
};

export default MembershipAddVehicleModal;