import {TGIcon, TGImg} from "../TGElements";
import React from "react";
import {IonItem} from "@ionic/react";
import {t} from "i18next";

const MembershipVehicle = ({vehicle, selectedVehicle, setSelectedVehicle = null, style = {}}) => {

    const onClickHandler = (vehicle) => {
        setSelectedVehicle && setSelectedVehicle(vehicle)
    }
    return (
        <IonItem lines={"none"} style={style}
                 className={`SubscriptionItem ${vehicle.id === selectedVehicle?.id && "selected"}`}>
            <div onClick={() => onClickHandler(vehicle)} key={vehicle.id}
                 style={{display: "flex", width: "100%", position: "relative"}}>
                <div>
                    <TGImg
                        src={vehicle.image}
                        width={130}
                        height={85}
                        reflect={true}
                        cssClass="VehicleImage"
                    />
                </div>
                <div>

                    <p className={"vehicleModel"}>{vehicle.model_text ? vehicle.model_text : t("unknown-model")}</p>
                    <p className={`vehiclePlate ${!vehicle.model_text && "noModel"}`}>{vehicle.plate}</p>
                </div>
                {vehicle.id === selectedVehicle?.id && <div className={"IconContainer"}>
                    <TGIcon name="vehicle-tick" width={18} height={18}/>
                </div>}

            </div>
        </IonItem>

    );
};

export default MembershipVehicle;
