import { Menu } from './Menu';
import { SearchModal } from './Search';
import { StationDetailModal } from './StationDetail';
import { CheckUserModal } from "./CheckUserModal";

const Modals = () => {
    return (
        <>
            <StationDetailModal />
            <SearchModal />
            <Menu />
            <CheckUserModal/>
        </>
    )
}

export default Modals;
