import { useEffect, useState } from "react";
import TGText from "./TGText";
import { App as CapacitorApp } from "@capacitor/app";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";

const TGVersionLabel = () => {
  const [appName, setAppName] = useState("");
  const [versionLabel, setVersionLabel] = useState("");
  const { live_server: { version: serverVersion } } = useSelector((state) => state.config);
  const isNativePlatform = Capacitor.isNativePlatform();

  const handleVersion = async () => {
    const appInfo = isNativePlatform && await CapacitorApp.getInfo();
    setAppName(appInfo.name ?? process.env.REACT_APP_TITLE);
    if (isNativePlatform) {
      setVersionLabel(`V ${appInfo.version}.${appInfo.build} / ${serverVersion}`);
    } else {
      setVersionLabel(`V ${serverVersion}`);
    }
  };

  useEffect(() => {
    handleVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="TGVersionLabel">
      <TGText textAlign="right" fontSize={14} fontWeight={"light"} color={"var(--tg-secondary-primary)"}>
        {appName}
      </TGText>
      <TGText textAlign="right" fontSize={14} fontWeight={"medium"}>{versionLabel}</TGText>
    </div>
  );
};

export default TGVersionLabel;
