import { IonContent, IonPage } from '@ionic/react';
import {TGPageHeader} from './TGElements';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
    const {t} = useTranslation()
    const history = useHistory()
    useEffect(() => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        if(!user.id) history.push("/register-phone")
        else history.push("/map")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <IonPage>
            <TGPageHeader
            title={"Not Found"}
            />
            <IonContent scrollY={false} className="ion-padding">
                <p>{t("page-not-found")}</p>
            </IonContent>
        </IonPage>
    );
}

export default NotFound;
