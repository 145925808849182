import React from "react";
import { TGText } from "../../TGElements";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

const StationInformation = () => {
  const { t } = useTranslation();
  const {activeSession} = useSelector(state => state.session)
  const {company} = useSelector(state => state.config)
  return (
    <div
      style={{
        marginTop: 34,
      }}
    >
      <TGText fontSize={14} fontWeight={"semibold"} margin={"0 0 10px 0"}>
        {t("station-informations")}
      </TGText>
      <div className="info-section">
        <div className="info-line">
          <TGText width={110} fontSize={16} fontWeight={"regular"}>
            {t("station-no")}:
          </TGText>
          <TGText fontSize={16} fontWeight={"medium"} width={250}>
            {activeSession?.connection}
          </TGText>
        </div>
        <div className="info-line">
          <TGText width={110} fontSize={16} fontWeight={"regular"}>
            {t("address")}:
          </TGText>
          <TGText
            fontSize={16}
            fontWeight={"medium"}
            width={250}
            styles={{
              wordWrap: "break-word",
            }}
          >
            {activeSession?.station_address}
          </TGText>
        </div>
        <div className="info-line">
          <TGText width={110} fontSize={16} fontWeight={"regular"}>
            {t("contact")}:
          </TGText>
          <TGText
            fontSize={16}
            fontWeight={"medium"}
            color={"var(--tg-link)"}
            width={250}
          >
            {company.tel}
          </TGText>
        </div>
      </div>
    </div>
  );
};

export default StationInformation;
