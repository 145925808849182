const LogoMono = (props) => {
  if(props.type === 'vertical') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="77.521" height="19.862" viewBox="0 0 77.521 19.862" {...props}>
        <g id="gosarj_logo1" >
          <g id="_2418453940304" transform="translate(0 0)">
            <g id="Group_311454" data-name="Group 311454" transform="translate(0 0)">
              <path id="Path_314106" data-name="Path 314106" d="M1124.692,19.87a9.9,9.9,0,0,1-5.854-1.908,10.758,10.758,0,0,0,3.372-5.816,3.312,3.312,0,0,0,2.483,1.116V11.447h2.663v-.826h-2.663V9.072h2.663V8.246h-2.663V6.616a3.332,3.332,0,0,0-3.14,2.237,2.854,2.854,0,0,0-.092.319h-6.669c.008-.106.019-.213.03-.319a9.907,9.907,0,0,1,3.366-6.416c.209-.182.426-.355.651-.519a9.931,9.931,0,1,1,5.854,17.952Z" transform="translate(-1101.749 -0.009)" fill="#191e26"/>
              <path id="Path_314107" data-name="Path 314107" d="M19.86,10.019a9.608,9.608,0,0,1-.059,1.029,9.908,9.908,0,0,1-3.365,6.416q-.315.273-.654.52a9.93,9.93,0,1,1,0-16.046,10.758,10.758,0,0,0-3.371,5.816,3.322,3.322,0,0,0-5.8,2.393A3.349,3.349,0,0,0,10,13.284a3.278,3.278,0,0,0,1.224-.262L9.385,11.184a.683.683,0,0,1,.483-1.165H19.86Z" transform="translate(0.001 -0.03)" fill="#191e26"/>
            </g>
            <path id="Path_314108" data-name="Path 314108" d="M23.788,1828.877l-2.738,1.349a3.926,3.926,0,0,0,1.468,1.713,5.733,5.733,0,0,0,2.54.864l.319,0v-2.615a2.36,2.36,0,0,1-1.59-1.306Zm6.816-.7a2.468,2.468,0,0,0-.986-.954,6.381,6.381,0,0,0-1.227-.543l-1.459-.444a8.17,8.17,0,0,1-1.7-.564.7.7,0,0,1-.438-.595.59.59,0,0,1,.3-.5,1.568,1.568,0,0,1,.868-.2,2.021,2.021,0,0,1,1.95,1.182l2.684-1.333a4.3,4.3,0,0,0-1.861-1.8,6.06,6.06,0,0,0-2.791-.621,5.119,5.119,0,0,0-3.1.909,2.817,2.817,0,0,0-1.244,2.379,2.527,2.527,0,0,0,1,2.129,7.356,7.356,0,0,0,2.72,1.19q1.212.3,1.569.422.846.286.847.751,0,.619-1.125.7v2.507l.857-.01A4.746,4.746,0,0,0,30,1831.69a2.982,2.982,0,0,0,.608-3.512Z" transform="translate(14.676 -1817.514)" fill="#191e26"/>
            <path id="Path_314109" data-name="Path 314109" d="M672.923,1839.8l-2.617,7.471H666.84l3.991-10.608h4.032l4.143,10.608h-3.3Z" transform="translate(-621.238 -1832.147)" fill="#191e26"/>
            <path id="Path_314110" data-name="Path 314110" d="M1525.223,1839.171v2.379h1.771a1.344,1.344,0,0,0,.966-.318,1.18,1.18,0,0,0,0-1.728,1.4,1.4,0,0,0-.966-.333Zm3.328,8.107-2.183-3.425h-1.145v3.425h-3.2V1836.67h5.01a4.979,4.979,0,0,1,3.212,1.038,3.229,3.229,0,0,1,1.3,2.629,2.931,2.931,0,0,1-.573,1.75,3.99,3.99,0,0,1-1.557,1.266l2.576,3.925h-3.435Z" transform="translate(-1463.339 -1832.157)" fill="#191e26"/>
            <path id="Path_314111" data-name="Path 314111" d="M2175.66,1847.5a4.612,4.612,0,0,1-4.33-2.122l2.738-1.349a1.72,1.72,0,0,0,.626.636,2,2,0,0,0,.966.2q1.378,0,1.378-1.076v-7.122h3.2v7.168a3.232,3.232,0,0,1-1.243,2.69,5.254,5.254,0,0,1-3.337.978Z" transform="translate(-2102.719 -1832.147)" fill="#191e26"/>
          </g>
        </g>
      </svg>
    )
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="533.585"
      height="483.271"
      viewBox="0 0 533.585 483.271"
      {...props}
    >
      <g id="gosarj_renkli" transform="translate(0.001 -0.009)">
        <g id="_2418453940304" transform="translate(-0.001 0.009)">
          <path
            id="Path_314106"
            data-name="Path 314106"
            d="M1275.505,322.39a160.622,160.622,0,0,1-95.028-30.964,174.6,174.6,0,0,0,54.729-94.4,53.753,53.753,0,0,0,40.3,18.12V185.669h43.219v-13.4h-43.219V147.115h43.219v-13.4h-43.219V107.257a54.082,54.082,0,0,0-50.973,36.305,46.842,46.842,0,0,0-1.5,5.178H1114.79c.137-1.717.3-3.462.486-5.178a160.8,160.8,0,0,1,54.636-104.134c3.387-2.956,6.921-5.765,10.565-8.427A160.6,160.6,0,0,1,1284.437.25c84.058,4.544,151.088,74.511,152.245,158.689a161.188,161.188,0,0,1-161.177,163.453Z"
            transform="translate(-903.113 -0.009)"
            fill="#ff5125"
          />
          <path
            id="Path_314107"
            data-name="Path 314107"
            d="M322.382,162.158a155.956,155.956,0,0,1-.965,16.7A160.816,160.816,0,0,1,266.8,283q-5.107,4.423-10.618,8.446A160.6,160.6,0,0,1,152.228,322.17C68.177,317.617,1.165,247.644.014,163.479A161.209,161.209,0,0,1,256.211,31a174.62,174.62,0,0,0-54.722,94.4,53.921,53.921,0,0,0-94.16,38.836c1.57,28.7,26.253,51.505,54.988,50.918a53.206,53.206,0,0,0,19.869-4.251l-29.826-29.826a11.08,11.08,0,0,1,7.834-18.916H322.384Z"
            transform="translate(0.001 -0.026)"
            fill="#010001"
          />
          <path
            id="Path_314108"
            data-name="Path 314108"
            d="M55.042,1909.667,21.05,1926.411a48.713,48.713,0,0,0,18.221,21.261q12.655,8.468,31.541,10.723l3.965-.046v-32.468q-13.426-3.443-19.736-16.212Zm84.628-8.693a30.655,30.655,0,0,0-12.236-11.843,79.138,79.138,0,0,0-15.238-6.746q-7.021-2.133-18.111-5.516-15.739-3.979-21.168-7c-3.627-2.011-5.434-4.479-5.434-7.381a7.314,7.314,0,0,1,3.663-6.241c2.442-1.633,6.031-2.461,10.778-2.461q16.881,0,24.215,14.678l33.322-16.559a53.371,53.371,0,0,0-23.1-22.389q-15.118-7.71-34.653-7.711-23.1,0-38.546,11.29t-15.439,29.54q0,16.924,12.439,26.428t33.772,14.771q15.05,3.691,19.48,5.243,10.507,3.552,10.51,9.327,0,7.684-13.971,8.628v31.129l10.639-.129q19.994-3.016,31.541-13.44t11.549-26.6a34.588,34.588,0,0,0-4-17.009Z"
            transform="translate(-17.053 -1475.877)"
            fill="#010001"
          />
          <path
            id="Path_314109"
            data-name="Path 314109"
            d="M742.369,1875.606l-32.489,92.757H666.84l49.553-131.7h50.064l51.439,131.7H776.961Z"
            transform="translate(-540.22 -1487.915)"
            fill="#010001"
          />
          <path
            id="Path_314110"
            data-name="Path 314110"
            d="M1561.785,1867.714v29.54h21.992q7.328.191,11.995-3.951a14.659,14.659,0,0,0,0-21.449q-4.665-4.136-11.995-4.139h-21.992Zm41.318,100.658-27.1-42.522h-14.218v42.522H1522.02v-131.7h62.2q23.768,0,39.875,12.887T1640.2,1882.2a36.4,36.4,0,0,1-7.109,21.732,49.522,49.522,0,0,1-19.326,15.711l31.987,48.731H1603.1Z"
            transform="translate(-1233.017 -1487.923)"
            fill="#010001"
          />
          <path
            id="Path_314111"
            data-name="Path 314111"
            d="M2225.089,1971.187q-38.209,0-53.759-26.34l33.989-16.746a21.376,21.376,0,0,0,7.776,7.9q4.44,2.449,11.995,2.446,17.1,0,17.106-13.358V1836.66h39.763v88.993q0,21.265-15.439,33.4t-41.43,12.135Z"
            transform="translate(-1759.036 -1487.915)"
            fill="#010001"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoMono;
