import { useTranslation } from "react-i18next"
import { TGIcon, TGText } from "../../TGElements"
import { useSelector } from "react-redux"
import {formatStationPrice} from "../../../helpers";

const Prices = () => {
  const {currentStation} = useSelector(state => state.station)
  const {stationDetailModal} = useSelector(state => state.modal)
  const {t} = useTranslation()
  return (
    stationDetailModal.currentBreakpoint === 1 && (
      <div className="Prices">
          <div className="Price">
            <div>
              <TGIcon name="mono-charge-20" />
              <TGText fontSize={14} fontWeight={"semibold"}>
                {t("charge-price")}
              </TGText>
            </div>
            <div>
              <TGText fontSize={16} fontWeight={"semibold"}>
                {formatStationPrice(currentStation.DETAIL.connections)}
              </TGText>
              <TGText
                fontSize={12}
                fontWeight={"regular"}
                color={"var(--tg-gray-2)"}
                textTransform={"unset"}
              >
                kwH
              </TGText>
            </div>
          </div>
      </div>
    )
  );
}

export default Prices
