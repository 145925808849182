import {TGIcon, TGImg, TGText} from '../../TGElements';
import {CarTop} from '../../../assets/onboarding'
import {useTranslation} from 'react-i18next';

const Slide1 = ({activeIndex}) => {
    const {t} = useTranslation()

    return (
        <div className='OnboardingSlide'>
            <div className='OnboardingSlideContent'>
                <TGIcon name="onboarding-park-area" cssClass={"OnboardingParkingArea"} size={"fit-content"}/>
                <TGImg src={CarTop} cssClass={`AnimatedOnboardingCar ${activeIndex === 0 && "animate"}`}/>
            </div>
            <div className='OnboardingSlideTextContent'>
                <TGText
                    fontSize={20}
                    fontWeight={"semibold"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    margin={"36px 0 14px 0"}
                    width={"100%"}
                >
                    {t("welcome")}
                </TGText>
                <TGText
                    fontSize={16}
                    fontWeight={"regular"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    width={"70%"}
                    textTransform={"unset"}
                >
                    {t("welcome-text")}
                </TGText>
            </div>
        </div>
    );
}

export default Slide1;
