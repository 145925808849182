export const formatValue = async (name, value) => {
    switch (name) {
        case "number":
            return value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim();
        case "expiry":
            return value.length >= 3 && value.split("/")[0].length === 1 ?
            `0${value.split("/")[0]}/${value.split("/")[1]}` :
            value.replace(/(\d{2})(\d{2})/, "$1/$2");
        default:
            return value
    }
}

export const formatDate = (date, type = "date") => {
    const d = new Date(date);

    const optionsDate = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
    };

    const optionsTime = {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC"
    };

    switch (type) {
        case "datetime":
            return d.toLocaleString("tr", { ...optionsDate, ...optionsTime });
        case "date":
            return d.toLocaleDateString("tr", optionsDate);
        case "time":
            return d.toLocaleTimeString("tr", optionsTime);
        default:
            throw new Error("Invalid type parameter. Use 'datetime', 'date' or 'time'.");
    }
};

export const formatSeconds = (seconds) => {
    if (seconds) {
        let resStr = ""
        const duration = new Date(seconds * 1000).toISOString().slice(11, 19);
        const durationArray = duration.split(":")
        const unit = (id) => id === 0 ? "sa" : id === 1 ? "dk" : "sn"
        durationArray.forEach((el, id) => {
            if(el!=="00"){
                resStr += `${el}${unit(id)}`
                if(id !== 2) resStr += " "
            }
        })
        return resStr
    }
}

export const handleDateFilter = (id) => {
    
    const calculateTimeStamp = (monthCount) => {
      let currentDate = new Date()
      currentDate.setMonth(currentDate.getMonth() - monthCount);
      let lastMonthTimestamp = currentDate.getTime();
      let timeStampSec = Math.floor(lastMonthTimestamp / 1000);
      return timeStampSec
    }

    switch(id){
      case 0:
        return calculateTimeStamp(1);
      case 1:
        return calculateTimeStamp(3);
      case 2:
        return calculateTimeStamp(12);
      default:
        return;
    }

  }