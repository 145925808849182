//Components
import { TGIcon, TGImg, TGText } from "../../../TGElements";
import SessionCar from "../../../../assets/images/session-car.png";
import ParkingArea from "../../../../assets/images/parking-area.png";
import SessionVehicleClippingMask from "../../../../assets/SessionVehicleClippingMask";
//Helpers
import { useTranslation } from "react-i18next";
const ParkingVehicle = ({isParkCharge}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="AnimatedVehicleContainer">
        <div className="box --parking">
          <div className={`main-liquid --parking${isParkCharge ? " --charging" : ""}`}></div>
          <div className={`secondary-liquid --parking${isParkCharge ? " --charging" : ""}`}></div>
        </div>
        <SessionVehicleClippingMask />
      </div>
      <TGImg src={ParkingArea} alt="PARKING_AREA" cssClass={"ParkingArea"} />
      <TGImg
        src={SessionCar}
        alt="SESSION_CAR"
        cssClass={"SessionCar --parking"}
      />
      <div className="ChargingText">
        <TGIcon name={`${isParkCharge ? "charging": "park-timer"}`} size={isParkCharge ? 48 : 30} />
        <TGText fontWeight={"medium"} fontSize={13} color={"var(--tg-white)"}>
          {t("parking")}
        </TGText>
      </div>
    </>
  );
};

export default ParkingVehicle;
