import {Virtuoso} from 'react-virtuoso'

const TGVirtualScroll = ({data, handleEnd, item}) => {
    return (
        <Virtuoso
          style={{ height: "100%", width: "100%"}}
          data={data}
          endReached={handleEnd}
          itemContent={item}
        />
      )
}

export default TGVirtualScroll