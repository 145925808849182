import AddNewButton from './AddNewButton'
import MyVehicleList from './MyVehicleList'

const MyVehicles = () => {
  return (
    <div className='MyVehicles'>
      <MyVehicleList />
      <AddNewButton />
    </div>
  )
}

export default MyVehicles
