import { useSelector } from "react-redux";
import VehicleItem from "./VehicleItem";
const VehiclesList = () => {
  const { vehicles } = useSelector((state) => state.user);
  return (
    <div className="VehicleList">
      {
        vehicles.map(vehicle => <VehicleItem key={vehicle.id} vehicle={vehicle}/>)
      }
    </div>
  );
};

export default VehiclesList;
