import { useDispatch, useSelector } from "react-redux"
import { TGModal, TGUser } from "../TGElements"
import { setMenuModal } from "../../redux/slices/modalSlice"
import { MenuContent } from "./MenuContent"
import { useHistory } from "react-router"

const Menu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {menuModal} = useSelector(state => state.modal)
  const isScrollable = window.innerWidth > 768

  return (
    <TGModal
    isOpen={menuModal.status}
    setIsOpen={status => {
      if(menuModal.status){
        dispatch(setMenuModal(status))
      }
    }}
    onWillDismiss={() => {
      if(menuModal.goRouteWhileDismissing !== ""){
        history.push(menuModal.goRouteWhileDismissing)
      }
    }}
    fullScreen={true}
    scrollY={isScrollable}
    ionPadding={true}
    rightButton={'notifications'}
    className="MenuModal"
    toolbar={true}
    iosSafeArea={false}
    isProfileEdit={true}
    >
      <TGUser />
      <MenuContent />
    </TGModal>
  )
}

export default Menu